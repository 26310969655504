import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const Project = () => {
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    const fetchProjects = async () => {
      const res = await fetch(
        'https://api.chaulanart.com/projects/get-home-projects'
      );
      const data = await res.json();
      setProjects(data);
    };

    fetchProjects();
  }, []);

  const checkURL = (url) => {
    return /\.(jpeg|jpg|gif|png)$/i.test(url);
  };
  const isValidYoutubeEmbedUrl = (url) => {
    const pattern = /^https:\/\/www\.youtube\.com\/embed\/.+/i;
    return pattern.test(url);
  };

  return (
    <div class="recent-orders">
      <h2>Các dự án mới</h2>
      <table className="project-list">
        <thead>
          <tr>
            <th>Tên dự án</th>
            <th>Hình minh họa</th>
            <th>Trạng thái</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {projects &&
            projects.map((project) => (
              <tr>
                <td>
                  <div dangerouslySetInnerHTML={{ __html: project.heading }} />
                </td>
                <td>
                  {checkURL(project.image_main) ? (
                    <img
                      src={project.image_main}
                      title="Uploaded Image"
                      alt="Uploaded"
                      className="project-image"
                    />
                  ) : isValidYoutubeEmbedUrl(project.image_main) ? (
                    <iframe
                      src={project.image_main}
                      title="Uploaded Video"
                      alt="Uploaded"
                      className="project-image"
                    />
                  ) : (
                    <iframe
                      src={project.image_main}
                      title="Uploaded Video"
                      alt="Uploaded"
                      className="project-image"
                    />
                  )}
                </td>
                <td>
                  {project.status === 1
                    ? 'Hoàn thành'
                    : project.status === 2
                    ? 'Ẩn'
                    : 'Coming soon'}
                </td>
                <td>
                  <Link
                    className="project-actions"
                    to={`/admin/projects/${project.project_id}`}>
                    Chi tiết
                  </Link>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      <Link to="/admin/projects">Show All</Link>
    </div>
  );
};

export default Project;
