import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ReactPaginate from 'react-paginate';
import './WorkList.css';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

const WorkList = () => {
  const [workList, setWorkList] = useState([]);
  const [workTags, setWorkTags] = useState({});
  const [workHeadingItem, setWorkHeadingItem] = useState([]);
  const [workHeading, setWorkHeading] = useState({});
  const [workThumbnail, setWorkThumbnail] = useState({});
  const [isLoaded, setIsLoaded] = useState(false);

  const checkURL = (url) => {
    return /\.(jpeg|jpg|gif|png)$/i.test(url);
  };
  const isValidYoutubeEmbedUrl = (url) => {
    const pattern = /^https:\/\/www\.youtube\.com\/embed\/.+/i;
    return pattern.test(url);
  };

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const res = await fetch(
          `https://api.chaulanart.com/projects/get-projects`
        );
        const projects = await res.json();

        setWorkList(projects);

        // Fetch tags for all projects
        const tagsPromises = projects.map((project) =>
          fetch(
            `https://api.chaulanart.com/projects/get-project-visuals/${project.project_id}`
          )
            .then((res) => res.json())
            .then((tagData) => ({
              projectId: project.project_id,
              tags: tagData,
            }))
        );

        Promise.all(tagsPromises).then((results) => {
          const tagsByProject = results.reduce((acc, current) => {
            acc[current.projectId] = current.tags;
            return acc;
          }, {});
          setWorkTags(tagsByProject);
        });
      } catch (error) {
        console.error('Failed to fetch project data:', error);
      }
    };
    fetch(`https://api.chaulanart.com/work-page/get-work`)
      .then((res) => res.json())
      .then((data) => setWorkHeading(data));
    fetch(`https://api.chaulanart.com/work-page/get-work-item`)
      .then((res) => res.json())
      .then((data) => {
        setIsLoaded(true);
        setWorkHeadingItem(data);
      });
    fetch(`https://api.chaulanart.com/work-page/get-work-thumnail`)
      .then((res) => res.json())
      .then((data) => {
        setWorkThumbnail(data);
      });
    fetchProjects();
    document.title = `Chau Lan Art`;
  }, []);

  function stripHtml(html) {
    // Temporary element for parsing HTML content
    var tempDivElement = document.createElement('div');
    tempDivElement.innerHTML = html;
    return tempDivElement.textContent || tempDivElement.innerText || '';
  }

  function LayoutInPage({ itemInPage }) {
    return (
      <div className="work-list">
        {itemInPage.slice(0, 12).map((item) =>
          item.status !== 2 ? (
            <div className={`work-item ${item.status === 0 ? 'coming' : ''}`}>
              <Link
                className={`work-item-link ${
                  item.status === 0 ? 'coming' : ''
                }`}
                to={`/work/${item.project_id}-${stripHtml(item.heading)
                  .replace(/ /g, '-')
                  .toLowerCase()}`}
                key={item.project_id}>
                <div className="work-item-content">
                  <div className="work-item-image">
                    {checkURL(item.image_main) ? (
                      <img
                        src={item.image_main}
                        title="Uploaded Image"
                        alt="Uploaded"
                        className="work-item-img"
                      />
                    ) : isValidYoutubeEmbedUrl(item.image_main) ? (
                      <iframe
                        src={item.image_main}
                        title="Uploaded Video"
                        alt="Uploaded"
                        className="work-item-img"
                      />
                    ) : (
                      <iframe
                        src={item.image_main}
                        title="Uploaded Video"
                        alt="Uploaded"
                        className="work-item-img"
                      />
                    )}
                    {item.status === 0 ? (
                      <div className="work-item-coming">
                        <h3 className="work-coming-heading">Coming soon</h3>
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                  <h4
                    className="work-title"
                    dangerouslySetInnerHTML={{ __html: item.heading }}
                  />
                  <div className="work-tag">
                    {workTags[item.project_id]?.map((tag, tagIndex) => (
                      <p
                        className="work-tag-item"
                        key={tagIndex}>
                        {tag.name_visual}
                      </p>
                    ))}
                  </div>
                </div>
              </Link>
            </div>
          ) : (
            ''
          )
        )}
      </div>
    );
  }

  const PageItem = ({ pageSize }) => {
    const [currentPage, setCurrentPage] = useState(0);
    const pageCount = Math.ceil(workList.length / pageSize);

    const handlePageClick = (selectedItem) => {
      setCurrentPage(selectedItem.selected);
      window.scrollTo(0, 0);
    };

    return (
      <>
        <LayoutInPage
          itemInPage={workList.slice(
            currentPage * pageSize,
            (currentPage + 1) * pageSize
          )}
        />
        <ReactPaginate
          previousLabel={currentPage === 0 ? '' : 'Prev'}
          nextLabel={currentPage === pageCount - 1 ? '' : 'Next'}
          onPageChange={handlePageClick}
          pageCount={pageCount}
          marginPagesDisplayed={0}
          pageRangeDisplayed={0}
          containerClassName="work-pagination"
          activeClassName="active"
          forcePage={currentPage}
        />
      </>
    );
  };

  const itemCount = workHeadingItem?.length || 0;

  const autoplay = {
    dots: false,
    infinite: true,
    slidesToShow: Math.min(1, itemCount),
    slidesToScroll: 1,
    autoplay: true,
    vertical: true,
    verticalSwiping: true,
    speed: 250,
    autoplaySpeed: 2500,
    cssEase: 'linear',
  };
  const pageDescription = workThumbnail.description;
  const baseUrl = 'https://chaulanart.com';
  const pageUrl = `${baseUrl}/work`;
  const imageUrl = workThumbnail.thumnail;

  return (
    <>
      <Helmet>
        <meta
          property="og:description"
          content={pageDescription}
        />
        <meta
          property="og:url"
          content={pageUrl || baseUrl}
        />
        <meta
          property="og:image"
          content={imageUrl}
        />
      </Helmet>
      <section className="work">
        <div className="container">
          <div className="work-wrapper">
            <h3 className="work-heading">
              <p
                className="work-heading-text"
                dangerouslySetInnerHTML={{ __html: workHeading.heading }}
              />
              {isLoaded && (
                <Slider {...autoplay}>
                  {workHeadingItem &&
                    workHeadingItem.map((item, index) => (
                      <span
                        key={index}
                        className="work-drop"
                        dangerouslySetInnerHTML={{ __html: item.title }}
                      />
                    ))}
                </Slider>
              )}
            </h3>
          </div>
          <PageItem pageSize={6} />
        </div>
      </section>
    </>
  );
};

export default WorkList;
