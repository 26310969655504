import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import FormAddBlogList from './FormAddBlogList';
import FormEditBlogList from './FormEditBlogList';
import ReactPaginate from 'react-paginate';

const BlogList = () => {
  const [showForm, setShowForm] = useState(false);
  const [showFormEdit, setShowFormEdit] = useState(false);
  const [blogItem, setBlogItem] = useState([]);
  const [courseItemEdit, setCourseItemEdit] = useState('');
  const [refreshKey, setRefreshKey] = useState(0);
  let { id } = useParams();

  const handleAddProjectClick = () => {
    setShowForm(true);
  };
  const handleEditCourseItem = (item) => {
    setShowFormEdit(true);
    setCourseItemEdit(item);
  };
  const handleCloseProjectClick = () => {
    setShowForm(false);
  };
  const handleCloseFormCourseItem = () => {
    setShowFormEdit(false);
  };

  useEffect(() => {
    const fetchProjects = async () => {
      const res = await fetch(
        `https://api.chaulanart.com/blog/blog-child/${id}`
      );
      const data = await res.json();

      setBlogItem(data);
    };

    fetchProjects();
    setShowForm(false);
    setShowFormEdit(false);
  }, [id, refreshKey]);

  const deleteProject = (id) => {
    if (window.confirm('Bạn muốn xóa không?')) {
      fetch(`https://api.chaulanart.com/blog/delete-blog-child/${id}`, {
        method: 'DELETE',
      })
        .then((res) => res.json())
        .then(() => {
          // Fetch the list of projects again after a project has been deleted
          const fetchProjects = async () => {
            const res = await fetch(
              `https://api.chaulanart.com/blog/blog-child/${id}`
            );
            const data = await res.json();
            setBlogItem(data);
          };
          setRefreshKey((prevKey) => prevKey + 1);
          fetchProjects();
        })
        .catch((error) => console.error('Lỗi xóa:', error));
    }
  };

  function LayoutInPage({ itemInPage }) {
    return (
      <tbody className="project-list">
        {itemInPage.slice(0, 10).map((project) => (
          <tr>
            <td>
              <div dangerouslySetInnerHTML={{ __html: project.title }} />
            </td>
            <td>
              <p
                className="project-actions"
                onClick={() => handleEditCourseItem(project)}>
                Sửa
              </p>
            </td>
            <td>
              <Link
                to={`/admin/blog-detail/${project.blog_child_id}`}
                className="project-actions">
                Chi tiết
              </Link>
            </td>
            <td>
              <p
                className="project-actions"
                onClick={() => deleteProject(project.blog_child_id)}>
                Xóa
              </p>
            </td>
          </tr>
        ))}
      </tbody>
    );
  }
  const PageItem = ({ pageSize }) => {
    const [currentPage, setCurrentPage] = useState(0);
    const pageCount = Math.ceil(blogItem.length / pageSize);

    const handlePageClick = (selectedItem) => {
      setCurrentPage(selectedItem.selected);
      window.scrollTo(0, 0);
    };

    return (
      <>
        <LayoutInPage
          itemInPage={blogItem.slice(
            currentPage * pageSize,
            (currentPage + 1) * pageSize
          )}
        />
        <ReactPaginate
          previousLabel={currentPage === 0 ? '' : 'Prev'}
          nextLabel={currentPage === pageCount - 1 ? '' : 'Next'}
          onPageChange={handlePageClick}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          containerClassName="project-pagination"
          activeClassName="active"
          forcePage={currentPage}
        />
      </>
    );
  };

  return (
    <div class="recent-orders">
      <h1>Bài viết</h1>
      <div className="project-form">
        {showForm && (
          <>
            <FormAddBlogList
              idBlogCate={id}
              onRefresh={() => setRefreshKey(refreshKey + 1)}
            />
            <div
              className="project-detail-add"
              onClick={handleCloseProjectClick}>
              <span>Đóng</span>
            </div>
          </>
        )}
        {showFormEdit && (
          <>
            <FormEditBlogList
              idBlogCate={id}
              onRefresh={() => setRefreshKey(refreshKey + 1)}
              blogItem={courseItemEdit}
            />
            <div
              className="project-detail-add"
              onClick={handleCloseFormCourseItem}>
              <span>Đóng</span>
            </div>
          </>
        )}
      </div>
      <h2>Các bài viết</h2>
      <table>
        <thead>
          <tr>
            <th>Tên tiêu đề</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <PageItem pageSize={10} />
      </table>
      <div
        className="project-add"
        onClick={handleAddProjectClick}>
        <span>Thêm bài viết mới</span>
      </div>
    </div>
  );
};

export default BlogList;
