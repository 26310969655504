import React, { useState, useEffect, useRef } from 'react';
import './FeedbackCourse.css';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const FeedbackCourse = ({ id }) => {
  const [activeIndex, setActiveIndex] = useState(null);
  const contentRefs = useRef([]);
  const [courseQuestion, setCourseQuestion] = useState([]);
  const [courseFeedback, setCourseFeedback] = useState([]);
  const [courseFeedbackHeading, setCourseFeedbackHeading] = useState({});
  const [courseQuestionHeading, setCourseQuestionHeading] = useState({});

  // Accordion height adjustment
  useEffect(() => {
    contentRefs.current.forEach((content, index) => {
      if (content) {
        content.style.height =
          activeIndex === index ? `${content.scrollHeight}px` : '24px';
      }
    });
  }, [activeIndex]);
  useEffect(() => {
    const fetchProjects = async () => {
      const res = await fetch(
        `https://api.chaulanart.com/course/course-feedback/${id}`
      );
      const data = await res.json();

      setCourseFeedback(data);
    };
    const fetchFeedbackHeading = async () => {
      const res = await fetch(
        `https://api.chaulanart.com/course/course-feedback-heading`
      );
      const data = await res.json();

      setCourseFeedbackHeading(data);
    };
    const fetchQuestionHeading = async () => {
      const res = await fetch(
        `https://api.chaulanart.com/course/course-question-heading`
      );
      const data = await res.json();

      setCourseQuestionHeading(data);
    };

    fetchProjects();
    fetchFeedbackHeading();
    fetchQuestionHeading();
  }, [id]);

  // Event handlers
  const handleAccordionClick = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };
  useEffect(() => {
    const fetchCourseQuestion = async () => {
      const res = await fetch(
        `https://api.chaulanart.com/course/course-question/${id}`
      );
      const text = await res.text();
      const data = text ? JSON.parse(text) : {};
      setCourseQuestion(data);
    };
    fetchCourseQuestion();
  }, [id]);
  function SampleNextArrow(props) {
    const { className, onClick } = props;
    return (
      <div
        className={`${className} feedbackCourse-next`}
        onClick={onClick}>
        <i className="fas fa-chevron-right"></i>
      </div>
    );
  }

  function SamplePrevArrow(props) {
    const { className, onClick } = props;
    return (
      <div
        className={`${className} feedbackCourse-prev`}
        onClick={onClick}>
        {' '}
        <i className="fas fa-chevron-left"></i>
      </div>
    );
  }

  const itemCount = courseFeedback?.length || 0;

  var settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: Math.min(1, itemCount),
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  const checkURL = (url) => {
    return /\.(jpeg|jpg|gif|png)$/i.test(url);
  };
  const isValidYoutubeEmbedUrl = (url) => {
    const pattern = /^https:\/\/www\.youtube\.com\/embed\/.+/i;
    return pattern.test(url);
  };

  return (
    <section className="feedbackCourse">
      <div className="container">
        <div className="feedbackCourse-wrapper">
          <div className="feedbackCourse-feedback">
            <h3
              className="feedbackCourse-feedback-heading"
              dangerouslySetInnerHTML={{
                __html: courseFeedbackHeading.heading,
              }}
            />
            <div className="feedbackCourse-feedback-list">
              {courseFeedback.length > 1 ? (
                <Slider {...settings}>
                  {courseFeedback.map((item) => (
                    <div className="feedbackCourse-feedback-item">
                      <div
                        className="feedbackCourse-feedback-author"
                        key={item.id}>
                        {checkURL(item.image) ? (
                          <img
                            src={item.image}
                            title="Uploaded Image"
                            alt="Uploaded"
                            className="feedbackCourse-feedback-img"
                          />
                        ) : isValidYoutubeEmbedUrl(item.image) ? (
                          <iframe
                            src={item.image}
                            title="Uploaded Video"
                            alt="Uploaded"
                            className="feedbackCourse-feedback-img"
                          />
                        ) : (
                          <iframe
                            src={item.image}
                            title="Uploaded Video"
                            alt="Uploaded"
                            className="feedbackCourse-feedback-img"
                          />
                        )}
                        <div className="feedbackCourse-feedback-info">
                          <p
                            className="feedbackCourse-feedback-name"
                            dangerouslySetInnerHTML={{ __html: item.name }}
                          />
                          <p
                            className="feedbackCourse-feedback-desc"
                            dangerouslySetInnerHTML={{
                              __html: item.description,
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </Slider>
              ) : (
                courseFeedback.map((item) => (
                  <div className="feedbackCourse-feedback-item">
                    <div
                      className="feedbackCourse-feedback-author"
                      key={item.id}>
                      {checkURL(item.image) ? (
                        <img
                          src={item.image}
                          title="Uploaded Image"
                          alt="Uploaded"
                          className="feedbackCourse-feedback-img"
                        />
                      ) : isValidYoutubeEmbedUrl(item.image) ? (
                        <iframe
                          src={item.image}
                          title="Uploaded Video"
                          alt="Uploaded"
                          className="feedbackCourse-feedback-img"
                        />
                      ) : (
                        <iframe
                          src={item.image}
                          title="Uploaded Video"
                          alt="Uploaded"
                          className="feedbackCourse-feedback-img"
                        />
                      )}
                      <div className="feedbackCourse-feedback-info">
                        <p
                          className="feedbackCourse-feedback-name"
                          dangerouslySetInnerHTML={{ __html: item.name }}
                        />
                        <p
                          className="feedbackCourse-feedback-desc"
                          dangerouslySetInnerHTML={{
                            __html: item.description,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
          <div className="feedbackCourse-faq">
            <h3
              className="feedbackCourse-faq-heading"
              dangerouslySetInnerHTML={{
                __html: courseQuestionHeading.heading,
              }}
            />
            <div className="feedbackCourse-faq-process">
              {courseQuestion.map((faq, index) => (
                <div
                  key={index}
                  className="feedbackCourse-accordion">
                  <div
                    ref={(el) => (contentRefs.current[index] = el)}
                    className={`feedbackCourse-accordion-header ${
                      activeIndex === index ? 'is-active' : ''
                    }`}
                    onClick={() => handleAccordionClick(index)}>
                    <span
                      className={`feedbackCourse-accordion-title ${
                        activeIndex === index ? 'is-active' : ''
                      }`}
                      dangerouslySetInnerHTML={{ __html: faq.heading }}
                    />
                    <span
                      className={`feedbackCourse-accordion-text ${
                        activeIndex === index ? 'is-active' : ''
                      }`}
                      dangerouslySetInnerHTML={{ __html: faq.description }}
                    />
                    <div
                      className={`feedbackCourse-accordion-icon ${
                        activeIndex === index ? 'is-active' : ''
                      }`}></div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeedbackCourse;
