import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import FormEditBlogCate from './FormEditBlogCate';
import FormEditBlogThumnail from './FormEditBlogThumnail';

const BlogCate = () => {
  const [blogCate, setBlogCate] = useState([]);
  const [blogThumnail, setBlogThumnail] = useState({});
  const [blogCateItem, setBlogCateItem] = useState('');
  const [refreshKey, setRefreshKey] = useState(0);
  const [showFormEditBlogCate, setShowFormEditBlogCate] = useState(false);
  const [showFormEditBlogThumbnail, setShowFormEditBlogThumbnail] =
    useState(false);

  const handleAddFormCourseCate = (item) => {
    setShowFormEditBlogCate(true);
    setBlogCateItem(item);
  };
  const handleClodeFormEditCourseCate = () => {
    setShowFormEditBlogCate(false);
  };

  const handleOpenFormEditBlogThumbnail = () => {
    setShowFormEditBlogThumbnail(true);
  };
  const handleClodeFormEditBlogThumbnail = () => {
    setShowFormEditBlogThumbnail(false);
  };

  useEffect(() => {
    const fetchCourseCate = async () => {
      const res = await fetch(`https://api.chaulanart.com/blog/blog-cate`);
      const data = await res.json();
      setBlogCate(data);
    };
    const fetchCourseThumnail = async () => {
      const res = await fetch(
        `https://api.chaulanart.com/blog/get-blog-thumbnail`
      );
      const data = await res.json();
      setBlogThumnail(data);
    };
    fetchCourseCate();
    fetchCourseThumnail();
    setShowFormEditBlogCate(false);
    setShowFormEditBlogThumbnail(false);
  }, [refreshKey]);

  const checkURL = (url) => {
    if (typeof url !== 'string') {
      console.error('Invalid input: URL must be a string.', url);
      return false;
    }
    return url.match(/\.(jpeg|jpg|gif|png)$/) != null;
  };
  const isValidYoutubeEmbedUrl = (url) => {
    const pattern = /^https:\/\/www\.youtube\.com\/embed\/.+/i;
    return pattern.test(url);
  };

  return (
    <div className="podcastPage">
      <h1> Loại bài viết</h1>
      {showFormEditBlogCate && (
        <>
          <FormEditBlogCate
            onRefresh={() => setRefreshKey((oldKey) => oldKey + 1)}
            blogCate={blogCateItem}
          />
          <div
            className="workpage-add"
            onClick={handleClodeFormEditCourseCate}>
            <span>Đóng</span>
          </div>
        </>
      )}
      {blogCate &&
        blogCate.length > 0 &&
        blogCate.map((item) => (
          <>
            <Link
              className="podcastPage-header-link"
              to={`/admin/blog-cate/${item.blog_cate_id}`}>
              <div className="podcastPage-header">
                <div className="podcastPage-header-item">
                  <label>Hình thumbnail</label>
                  {checkURL(item.image) ? (
                    <img
                      src={item.image}
                      title="Uploaded Image"
                      alt="Uploaded"
                      className="project-image"
                    />
                  ) : isValidYoutubeEmbedUrl(item.image) ? (
                    <iframe
                      src={item.image}
                      title="Uploaded Video"
                      alt="Uploaded"
                      className="project-image"
                    />
                  ) : (
                    <iframe
                      src={item.image}
                      title="Uploaded Video"
                      alt="Uploaded"
                      className="project-image"
                    />
                  )}
                </div>
                <div className="podcastPage-info-item">
                  <label>Tiêu đề:</label>
                  <p>
                    <div dangerouslySetInnerHTML={{ __html: item.name }} />
                  </p>
                </div>
                <div className="podcastPage-header-item">
                  <label>Mô tả</label>
                  <p>
                    <div
                      dangerouslySetInnerHTML={{ __html: item.description }}
                    />
                  </p>
                </div>
              </div>
            </Link>
            <div
              className="project-detail-add"
              onClick={() => handleAddFormCourseCate(item)}>
              <span>Sửa thông tin</span>
            </div>
          </>
        ))}
      <h2>Thumbnail BlogPage</h2>
      {showFormEditBlogThumbnail ? (
        <>
          <FormEditBlogThumnail
            onRefresh={() => setRefreshKey(refreshKey + 1)}
            blogThumnail={blogThumnail}
          />
          <div
            className="workpage-add"
            onClick={handleClodeFormEditBlogThumbnail}>
            <span>Đóng</span>
          </div>
        </>
      ) : null}
      <div className="project-image-detail">
        <div className="project-image-detail-item">
          {checkURL(blogThumnail.thumnail) ? (
            <img
              src={blogThumnail.thumnail}
              title="Uploaded Image"
              alt="Uploaded"
              className="project-image"
            />
          ) : isValidYoutubeEmbedUrl(blogThumnail.thumnail) ? (
            <iframe
              src={blogThumnail.thumnail}
              title="Uploaded Video"
              alt="Uploaded"
              className="project-image"
            />
          ) : (
            <iframe
              src={blogThumnail.thumnail}
              title="Uploaded Video"
              alt="Uploaded"
              className="project-image"
            />
          )}
        </div>
      </div>
      <div
        className="project-detail-add"
        onClick={handleOpenFormEditBlogThumbnail}>
        <span>Sửa thông tin</span>
      </div>
    </div>
  );
};

export default BlogCate;
