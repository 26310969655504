import React from 'react';
import { useForm } from 'react-hook-form';
import { DevTool } from '@hookform/devtools';
import { useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import './FormCourse.css';
import * as yup from 'yup';
const schema = yup.object({
  name: yup
    .string()
    .trim()
    .required('Không được bỏ trống')
    .min(2, 'Tiêu đề có tối thiểu 2 ký tự'),
  phone: yup
    .string()
    .trim()
    .required('Không được bỏ trống')
    .matches(/^[0-9]+$/, 'Số điện thoại chỉ chứa chữ số')
    .min(10, 'Số điện thoại phải có tối thiểu 10 chữ số'),
  email: yup
    .string()
    .trim()
    .required('Không được bỏ trống')
    .email('Địa chỉ email không hợp lệ')
    .min(2, 'Tiêu đề có tối thiểu 2 ký tự'),
  description: yup
    .string()
    .trim()
    .required('Không được bỏ trống')
    .min(2, 'Mô tả có tối thiểu 10 ký tự')
    .max(4000, ' Mô tả có tối đa 4000 ký tự'),
});

const FormCourse = ({ id }) => {
  const form = useForm({
    defaultValues: {
      name: '',
      phone: '',
      email: '',
      description: '',
      course_item_id: id,
    },
    resolver: yupResolver(schema),
  });
  const { register, handleSubmit, reset, formState, control } = form;
  const { errors, isSubmitSuccessful } = formState;

  const handleSubmitProject = async (data) => {
    try {
      const url = 'https://api.chaulanart.com/contact/add';
      const opt = {
        method: 'post',
        body: JSON.stringify(data),
        headers: { 'Content-Type': 'application/json' },
      };
      const res = await fetch(url, opt);
      const reponseData = await res.json();
      alert('Đăng ký thành công,', reponseData);
    } catch (error) {
      console.error('Lỗi khi thêm sản phẩm: ', error);
    }
  };

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
  }, [isSubmitSuccessful, reset]);

  return (
    <section className="formCourse">
      <div className="container">
        <form
          onSubmit={handleSubmit(handleSubmitProject)}
          noValidate>
          <div className="formCoure-list">
            <h3 className="formCourse-heading">Đăng Ký Tư Vấn</h3>
            <div className="formCoure-info">
              <div className="formCoure-input">
                <div className="formCoure-input-item">
                  <input
                    type="text"
                    placeholder="Tên"
                    className="formCoure-input-info"
                    {...register('name')}
                  />
                  {errors.name && (
                    <p className="error">{errors.name.message}</p>
                  )}
                </div>
                <div className="formCoure-input-item">
                  <input
                    type="text"
                    placeholder="Số điện thoại"
                    className="formCoure-input-info"
                    {...register('phone')}
                  />
                  {errors.phone && (
                    <p className="error">{errors.phone.message}</p>
                  )}
                </div>
                <div className="formCoure-input-item">
                  <input
                    type="email"
                    placeholder="Email"
                    className="formCoure-input-info"
                    {...register('email')}
                  />
                  {errors.email && (
                    <p className="error">{errors.email.message}</p>
                  )}
                </div>
                <div className="formCoure-input-item">
                  <textarea
                    class="formCoure-input-info"
                    type="text"
                    placeholder="Mỗ tả"
                    rows="7"
                    {...register('description')}></textarea>
                  {errors.description && (
                    <p className="error">{errors.description.message}</p>
                  )}
                </div>
              </div>
              <button className="formCoure-button">Gửi thông tin</button>
            </div>
          </div>
        </form>
        <DevTool control={control} />
      </div>
    </section>
  );
};

export default FormCourse;
