import React, { useEffect, useState } from 'react';
import './BlogRelated.css';
import { Link } from 'react-router-dom';

const BlogRelated = ({ color = '', id }) => {
  const [blogItem, setBlogItem] = useState([]);

  useEffect(() => {
    const fetchProjects = async () => {
      const res = await fetch(
        `https://api.chaulanart.com/blog/get-blog-next/${id}`
      );
      const data = await res.json();
      setBlogItem(data);
    };
    fetchProjects();
  }, [id]);

  function convertToHyphenatedString(htmlString) {
    // Create a new DOM parser
    const parser = new DOMParser();

    // Parse the string into an HTML document
    const doc = parser.parseFromString(htmlString, 'text/html');

    // Select all <p> elements
    const paragraphs = doc.querySelectorAll('p');

    // Extract text content from each <p> element, join them with a hyphen, and convert to lowercase
    let combinedText = Array.from(paragraphs)
      .map((p) => p.textContent)
      .join('-')
      .replace(/ /g, '-')
      .toLowerCase();

    return combinedText;
  }

  return (
    <>
      {blogItem.length > 0 ? (
        <section className="blogRelated">
          <div className="blogRelated-list">
            {blogItem.length > 0 &&
              blogItem.map((blog) => (
                <Link
                  to={`/blog/insight-detail/${
                    blog.blog_child_id
                  }-${convertToHyphenatedString(blog.title)}`}>
                  <div
                    className={`blogChild-post-item ${
                      color === '#4285f4' ? 'insight' : 'thoughts'
                    }`}>
                    <h3
                      className="blogChild-post-title"
                      dangerouslySetInnerHTML={{ __html: blog.title }}
                    />
                  </div>
                </Link>
              ))}
          </div>
        </section>
      ) : null}
    </>
  );
};

export default BlogRelated;
