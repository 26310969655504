import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import './Blog.css';
import { Link } from 'react-router-dom';

const Blog = () => {
  const [blogCate, setBlogCate] = useState([]);
  const [blogThumbnail, setBlogThumbnail] = useState({});

  useEffect(() => {
    const fetchCourseCate = async () => {
      const res = await fetch(`https://api.chaulanart.com/blog/blog-cate`);
      const data = await res.json();
      setBlogCate(data);
    };
    const fetchBlogThumnail = async () => {
      const res = await fetch(
        `https://api.chaulanart.com/blog/get-blog-thumbnail`
      );
      const data = await res.json();
      setBlogThumbnail(data);
    };
    fetchCourseCate();
    fetchBlogThumnail();
    fetch(`https://api.chaulanart.com/header/header-menu`)
      .then((res) => res.json())
      .then((data) => {
        const title = data.find((item) => item.menu_id === 5);
        document.title = `${title.name} - Chau Lan Art `;
      });
  }, []);

  const pageDescription = blogThumbnail.description;
  const baseUrl = 'https://chaulanart.com';
  const pageUrl = `${baseUrl}/blog`;
  const imageUrl = blogThumbnail.thumnail;

  function convertToHyphenatedString(htmlString) {
    // Create a new DOM parser
    const parser = new DOMParser();

    // Parse the string into an HTML document
    const doc = parser.parseFromString(htmlString, 'text/html');

    // Select all <p> elements
    const paragraphs = doc.querySelectorAll('p');

    // Extract text content from each <p> element, join them with a hyphen, and convert to lowercase
    let combinedText = Array.from(paragraphs)
      .map((p) => p.textContent)
      .join('-')
      .replace(/ /g, '-')
      .toLowerCase();

    return combinedText;
  }

  return (
    <>
      <Helmet>
        <meta
          property="og:description"
          content={pageDescription}
        />
        <meta
          property="og:url"
          content={pageUrl}
        />
        <meta
          property="og:image"
          content={imageUrl}
        />
      </Helmet>
      <section className="blog">
        <div className="container">
          <div className="blog-list">
            {blogCate &&
              blogCate.length > 0 &&
              blogCate.map((item) => (
                <Link
                  to={`/blog/${
                    item.blog_cate_id === 1 ? 'insight' : 'thoughts'
                  }/${item.blog_cate_id}-${convertToHyphenatedString(
                    item.name
                  )}`}>
                  <div className="blog-item">
                    <h3
                      className={`blog-${
                        item.blog_cate_id === 1 ? 'insight' : 'thoughts'
                      }`}
                      dangerouslySetInnerHTML={{ __html: item.name }}
                    />
                    <p
                      className="blog-desc"
                      dangerouslySetInnerHTML={{ __html: item.description }}
                    />
                  </div>
                </Link>
              ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default Blog;
