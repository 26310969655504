import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import FormAddBlogDetail from './FormAddBlogDetail';
import FormEditBlogDetail from './FormEditBlogDetail';

const BlogDetail = () => {
  const [refreshKey, setRefreshKey] = useState(0);
  const [blogDetail, setBlogDetail] = useState([]);
  const [showFormAddBlogDetail, setShowFormAddBlogDetail] = useState(false);
  const [showFormEditBlogDetail, setShowFormEditBlogDetail] = useState(false);
  const [blogDetailItem, setBlogDetailItem] = useState('');
  let { id } = useParams();

  const handleOpenFormAddBlogDetail = () => {
    setShowFormAddBlogDetail(true);
  };
  const handleOpenFormEditBlogDetail = (item) => {
    setShowFormEditBlogDetail(true);
    setBlogDetailItem(item);
  };

  const handleCloseFormAddBlogDetail = () => {
    setShowFormAddBlogDetail(false);
  };
  const handleCloseFormEditBlogDetail = () => {
    setShowFormEditBlogDetail(false);
  };

  useEffect(() => {
    const fetchAboutHeader = async () => {
      const res = await fetch(
        `https://api.chaulanart.com/blog/blog-detail/${id}`
      );
      const text = await res.text();
      const data = text ? JSON.parse(text) : {};
      setBlogDetail(data);
    };
    fetchAboutHeader();
    setShowFormAddBlogDetail(false);
    setShowFormEditBlogDetail(false);
  }, [id, refreshKey]);

  const deleteProject = (id) => {
    if (window.confirm('Bạn muốn xóa không?')) {
      fetch(`https://api.chaulanart.com/blog/delete-blog-detail/${id}`, {
        method: 'DELETE',
      })
        .then((res) => res.json())
        .then(() => {
          // Fetch the list of projects again after a project has been deleted
          const fetchProjects = async () => {
            const res = await fetch(
              `https://api.chaulanart.com/blog/blog-detail/${id}`
            );
            const data = await res.json();
            setBlogDetail(data);
          };
          setRefreshKey((prevKey) => prevKey + 1);
          fetchProjects();
        })
        .catch((error) => console.error('Lỗi xóa:', error));
    }
  };

  return (
    <div className="podcastPage">
      <h1>Chi tiết bài viết</h1>

      {blogDetail && blogDetail.length > 0 ? (
        blogDetail.map((item) => (
          <>
            {showFormEditBlogDetail && (
              <>
                <FormEditBlogDetail
                  onRefresh={() => setRefreshKey((oldKey) => oldKey + 1)}
                  blogDetailItem={blogDetailItem}
                  idBlogChild={id}
                />
                <div
                  className="workpage-add"
                  onClick={handleCloseFormEditBlogDetail}>
                  <span>Đóng</span>
                </div>
              </>
            )}
            <div className="podcastPage-headers">
              <div className="podcastPage-info-item">
                <label>Tiêu đề:</label>
                <p>
                  <div dangerouslySetInnerHTML={{ __html: item.heading }} />
                </p>
              </div>
              <div className="podcastPage-header-item">
                <label>Mô tả</label>
                <p>
                  <div dangerouslySetInnerHTML={{ __html: item.description }} />
                </p>
              </div>
            </div>
            <div
              className="project-detail-add"
              onClick={() => handleOpenFormEditBlogDetail(item)}>
              <span>Sửa thông tin</span>
            </div>
            <div
              className="project-detail-add"
              onClick={() => deleteProject(item.blog_detail_id)}>
              <span>Xóa</span>
            </div>
          </>
        ))
      ) : (
        <div className="podcastPage-header">
          <div className="podcastPage-info-item">
            <label>Chưa có nội dung</label>
          </div>
        </div>
      )}
      {showFormAddBlogDetail && (
        <>
          <FormAddBlogDetail
            onRefresh={() => setRefreshKey(refreshKey + 1)}
            idBlogChild={id}
          />
          <div
            className="workpage-add"
            onClick={handleCloseFormAddBlogDetail}>
            <span>Đóng</span>
          </div>
        </>
      )}
      <div
        className="project-detail-add"
        onClick={handleOpenFormAddBlogDetail}>
        <span>Thêm thông tin</span>
      </div>
    </div>
  );
};

export default BlogDetail;
