import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { DevTool } from '@hookform/devtools';
import { useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import cloudinaryUpload from '../../../server/uploads.js';
import cloudinaryDelete from '../../../server/delete.js';
import * as yup from 'yup';

const schema = yup.object({
  logo: yup.string().required('Không được bỏ trống'),
  icon: yup.string().required('Không được bỏ trống'),
});

const FormHeader = ({ header, onRefresh }) => {
  const [uploadedImageUrlLogo, setUploadedImageUrlLogo] = useState('');
  const [uploadedImageUrlIcon, setUploadedImageUrlIcon] = useState('');
  const [uploadedImagePublicId, setUploadedImagePublicId] = useState('');
  const [isImageUploadedLogo, setIsImageUploadedLogo] = useState(false);
  const [isImageUploadedIcon, setIsImageUploadedIcon] = useState(false);
  const [isLoadedLogo, setIsLoadedLogo] = useState(false);
  const [isLoadedIcon, setIsLoadedIcon] = useState(false);

  const form = useForm({
    defaultValues: {
      logo: header.logo,
      icon: header.icon,
    },
    resolver: yupResolver(schema),
  });
  const { handleSubmit, reset, formState, control, setValue } = form;
  const { errors, isSubmitSuccessful } = formState;

  const handleFileUploadLogo = async (e) => {
    setIsLoadedLogo(true);
    const fileData = new FormData();
    fileData.append('file', e.target.files[0]);

    try {
      const res = await cloudinaryUpload(fileData);
      setUploadedImageUrlLogo(res.secure_url);
      setUploadedImagePublicId(res.publicId);
      setValue('logo', res.secure_url);
      setIsImageUploadedLogo(true);
    } catch (err) {
      console.error('Upload Error:', err);
    }
  };
  const handleFileUploadIcon = async (e) => {
    setIsLoadedIcon(true);
    const fileData = new FormData();
    fileData.append('file', e.target.files[0]);

    try {
      const res = await cloudinaryUpload(fileData);
      setUploadedImageUrlIcon(res.secure_url);
      setUploadedImagePublicId(res.publicId);
      setValue('icon', res.secure_url);
      setIsImageUploadedIcon(true);
    } catch (err) {
      console.error('Upload Error:', err);
    }
  };
  const handleDeleteImageLogo = async () => {
    if (!uploadedImagePublicId) {
      alert('No image to delete');
      return;
    }
    try {
      cloudinaryDelete(uploadedImagePublicId);
      setUploadedImageUrlLogo('');
      setUploadedImagePublicId('');
      setIsImageUploadedLogo(false);
      setValue('logo', '');
      setIsLoadedLogo(false);
    } catch (err) {
      console.error('Deletion Error:', err.message);
      alert('Failed to delete image');
    }
  };
  const handleDeleteImageIcon = async () => {
    if (!uploadedImagePublicId) {
      alert('No image to delete');
      return;
    }
    try {
      cloudinaryDelete(uploadedImagePublicId);
      setUploadedImageUrlIcon('');
      setUploadedImagePublicId('');
      setIsImageUploadedIcon(false);
      setValue('icon', '');
      setIsLoadedIcon(false);
    } catch (err) {
      console.error('Deletion Error:', err.message);
      alert('Failed to delete image');
    }
  };

  const handleSubmitProject = async (data) => {
    try {
      const url = `https://api.chaulanart.com/header/update-header`;
      const opt = {
        method: 'put',
        body: JSON.stringify(data),
        headers: { 'Content-Type': 'application/json' },
      };
      const res = await fetch(url, opt);
      const reponseData = await res.json();
      alert('Xác nhận thành công,', reponseData);
    } catch (error) {
      console.error('Lỗi khi cập nhật sản phẩm: ', error);
    }
  };

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
      onRefresh();
    }
  }, [isSubmitSuccessful, reset, onRefresh]);

  return (
    <div className="form-project">
      <form
        onSubmit={handleSubmit(handleSubmitProject)}
        noValidate>
        <div className="form-project-item">
          <div className="form-project-flex">
            <div className="form-project-flex-item">
              <label>Logo Website</label>
              <input
                type="file"
                onChange={(e) => handleFileUploadLogo(e)}
                accept="image/*"
              />
              {errors.logo && <p className="error">{errors.logo.message}</p>}
            </div>
            <div className="form-project-flex-item">
              {/* Hiển thị hình ảnh đã tải lên */}
              {isLoadedLogo ? (
                isImageUploadedLogo && isImageUploadedLogo ? (
                  <div className="form-project-flex-img">
                    <img
                      src={uploadedImageUrlLogo}
                      title="Uploaded Image"
                      alt="Uploaded"
                    />
                    <p onClick={handleDeleteImageLogo}>Xóa</p>
                  </div>
                ) : (
                  <p className="project-loading">Loading ...</p>
                )
              ) : (
                <div className="form-project-flex-img">
                  <img
                    src={header.logo}
                    title="Uploaded Image"
                    alt="Uploaded"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="form-project-item">
          <div className="form-project-flex">
            <div className="form-project-flex-item">
              <label>Icon Website</label>
              <input
                type="file"
                onChange={(e) => handleFileUploadIcon(e)}
                accept="image/*"
              />
              {errors.icon && <p className="error">{errors.icon.message}</p>}
            </div>
            <div className="form-project-flex-item">
              {/* Hiển thị hình ảnh đã tải lên */}
              {isLoadedIcon ? (
                isImageUploadedIcon && isImageUploadedIcon ? (
                  <div className="form-project-flex-img">
                    <img
                      src={uploadedImageUrlIcon}
                      title="Uploaded Image"
                      alt="Uploaded"
                    />
                    <p onClick={handleDeleteImageIcon}>Xóa</p>
                  </div>
                ) : (
                  <p className="project-loading">Loading ...</p>
                )
              ) : (
                <div className="form-project-flex-img">
                  <img
                    src={header.icon}
                    title="Uploaded Image"
                    alt="Uploaded"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="form-project-item">
          <button>Xác nhận</button>
        </div>
      </form>
      <DevTool control={control} />
    </div>
  );
};

export default FormHeader;
