import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import './BlogDetail.css';
import BlogRelated from './BlogRelated/BlogRelated';
import { useParams } from 'react-router-dom';

const BlogDetail = ({ color = '' }) => {
  let { id } = useParams();
  const [blogDetail, setBlogDetail] = useState([]);
  const [blogId, setBlogID] = useState({});

  function stripHtml(html) {
    // Temporary element for parsing HTML content
    var tempDivElement = document.createElement('div');
    tempDivElement.innerHTML = html;
    return tempDivElement.textContent || tempDivElement.innerText || '';
  }

  useEffect(() => {
    const fetchblogid = async () => {
      const res = await fetch(
        `https://api.chaulanart.com/blog/blog-child-detail/${id}`
      );
      const text = await res.text();
      const data = text ? JSON.parse(text) : {};
      setBlogID(data);
    };
    const fetchAboutHeader = async () => {
      const res = await fetch(
        `https://api.chaulanart.com/blog/blog-detail/${id}`
      );
      const text = await res.text();
      const data = text ? JSON.parse(text) : {};
      setBlogDetail(data);
    };
    fetchAboutHeader();
    fetchblogid();
    const textTitle = stripHtml(blogId.title);
    document.title = `${textTitle} - Chau Lan Art`;
  }, [id, blogId.title, blogDetail]);

  const pageDescription = stripHtml(blogId.title);
  const baseUrl = 'https://chaulanart.com';
  const pageUrlThought = `${baseUrl}/thoughts-detail/${id}`;
  const pageUrlInsight = `${baseUrl}/insight-detail/${id}`;
  const imageUrl = blogId.thumnail;

  return (
    <>
      <Helmet>
        fetch
        <meta
          property="og:description"
          content={pageDescription}
        />
        <meta
          property="og:url"
          content={pageUrlThought || pageUrlInsight}
        />
        <meta
          property="og:image"
          content={imageUrl}
        />
      </Helmet>
      <section className="blogDetail">
        <div className="container">
          <div className="blogDetail-view">
            <div className="blogDetail-top">
              <h1
                className="blogDetail-heading"
                style={{ color: color }}
                dangerouslySetInnerHTML={{ __html: blogId.title }}
              />
            </div>
            {blogDetail &&
              blogDetail.length > 0 &&
              blogDetail.map((item) => (
                <div className="blogDetail-content">
                  <h3
                    className="blogDetail-title"
                    dangerouslySetInnerHTML={{ __html: item.heading }}
                  />
                  <p
                    className="blogDetail-desc"
                    dangerouslySetInnerHTML={{ __html: item.description }}
                  />
                </div>
              ))}
          </div>
          <BlogRelated
            color={color}
            id={id}></BlogRelated>
        </div>
      </section>
    </>
  );
};

export default BlogDetail;
