import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import FromEditAboutAward from './FormEditAboutAward';

const AboutAwardDetail = () => {
  const [aboutAwardDetail, setAboutAwardDetail] = useState({});
  const [showForm, setShowForm] = useState(false);
  const [refreshKey, setRefreshKey] = useState(0);
  const handleRefresh = useCallback(
    () => setRefreshKey(refreshKey + 1),
    [refreshKey]
  );
  let { id } = useParams();

  const handleOpenAboutAddAward = () => {
    setShowForm(true);
  };
  const handleCloseAboutAddAward = () => {
    setShowForm(false);
  };

  useEffect(() => {
    const fetchAboutAward = async () => {
      const res = await fetch(
        `https://api.chaulanart.com/about/about-awards/${id}`
      );
      const data = await res.json();
      setAboutAwardDetail(data);
    };

    fetchAboutAward();
  }, [id, refreshKey]);

  const checkURL = (url) => {
    return /\.(jpeg|jpg|gif|png)$/i.test(url);
  };
  const isValidYoutubeEmbedUrl = (url) => {
    const pattern = /^https:\/\/www\.youtube\.com\/embed\/.+/i;
    return pattern.test(url);
  };

  return (
    <div className="project-detail">
      <h1>Chi tiết dự án</h1>
      {showForm && (
        <>
          <FromEditAboutAward
            aboutAward={aboutAwardDetail}
            onRefresh={handleRefresh}
          />
          <div
            className="project-detail-add"
            onClick={handleCloseAboutAddAward}>
            <span>Đóng</span>
          </div>
        </>
      )}
      <div className="project-detail-info">
        <div className="project-detail-info-item">
          <label>Hình minh họa</label>
          {checkURL(aboutAwardDetail.image) ? (
            <img
              src={aboutAwardDetail.image}
              title="Uploaded Image"
              alt="Uploaded"
              className="project-detail-image"
            />
          ) : isValidYoutubeEmbedUrl(aboutAwardDetail.image) ? (
            <iframe
              src={aboutAwardDetail.image}
              title="Uploaded Video"
              alt="Uploaded"
              className="project-detail-image"
            />
          ) : (
            <iframe
              src={aboutAwardDetail.image}
              title="Uploaded Video"
              alt="Uploaded"
              className="project-detail-image"
            />
          )}
        </div>
        <div className="project-detail-info-item">
          <label>Tên dự án</label>
          <div dangerouslySetInnerHTML={{ __html: aboutAwardDetail.heading }} />
        </div>
        <div className="project-detail-info-item">
          <label>Mô tả</label>
          <div
            dangerouslySetInnerHTML={{ __html: aboutAwardDetail.description }}
          />
        </div>
        <div className="project-detail-info-item">
          <label>Thời gian</label>
          <div dangerouslySetInnerHTML={{ __html: aboutAwardDetail.time }} />
        </div>
      </div>
      <div
        className="project-detail-add"
        onClick={handleOpenAboutAddAward}>
        <span>Sửa thông tin</span>
      </div>
    </div>
  );
};

export default AboutAwardDetail;
