import React from 'react';
import './Home.css';
import ProjectHome from '../ProjecHome/ProjectHome';
import ContactHome from '../Contact/ContactHome';

const HomeAdmin = () => {
  return (
    <div className="homeAdmin">
      <h1>Dashboard</h1>
      <div className="homeAdmin-cards">
        <ProjectHome />
        <ContactHome />
      </div>
    </div>
  );
};

export default HomeAdmin;
