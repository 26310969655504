import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import Nav from './Components/Nav/Nav';
import Sidebar from './Components/Sidebar/Sidebar';
import './App.css';
import Accout from './Components/Accout/Accout';
import Home from './Components/Home/Home';
import Register from './Components/Register/Register';
import Profile from './Components/Profile/Profile';
import ProjectList from './Components/ProjectList/ProjectList';
import ProjectDetail from './Components/ProjectDetail/ProjectDetail';
import WorkPage from './Components/WorkPage/WorkPage';
import ExperPage from './Components/ExperPage/ExperPage';
import PodcastPage from './Components/PodcastPage/PodcastPage';
import AboutPage from './Components/AboutPage/AboutPage';
import AboutAward from './Components/AboutAward/AboutAward';
import AboutAwardDetail from './Components/AboutAward/AboutAwardDetail';
import AboutEx from './Components/AboutEx/AboutEx';
import AboutExDetail from './Components/AboutEx/AboutExDetail';
import CoursePage from './Components/CoursePage/CoursePage';
import CourseItem from './Components/CoursePage/CourseItem';
import CourseDetail from './Components/CourseDetail/CourseDetail';
import Student from './Components/Student/Student';
import Feedback from './Components/Feedback/Feedback';
import BlogCate from './Components/Blog/BlogCate';
import BlogList from './Components/Blog/BlogList';
import BlogDetail from './Components/Blog/BlogDetail';
import Footer from './Components/Footer/Footer';
import Contact from './Components/Contact/Contact';
import UpdatePass from './Components/Accout/UpdatePass';
import HeaderPage from './Components/HeaderPage/HeaderPage';

const App = () => {
  useEffect(() => {
    document.title = 'Admin - Chau Lan Art';
  }, []);
  return (
    <div className="wrapper-admin">
      <div className="container-admin">
        <Sidebar />
        <main className="mainAdmin">
          <Nav />
          <Routes>
            <Route
              path="/"
              element={<Home />}
            />
            <Route
              path="/account"
              element={<Accout />}
            />
            <Route
              path="/expertise-page"
              element={<ExperPage />}
            />
            <Route
              path="/work-page"
              element={<WorkPage />}
            />
            <Route
              path="/podcast-page"
              element={<PodcastPage />}
            />
            <Route
              path="/about-page"
              element={<AboutPage />}
            />
            <Route
              path="/about-awards"
              element={<AboutAward />}
            />
            <Route
              path="/about-awards/:id"
              element={<AboutAwardDetail />}
            />
            <Route
              path="/about-ex-content"
              element={<AboutEx />}
            />
            <Route
              path="/about-ex-content/:id"
              element={<AboutExDetail />}
            />
            <Route
              path="/course-page"
              element={<CoursePage />}
            />
            <Route
              path="/course-detail/:id"
              element={<CourseDetail />}
            />
            <Route
              path="/course-cate/:id"
              element={<CourseItem />}
            />
            <Route
              path="/course-student/:id"
              element={<Student />}
            />
            <Route
              path="/course-feedback/:id"
              element={<Feedback />}
            />
            <Route
              path="/contact"
              element={<Contact />}
            />
            <Route
              path="/header"
              element={<HeaderPage />}
            />
            <Route
              path="/blog-page"
              element={<BlogCate />}
            />
            <Route
              path="/blog-cate/:id"
              element={<BlogList />}
            />
            <Route
              path="/blog-detail/:id"
              element={<BlogDetail />}
            />
            <Route
              path="/new-login"
              element={<Register />}
            />
            <Route
              path="/profile/:id"
              element={<Profile />}
            />
            <Route
              path="/projects"
              element={<ProjectList />}
            />
            <Route
              path="/reset-password"
              element={<UpdatePass />}
            />
            <Route
              path="/footer"
              element={<Footer />}
            />
            <Route
              path="/projects/:id"
              element={<ProjectDetail />}
            />
          </Routes>
        </main>
      </div>
    </div>
  );
};

export default App;
