import React, { useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { DevTool } from '@hookform/devtools';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import quillEmoji from 'react-quill-emoji';
import 'react-quill-emoji/dist/quill-emoji.css';
import { SnowTheme } from 'quill-color-picker-enhance';
import 'quill-color-picker-enhance/dist/index.css';
Quill.register('themes/snow-quill-color-picker-enhance', SnowTheme);

Quill.register(
  {
    'formats/emoji': quillEmoji.EmojiBlot,
    'modules/emoji-toolbar': quillEmoji.ToolbarEmoji,
    'modules/emoji-shortname': quillEmoji.ShortNameEmoji,
  },
  true
);

const schema = yup.object({
  heading: yup
    .string()
    .min(2, 'Tiêu đề có tối thiểu 2 ký tự')
    .required('Không được bỏ trống'),
});

let Parchment = Quill.import('parchment');
let LineHeightAttributor = new Parchment.Attributor.Style(
  'lineHeight', // Sử dụng camelCase cho tên định dạng
  'line-height',
  {
    scope: Parchment.Scope.BLOCK_ATTRIBUTE,
  }
);
Quill.register(
  {
    'formats/lineHeight': LineHeightAttributor,
  },
  true
);

var Size = Quill.import('attributors/style/size');
Size.whitelist = Array.from(
  { length: 100 },
  (_, i) => (i + 1).toString() + 'px'
);
Quill.register(Size, true);

const FormExperHeading = ({ experHeading = null, onRefresh }) => {
  const reactQuillRef = useRef(null);

  useEffect(() => {
    let sizePickerItems = document.querySelectorAll('.ql-size .ql-picker-item');
    let lHeightPickerItems = document.querySelectorAll(
      '.ql-lineHeight .ql-picker-item'
    );

    for (let i = 0; i < sizePickerItems.length; i++) {
      let item = sizePickerItems[i];
      let value = item.getAttribute('data-value');
      if (value) {
        item.textContent = value;
      }
    }
    for (let i = 0; i < lHeightPickerItems.length; i++) {
      let item = lHeightPickerItems[i];
      let value = item.getAttribute('data-value');
      if (value) {
        item.textContent = value;
      }
    }
  }, []);

  const form = useForm({
    defaultValues: {
      heading: experHeading.heading,
    },
    resolver: yupResolver(schema),
  });
  const { handleSubmit, reset, formState, control } = form;
  const { errors, isSubmitSuccessful } = formState;

  const handleSubmitProjectImage = async (data) => {
    function convertToUnicode(input) {
      return input.replace(
        /([\uD800-\uDBFF][\uDC00-\uDFFF])/g,
        function (char) {
          return '&#x' + char.codePointAt(0).toString(16) + ';';
        }
      );
    }
    function convertObjectToUnicode(obj) {
      for (let key in obj) {
        if (typeof obj[key] === 'object') {
          convertObjectToUnicode(obj[key]); // Nếu phần tử là một đối tượng, gọi đệ quy
        } else if (typeof obj[key] === 'string') {
          obj[key] = convertToUnicode(obj[key]); // Nếu phần tử là một chuỗi, chuyển đổi thành Unicode
        }
      }
      return obj;
    }

    const convertedData = convertObjectToUnicode(data);
    try {
      const url =
        'https://api.chaulanart.com/expertise-page/edit-expertise-heading';
      const opt = {
        method: 'put',
        body: JSON.stringify(convertedData),
        headers: { 'Content-Type': 'application/json' },
      };
      const res = await fetch(url, opt);
      const reponseData = await res.json();
      alert('Xác nhận thành công,', reponseData);
    } catch (error) {
      console.error('Lỗi: ', error);
    }
  };
  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
      onRefresh();
    }
  }, [isSubmitSuccessful, reset, onRefresh]);
  return (
    <div className="form-project-visual">
      <form onSubmit={handleSubmit(handleSubmitProjectImage)}>
        <div className="form-project-item">
          <label>Tên tiêu đề</label>
          <ReactQuill
            ref={reactQuillRef}
            theme="snow-quill-color-picker-enhance"
            value={form.watch('heading')}
            onChange={(content) => form.setValue('heading', content)}
            modules={{
              toolbar: {
                container: [
                  ['bold', 'italic', 'underline', 'strike'],
                  ['blockquote', 'code-block'],
                  [{ list: 'ordered' }, { list: 'bullet' }],
                  [{ script: 'sub' }, { script: 'super' }],
                  [{ indent: '-1' }, { indent: '+1' }],
                  [{ direction: 'rtl' }],
                  [
                    {
                      lineHeight: [
                        '1',
                        '1.1',
                        '1.2',
                        '1.3',
                        '1.4',
                        '1.5',
                        '1.6',
                        '1.7',
                        '1.8',
                        '1.9',
                        '2',
                        '2.5',
                        '3',
                      ],
                    },
                  ], // Đảm bảo cú pháp này chính xác
                  [{ size: Size.whitelist }],
                  [{ header: [2, 3, 4, 5, 6, false] }],
                  [{ color: [] }, { background: [] }],
                  [{ font: [] }],
                  [{ align: [] }],
                  ['clean'],
                  ['link'],
                  ['emoji'],
                ],
                'emoji-toolbar': true,
              },
              'emoji-textarea': true,
              'emoji-shortname': true,
              clipboard: {
                matchVisual: false,
              },
            }}
          />
          {errors.heading && <p className="error">{errors.heading.message}</p>}
        </div>
        <div className="form-project-item">
          <button>Xác nhận</button>
        </div>
      </form>
      <DevTool control={control} />
    </div>
  );
};

export default FormExperHeading;
