import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import FormAddCourserItem from './FormAddCourserItem';
import FormEditCourserItem from './FormEditCourseItem';

const CourseItem = () => {
  const [showForm, setShowForm] = useState(false);
  const [showFormEdit, setShowFormEdit] = useState(false);
  const [course_item, setCourseItem] = useState([]);
  const [courseItemEdit, setCourseItemEdit] = useState('');
  const [refreshKey, setRefreshKey] = useState(0);
  let { id } = useParams();

  const handleAddProjectClick = () => {
    setShowForm(true);
  };
  const handleEditCourseItem = (item) => {
    setShowFormEdit(true);
    setCourseItemEdit(item);
  };
  const handleCloseProjectClick = () => {
    setShowForm(false);
  };
  const handleCloseFormCourseItem = () => {
    setShowFormEdit(false);
  };

  useEffect(() => {
    const fetchProjects = async () => {
      const res = await fetch(
        `https://api.chaulanart.com/course/course-cate/${id}`
      );
      const data = await res.json();

      setCourseItem(data);
    };

    fetchProjects();
    setShowForm(false);
    setShowFormEdit(false);
  }, [id, refreshKey]);

  const checkURL = (url) => {
    return /\.(jpeg|jpg|gif|png)$/i.test(url);
  };
  const isValidYoutubeEmbedUrl = (url) => {
    const pattern = /^https:\/\/www\.youtube\.com\/embed\/.+/i;
    return pattern.test(url);
  };

  const deleteProject = (id) => {
    if (window.confirm('Bạn muốn xóa khóa học này không?')) {
      fetch(`https://api.chaulanart.com/course/delete-course-item/${id}`, {
        method: 'DELETE',
      })
        .then((res) => res.json())
        .then(() => {
          // Fetch the list of projects again after a project has been deleted
          const fetchProjects = async () => {
            const res = await fetch(
              `https://api.chaulanart.com/course/course-cate/${id}`
            );
            const data = await res.json();
            setCourseItem(data);
          };
          setRefreshKey((prevKey) => prevKey + 1);
          fetchProjects();
        })
        .catch((error) => console.error('Lỗi xóa:', error));
    }
  };

  return (
    <div class="recent-orders">
      <h1>Khóa học</h1>
      <div className="project-form">
        {showForm && (
          <>
            <FormAddCourserItem
              idCate={id}
              onRefresh={() => setRefreshKey(refreshKey + 1)}
            />
            <div
              className="project-detail-add"
              onClick={handleCloseProjectClick}>
              <span>Đóng</span>
            </div>
          </>
        )}
        {showFormEdit && (
          <>
            <FormEditCourserItem
              idCate={id}
              onRefresh={() => setRefreshKey(refreshKey + 1)}
              courseItem={courseItemEdit}
            />
            <div
              className="project-detail-add"
              onClick={handleCloseFormCourseItem}>
              <span>Đóng</span>
            </div>
          </>
        )}
      </div>
      <h2>Các khóa học</h2>
      <table>
        <thead>
          <tr>
            <th>Tên Khóa học</th>
            <th>Hình thumbnail</th>
            <th>Trạng thái</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody className="project-list">
          {course_item.map((project) => (
            <tr>
              <td>
                <div dangerouslySetInnerHTML={{ __html: project.name }} />
              </td>
              <td>
                {checkURL(project.image) ? (
                  <img
                    src={project.image}
                    title="Uploaded Image"
                    alt="Uploaded"
                    className="project-image"
                  />
                ) : isValidYoutubeEmbedUrl(project.image) ? (
                  <iframe
                    src={project.image}
                    title="Uploaded Video"
                    alt="Uploaded"
                    className="project-image"
                  />
                ) : (
                  <iframe
                    src={project.image}
                    title="Uploaded Video"
                    alt="Uploaded"
                    className="project-image"
                  />
                )}
              </td>
              <td>{project.status === 1 ? 'Hoàn thành' : 'Coming soon'}</td>
              <td>
                <p
                  className="project-actions"
                  onClick={() => handleEditCourseItem(project)}>
                  Sửa
                </p>
              </td>
              <td>
                <Link
                  to={`/admin/course-detail/${project.course_item_id}`}
                  className="project-actions">
                  Chi tiết
                </Link>
              </td>
              <td>
                <p
                  className="project-actions"
                  onClick={() => deleteProject(project.course_item_id)}>
                  Xóa
                </p>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div
        className="project-add"
        onClick={handleAddProjectClick}>
        <span>Thêm khóa mới</span>
      </div>
    </div>
  );
};

export default CourseItem;
