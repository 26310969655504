import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from 'react-router-dom';
import UserApp from './user/App';
import AdminApp from './admin/App';
import LoginPage from './LoginPage';
import { useSelector } from 'react-redux';
import { ProtectedRoute } from './ProtectedRoute';
import { useDispatch } from 'react-redux';
import { dalogin } from './authSlice';
import ForgotPassword from './ForgotPassword';
// import { Helmet, HelmetProvider } from 'react-helmet-async';

const updateFavicon = (faviconUrl) => {
  let link = document.querySelector("link[rel*='icon']");
  if (!link) {
    link = document.createElement('link');
    link.rel = 'icon';
    document.getElementsByTagName('head')[0].appendChild(link);
  }
  link.href = faviconUrl;
};

const App = () => {
  const userIsAuthenticated = useSelector((state) => state.auth.daDangNhap);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchHeader = async () => {
      const res = await fetch(`https://api.chaulanart.com/header/header`);
      const data = await res.json();
      updateFavicon(data.icon);
    };
    fetchHeader();
  }, []);

  useEffect(() => {
    // Kiểm tra localStorage khi ứng dụng khởi động
    const result = localStorage.getItem('result');
    if (result) {
      // Chuyển đổi chuỗi JSON trở lại thành đối tượng và đưa vào Redux store
      dispatch(dalogin(JSON.parse(result)));
    }
  }, [dispatch]);

  return (
    <Router>
      <Routes>
        <Route
          path="/login"
          element={<LoginPage />}
        />
        <Route
          path="/forgot-password"
          element={<ForgotPassword />}
        />
        <Route element={<ProtectedRoute />}>
          <Route
            path="/admin/*"
            element={
              userIsAuthenticated ? (
                <AdminApp />
              ) : (
                <Navigate
                  replace
                  to="/login"
                />
              )
            }
          />
        </Route>
        <Route
          path="/*"
          element={<UserApp />}
        />
      </Routes>
    </Router>
  );
};

export default App;
