import React, { useRef, useEffect, useState } from 'react';
import { debounce } from 'lodash';
import './Register.css';

const Register = () => {
  const username = useRef();
  const email = useRef();
  const phone = useRef();
  const password = useRef();
  const [isExistingEmail, setIsExistingEmail] = useState(false);
  const [isCheckingEmail, setIsCheckingEmail] = useState(false);

  const checkEmail = debounce(async () => {
    setIsCheckingEmail(true);
    const emailValue = email.current?.value;
    if (emailValue) {
      const response = await fetch(
        `https://api.chaulanart.com/users/check-email/${emailValue}`
      );
      const data = await response.json();
      setIsExistingEmail(data);
      setIsCheckingEmail(false);
    }
  }, 1000);

  useEffect(() => {
    checkEmail();
  }, [email.current?.value, checkEmail]);
  function validateEmail(email) {
    const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    return emailRegex.test(email);
  }

  function validatePhone(phone) {
    const phoneRegex = /^0\d{9}$/;
    return phoneRegex.test(phone);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      username: username.current.value,
      email: email.current.value,
      password: password.current.value,
      phone: phone.current.value,
    };
    if (data.name === '') {
      alert('Họ tên không được để trống');
      return false;
    }
    if (data.email === '') {
      alert('Email không được để trống');
      return false;
    }
    if (!validateEmail(data.email)) {
      alert('Email không hợp lệ');
      return false;
    }
    if (isCheckingEmail) {
      alert('Đang kiểm tra email. Vui lòng đợi!');
      return false;
    }
    if (isExistingEmail) {
      alert('Email đã tồn tại. Vui lòng sử dụng email khác!');
      return false;
    }
    if (data.password === '') {
      alert('Mật khẩu không được để trống');
      return false;
    }
    if (data.phone === '') {
      alert('Số điện thoại không được để trống');
      return false;
    }
    if (!validatePhone(data.phone)) {
      alert('Số điện thoại không hợp lệ');
      return false;
    }
    try {
      const response = await fetch(
        'https://api.chaulanart.com/users/register',
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(data),
        }
      );
      const result = await response.json();
      if (result.success) {
        alert(result.success);
        username.current.value = '';
        email.current.value = '';
        phone.current.value = '';
        password.current.value = '';
      } else {
        alert('Đăng ký không thành công. Vui lòng thử lại.');
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="resgiter">
      <h1>New Login</h1>
      <div className="resgiter-user">
        <h2>Đăng ký tài khoản</h2>
        <form
          onSubmit={handleSubmit}
          encType="multipart/form-data">
          <div className="resgiter-user-item">
            <label>Tên tài khoản</label>
            <input
              type="text"
              ref={username}
              placeholder="Admin"
            />
            <p className="err"></p>
          </div>
          <div className="resgiter-user-item">
            <label>Email</label>
            <input
              type="email"
              ref={email}
              placeholder="adminchaulan@gmail.com"
              onChange={checkEmail}
            />
            {isExistingEmail && <p className="err">Email đã tồn tại!</p>}
          </div>
          <div className="resgiter-user-item">
            <label>Số điện thoại</label>
            <input
              type="text"
              ref={phone}
              placeholder="0123456789"
            />
            <p className="err"></p>
          </div>
          <div className="resgiter-user-item">
            <label>Password</label>
            <input
              type="password"
              placeholder="password"
              ref={password}
            />
            <p className="err"></p>
          </div>
          <div className="resgiter-user-item">
            <button>Đăng ký</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Register;
