import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { DevTool } from '@hookform/devtools';
import { useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import cloudinaryUpload from '../../../server/uploads.js';
import cloudinaryDelete from '../../../server/delete.js';
import * as yup from 'yup';

const schema = yup.object({
  thumnail: yup.string().required('Không được bỏ trống'),
  description: yup.string().required('Không được bỏ trống'),
});

const FormEditPodcastThumnail = ({ onRefresh, podcastThumnail }) => {
  const [uploadedImageUrl, setUploadedImageUrl] = useState('');
  const [uploadedImagePublicId, setUploadedImagePublicId] = useState('');
  const [isImageUploaded, setIsImageUploaded] = useState(false);
  const [uploadOption, setUploadOption] = useState('file');
  const [isLoaded, setIsLoaded] = useState(false);

  const form = useForm({
    defaultValues: {
      thumnail: podcastThumnail.thumnail,
      description: podcastThumnail.description,
    },
    resolver: yupResolver(schema),
  });
  const { register, handleSubmit, reset, formState, control, setValue } = form;
  const { errors, isSubmitSuccessful } = formState;

  const handleFileUpload = async (e) => {
    setIsLoaded(true);
    const fileData = new FormData();
    fileData.append('file', e.target.files[0]);

    try {
      const res = await cloudinaryUpload(fileData);
      setUploadedImageUrl(res.secure_url);
      setUploadedImagePublicId(res.publicId);
      setValue('thumnail', res.secure_url);
      setIsImageUploaded(true);
    } catch (err) {
      console.error('Upload Error:', err);
    }
  };

  const handleUploadOptionChange = (e) => {
    setUploadOption(e.target.value);
  };
  const extractSrcFromIframe = (iframeString) => {
    const srcMatch = iframeString.match(
      /src\s*=\s*"([^"]+)"|src\s*=\s*'([^']+)'/
    );
    return srcMatch ? srcMatch[1] || srcMatch[2] : null;
  };

  const handleInputChange = (e) => {
    const embedCode = e.target.value;
    const url = extractSrcFromIframe(embedCode);
    setValue('thumnail', url);
  };
  const checkURL = (url) => {
    return /\.(jpeg|jpg|gif|png)$/i.test(url);
  };
  const isValidYoutubeEmbedUrl = (url) => {
    const pattern = /^https:\/\/www\.youtube\.com\/embed\/.+/i;
    return pattern.test(url);
  };

  const handleDeleteImage = async () => {
    if (!uploadedImagePublicId) {
      alert('No image to delete');
      return;
    }
    try {
      cloudinaryDelete(uploadedImagePublicId);
      setUploadedImageUrl('');
      setUploadedImagePublicId('');
      setIsImageUploaded(false);
      setValue('thumnail', '');
      setIsLoaded(false);
    } catch (err) {
      console.error('Deletion Error:', err.message);
      alert('Failed to delete image');
    }
  };

  const handleSubmitProject = async (data) => {
    function convertToUnicode(input) {
      return input.replace(
        /([\uD800-\uDBFF][\uDC00-\uDFFF])/g,
        function (char) {
          return '&#x' + char.codePointAt(0).toString(16) + ';';
        }
      );
    }
    function convertObjectToUnicode(obj) {
      for (let key in obj) {
        if (typeof obj[key] === 'object') {
          convertObjectToUnicode(obj[key]); // Nếu phần tử là một đối tượng, gọi đệ quy
        } else if (typeof obj[key] === 'string') {
          obj[key] = convertToUnicode(obj[key]); // Nếu phần tử là một chuỗi, chuyển đổi thành Unicode
        }
      }
      return obj;
    }

    const convertedData = convertObjectToUnicode(data);
    try {
      const url = `https://api.chaulanart.com/podcast/update-podcast-thumnail`;
      const opt = {
        method: 'put',
        body: JSON.stringify(convertedData),
        headers: { 'Content-Type': 'application/json' },
      };
      const res = await fetch(url, opt);
      const reponseData = await res.json();
      alert('Xác nhận thành công,', reponseData);
    } catch (error) {
      console.error('Lỗi khi thêm sản phẩm: ', error);
    }
  };

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
      onRefresh();
    }
  }, [isSubmitSuccessful, reset, onRefresh]);

  return (
    <div className="form-project">
      <form
        onSubmit={handleSubmit(handleSubmitProject)}
        noValidate>
        <div className="form-project-item">
          <div className="form-project-flex">
            <div className="form-project-flex-item">
              <label>Hình minh họa</label>
              <select onChange={(e) => handleUploadOptionChange(e)}>
                <option value="file">Tải lên từ máy</option>
                <option value="url">Nhập link từ YouTube</option>
              </select>
              {uploadOption === 'file' ? (
                <input
                  type="file"
                  onChange={(e) => handleFileUpload(e)}
                  accept="image/*,video/*"
                />
              ) : (
                <input
                  type="text"
                  placeholder="Nhập mã nhúng từ YouTube"
                  onChange={handleInputChange}
                />
              )}
              {errors.thumnail && (
                <p className="error">{errors.thumnail.message}</p>
              )}
            </div>
            <div className="form-project-flex-item">
              {/* Hiển thị hình ảnh đã tải lên */}
              {isLoaded ? (
                isImageUploaded && isImageUploaded ? (
                  <div className="form-project-flex-img">
                    {checkURL(uploadedImageUrl) ? (
                      <img
                        src={uploadedImageUrl}
                        title="Uploaded Image"
                        alt="Uploaded"
                      />
                    ) : (
                      <iframe
                        src={uploadedImageUrl}
                        title="Uploaded Video"
                        alt="Uploaded"
                      />
                    )}
                    <p onClick={handleDeleteImage}>Xóa</p>
                  </div>
                ) : (
                  <p className="project-loading">Loading ...</p>
                )
              ) : (
                <div className="form-project-flex-img">
                  {checkURL(podcastThumnail.thumnail) ? (
                    <img
                      src={podcastThumnail.thumnail}
                      title="Uploaded Image"
                      alt="Uploaded"
                    />
                  ) : isValidYoutubeEmbedUrl(podcastThumnail.thumnail) ? (
                    <iframe
                      src={podcastThumnail.thumnail}
                      title="Uploaded Video"
                      alt="Uploaded"
                    />
                  ) : (
                    <iframe
                      src={podcastThumnail.thumnail}
                      title="Uploaded Video"
                      alt="Uploaded"
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="form-project-item">
          <label>Mô tả ngắn</label>
          <textarea
            type="text"
            placeholder="Mô tả ngắn"
            rows="7"
            cols="80"
            {...register('description')}
          />
          {errors.description && (
            <p className="error">{errors.description.message}</p>
          )}
        </div>
        <div className="form-project-item">
          <button>Xác nhận</button>
        </div>
      </form>
      <DevTool control={control} />
    </div>
  );
};

export default FormEditPodcastThumnail;
