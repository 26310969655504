import React from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import Home from './Components/Home/Home';
import './App.css';
import Header from './Components/Header/Header';
import Footer from './Components/Footer/Footer';
import WorkDetail from './Components/WorkDetail/WorkDetail';
import Expertise from './Components/Expertise/Expertise';
import Courses from './Components/Courses/Courses';
import CourseDetail from './Components/CourseDetail.js/CourseDetail';
import Podcast from './Components/Podcast/Podcast';
import Blog from './Components/Blog/Blog';
import BlogThoughts from './Components/BlogChild/BlogThoughts';
import BlogDetail from './Components/BlogDetail/BlogDetail';
import About from './Components/About/About';
import DPICenter from './Components/CourseDetail.js/DPICenter';
import ScrollToTop from './Components/ScrollToTop/ScrollToTop';
import BlogInsight from './Components/BlogChild/BlogInsight';
import Loading from './Components/Loading/Loading';
import { HelmetProvider } from 'react-helmet-async';

const App = () => {
  const [loader, setLoader] = React.useState(true);
  const localhost = useLocation();

  React.useEffect(() => {
    setTimeout(() => {
      setLoader(false);
    }, 2500);
  }, [localhost]);

  return (
    <HelmetProvider>
      {loader ? (
        <Loading />
      ) : (
        <div className="wrapper">
          <Header></Header>
          <ScrollToTop />
          <main className="main">
            <Routes>
              <Route
                path="/"
                exact
                element={<Home />}
              />
              <Route
                path="/work"
                exact
                element={<Home />}
              />
              <Route
                path="/work/:slug"
                exact
                element={<WorkDetail />}
              />
              <Route
                path="/service"
                exact
                element={<Expertise />}
              />
              <Route
                path="/course"
                exact
                element={<Courses />}
              />
              <Route
                path="/podcast"
                exact
                element={<Podcast />}
              />
              <Route
                path="/blog"
                exact
                element={<Blog />}
              />
              <Route
                path="/about"
                exact
                element={<About />}
              />
              <Route
                path="/blog/insight/:id"
                exact
                element={<BlogInsight />}
              />
              <Route
                path="/blog/thoughts/:id"
                exact
                element={<BlogThoughts />}
              />
              <Route
                path="/blog/thoughts-detail/:id"
                exact
                element={<BlogDetail color="#ff7db0" />}
              />
              <Route
                path="/blog/insight-detail/:id"
                exact
                element={<BlogDetail color="#4285f4" />}
              />
              <Route
                path="/course/dpi-center/:id"
                exact
                element={<DPICenter />}
              />
              <Route
                path="/course/cbs-art-space/:id"
                exact
                element={<CourseDetail />}
              />
            </Routes>
          </main>
          <Footer></Footer>
        </div>
      )}
    </HelmetProvider>
  );
};

export default App;
