import React, { useEffect, useState } from 'react';

const AboutStory = () => {
  const [aboutStory, setAboutStory] = useState({});

  useEffect(() => {
    const fetchAboutStory = async () => {
      const res = await fetch(`https://api.chaulanart.com/about/about-story`);
      const data = await res.json();
      setAboutStory(data);
    };
    fetchAboutStory();
  }, []);

  const checkURL = (url) => {
    return /\.(jpeg|jpg|gif|png)$/i.test(url);
  };
  const isValidYoutubeEmbedUrl = (url) => {
    const pattern = /^https:\/\/www\.youtube\.com\/embed\/.+/i;
    return pattern.test(url);
  };

  return (
    <section className="about-story">
      <div className="about-story-top">
        <h4
          className="about-story-heading"
          dangerouslySetInnerHTML={{ __html: aboutStory.heading }}
        />
      </div>
      <div className="about-story-wrapper">
        <div className="about-story-image">
          <div className="about-story-image-list">
            {checkURL(aboutStory.image_main) ? (
              <img
                src={aboutStory.image_main}
                title="Uploaded Image"
                alt="Uploaded"
                className="about-story-image-main"
              />
            ) : isValidYoutubeEmbedUrl(aboutStory.image_main) ? (
              <iframe
                src={aboutStory.image_main}
                title="Uploaded Video"
                alt="Uploaded"
                className="about-story-image-main"
              />
            ) : (
              <iframe
                src={aboutStory.image_main}
                title="Uploaded Video"
                alt="Uploaded"
                className="about-story-image-main"
              />
            )}
            {checkURL(aboutStory.image_item_1) ? (
              <img
                src={aboutStory.image_item_1}
                title="Uploaded Image"
                alt="Uploaded"
                className="about-story-image-item"
              />
            ) : isValidYoutubeEmbedUrl(aboutStory.image_item_1) ? (
              <iframe
                src={aboutStory.image_item_1}
                title="Uploaded Video"
                alt="Uploaded"
                className="about-story-image-item"
              />
            ) : (
              <iframe
                src={aboutStory.image_item_1}
                title="Uploaded Video"
                alt="Uploaded"
                className="about-story-image-item"
              />
            )}
            {checkURL(aboutStory.image_item_2) ? (
              <img
                src={aboutStory.image_item_2}
                title="Uploaded Image"
                alt="Uploaded"
                className="about-story-image-item"
              />
            ) : isValidYoutubeEmbedUrl(aboutStory.image_item_2) ? (
              <iframe
                src={aboutStory.image_item_2}
                title="Uploaded Video"
                alt="Uploaded"
                className="about-story-image-item"
              />
            ) : (
              <iframe
                src={aboutStory.image_item_2}
                title="Uploaded Video"
                alt="Uploaded"
                className="about-story-image-item"
              />
            )}
            {checkURL(aboutStory.image_item_3) ? (
              <img
                src={aboutStory.image_item_3}
                title="Uploaded Image"
                alt="Uploaded"
                className="about-story-image-item"
              />
            ) : isValidYoutubeEmbedUrl(aboutStory.image_item_3) ? (
              <iframe
                src={aboutStory.image_item_3}
                title="Uploaded Video"
                alt="Uploaded"
                className="about-story-image-item"
              />
            ) : (
              <iframe
                src={aboutStory.image_item_3}
                title="Uploaded Video"
                alt="Uploaded"
                className="about-story-image-item"
              />
            )}
            {checkURL(aboutStory.image_item_4) ? (
              <img
                src={aboutStory.image_item_4}
                title="Uploaded Image"
                alt="Uploaded"
                className="about-story-image-item"
              />
            ) : isValidYoutubeEmbedUrl(aboutStory.image_item_4) ? (
              <iframe
                src={aboutStory.image_item_4}
                title="Uploaded Video"
                alt="Uploaded"
                className="about-story-image-item"
              />
            ) : (
              <iframe
                src={aboutStory.image_item_4}
                title="Uploaded Video"
                alt="Uploaded"
                className="about-story-image-item"
              />
            )}
          </div>
        </div>
        <div className="about-story-content">
          <p
            className="about-story-desc"
            dangerouslySetInnerHTML={{ __html: aboutStory.description }}
          />
        </div>
      </div>
    </section>
  );
};

export default AboutStory;
