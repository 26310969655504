import React from 'react';
import { useForm } from 'react-hook-form';
import { DevTool } from '@hookform/devtools';
import { useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const schema = yup.object({
  name: yup.string().required('Không được bỏ trống'),
  page: yup.string().required('Không được bỏ trống'),
});

const FormHeaderMenu = ({ menu, onRefresh }) => {
  const form = useForm({
    defaultValues: {
      name: menu.name,
      page: menu.page,
    },
    resolver: yupResolver(schema),
  });
  const { register, handleSubmit, reset, formState, control } = form;
  const { errors, isSubmitSuccessful } = formState;

  const handleSubmitProject = async (data) => {
    try {
      const url = `https://api.chaulanart.com/header/update-header-menu/${menu.menu_id}`;
      const opt = {
        method: 'put',
        body: JSON.stringify(data),
        headers: { 'Content-Type': 'application/json' },
      };
      const res = await fetch(url, opt);
      const reponseData = await res.json();
      alert('Xác nhận thành công,', reponseData);
    } catch (error) {
      console.error('Lỗi khi cập nhật sản phẩm: ', error);
    }
  };

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
      onRefresh();
    }
  }, [isSubmitSuccessful, reset, onRefresh]);

  return (
    <div className="form-project">
      <form
        onSubmit={handleSubmit(handleSubmitProject)}
        noValidate>
        <div className="form-project-item">
          <label>Tên menu</label>
          <input
            type="text"
            {...register('name')}
          />
          {errors.name && <p className="error">{errors.name.message}</p>}
        </div>
        <div className="form-project-item">
          <label>Page liên kết</label>
          <select {...register('page')}>
            <option value="work">Work</option>
            <option value="expertise">Expertise</option>
            <option value="courses">Courses</option>
            <option value="podcast">Podcast</option>
            <option value="blog">Blog</option>
            <option value="about">About</option>
          </select>
          {errors.status && <p className="error">{errors.status.message}</p>}
        </div>
        <div className="form-project-item">
          <button>Xác nhận</button>
        </div>
      </form>
      <DevTool control={control} />
    </div>
  );
};

export default FormHeaderMenu;
