import React from 'react';
import { useForm } from 'react-hook-form';
import { DevTool } from '@hookform/devtools';
import { useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useSelector } from 'react-redux';
const schema = yup.object().shape({
  oldPassword: yup.string().trim().required('Không được bỏ trống'),
  newPassword: yup.string().trim().required('Không được bỏ trống'),
  confirmpassword: yup
    .string()
    .required('Không được bỏ trống')
    .oneOf([yup.ref('newPassword'), null], 'Mật khẩu không trùng khớp'),
  email: yup
    .string()
    .email('email có định dạng không hợp lệ')
    .trim()
    .required('Không được bỏ trống'),
});

const UpdatePass = () => {
  const user = useSelector((state) => state.auth.user);
  const form = useForm({
    defaultValues: {
      email: user?.email,
      oldPassword: '',
      newPassword: '',
      confirmpassword: '',
    },
    resolver: yupResolver(schema),
  });
  const { register, handleSubmit, reset, formState, control } = form;
  const { errors, isSubmitSuccessful } = formState;
  const handleChangeRessPassword = async (data) => {
    try {
      const response = await fetch(
        `https://api.chaulanart.com/users/change-password`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        }
      );
      const result = await response.json();
      console.log(result);
      alert('Đổi mật khẩu thành công');
    } catch (error) {
      console.error('Lỗi khi gửi form: ', error);
    }
  };
  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
  }, [isSubmitSuccessful, reset]);

  return (
    <div className="resgiter">
      <h1>Profile</h1>
      <div className="resgiter-user">
        <h2>Đổi mật khẩu</h2>
        <form
          onSubmit={handleSubmit(handleChangeRessPassword)}
          noValidate>
          <div className="resgiter-user-item">
            <label>Mật khẩu củ</label>
            <input
              type="password"
              placeholder="Mật khẩu củ"
              {...register('oldPassword')}
            />
            <p className="err">{errors.oldPassword?.message}</p>
          </div>
          <div className="resgiter-user-item">
            <label>Mật khẩu mới</label>
            <input
              type="password"
              placeholder="Mật khẩu mới"
              {...register('newPassword')}
            />
            <p className="err">{errors.newPassword?.message}</p>
          </div>
          <div className="resgiter-user-item">
            <label>Nhặp lại mật khẩu</label>
            <input
              type="password"
              placeholder="nhặp lại mật khẩu"
              {...register('confirmpassword')}
            />
            <p className="err">{errors.confirmpassword?.message}</p>
          </div>
          <div className="resgiter-user-item">
            <button>Cập nhật</button>
          </div>
        </form>
        <DevTool control={control} />
      </div>
    </div>
  );
};

export default UpdatePass;
