import React, { useEffect, useState } from 'react';
import './ProjectList.css';
import FormProject from '../FormProject/FormProject';
import { Link } from 'react-router-dom';
import ReactPaginate from 'react-paginate';

const ProjectList = () => {
  const [showForm, setShowForm] = useState(false);
  const [projects, setProjects] = useState([]);
  const [refreshKey, setRefreshKey] = useState(0);

  const handleAddProjectClick = () => {
    setShowForm(true);
  };
  const handleCloseProjectClick = () => {
    setShowForm(false);
  };

  useEffect(() => {
    const fetchProjects = async () => {
      const res = await fetch(
        'https://api.chaulanart.com/projects/get-projects'
      );
      const data = await res.json();
      setProjects(data);
    };

    fetchProjects();
    setShowForm(false);
  }, [refreshKey]);

  const checkURL = (url) => {
    return /\.(jpeg|jpg|gif|png)$/i.test(url);
  };
  const isValidYoutubeEmbedUrl = (url) => {
    const pattern = /^https:\/\/www\.youtube\.com\/embed\/.+/i;
    return pattern.test(url);
  };

  const deleteProject = (id) => {
    if (window.confirm('Bạn muốn xóa project này không?')) {
      fetch(`https://api.chaulanart.com/projects/delete-project/${id}`, {
        method: 'DELETE',
      })
        .then((res) => res.json())
        .then(() => {
          // Fetch the list of projects again after a project has been deleted
          const fetchProjects = async () => {
            const res = await fetch(
              'https://api.chaulanart.com/projects/get-projects'
            );
            const data = await res.json();
            setProjects(data);
          };

          fetchProjects();
        })
        .catch((error) => console.error('Lỗi xóa:', error));
    }
  };

  function LayoutInPage({ itemInPage }) {
    return (
      <tbody className="project-list">
        {itemInPage &&
          itemInPage.slice(0, 10).map((project) => (
            <tr>
              <td>
                <div dangerouslySetInnerHTML={{ __html: project.heading }} />
              </td>
              <td>
                {checkURL(project.image_main) ? (
                  <img
                    src={project.image_main}
                    title="Uploaded Image"
                    alt="Uploaded"
                    className="project-image"
                  />
                ) : isValidYoutubeEmbedUrl(project.image_main) ? (
                  <iframe
                    src={project.image_main}
                    title="Uploaded Video"
                    alt="Uploaded"
                    className="project-image"
                  />
                ) : (
                  <iframe
                    src={project.image_main}
                    title="Uploaded Video"
                    alt="Uploaded"
                    className="project-image"
                  />
                )}
              </td>
              <td>
                {project.status === 1
                  ? 'Hoàn thành'
                  : project.status === 2
                  ? 'Ẩn'
                  : 'Coming soon'}
              </td>
              <td>
                <Link
                  className="project-actions"
                  to={`/admin/projects/${project.project_id}`}>
                  Chi tiết
                </Link>
              </td>
              <td>
                <p
                  className="project-actions"
                  onClick={() => deleteProject(project.project_id)}>
                  Xóa
                </p>
              </td>
            </tr>
          ))}
      </tbody>
    );
  }
  const PageItem = ({ pageSize }) => {
    const [currentPage, setCurrentPage] = useState(0);
    const pageCount = Math.ceil(projects.length / pageSize);

    const handlePageClick = (selectedItem) => {
      setCurrentPage(selectedItem.selected);
      window.scrollTo(0, 0);
    };

    return (
      <>
        <LayoutInPage
          itemInPage={projects.slice(
            currentPage * pageSize,
            (currentPage + 1) * pageSize
          )}
        />
        <ReactPaginate
          previousLabel={currentPage === 0 ? '' : 'Prev'}
          nextLabel={currentPage === pageCount - 1 ? '' : 'Next'}
          onPageChange={handlePageClick}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          containerClassName="project-pagination"
          activeClassName="active"
          forcePage={currentPage}
        />
      </>
    );
  };

  return (
    <div class="recent-orders">
      <h1>Dự án</h1>
      <div className="project-form">
        {showForm && (
          <>
            <FormProject onRefresh={() => setRefreshKey(refreshKey + 1)} />
            <div
              className="project-detail-add"
              onClick={handleCloseProjectClick}>
              <span>Đóng</span>
            </div>
          </>
        )}
      </div>
      <h2>Các dự án mới</h2>
      <table>
        <thead>
          <tr>
            <th>Tên dự án</th>
            <th>Hình minh họa</th>
            <th>Trạng thái</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <PageItem pageSize={6} />
      </table>
      <div
        className="project-add"
        onClick={handleAddProjectClick}>
        <span>Thêm dự án mới</span>
      </div>
    </div>
  );
};

export default ProjectList;
