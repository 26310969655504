import React, { useEffect, useState } from 'react';

const AboutEducation = () => {
  const [aboutEducation, setAboutEducation] = useState([]);
  const [aboutEducationHeading, setAboutEducationHeading] = useState({});

  useEffect(() => {
    const fetchAboutEducaition = async () => {
      const res = await fetch(
        `https://api.chaulanart.com/about/about-education`
      );
      const data = await res.json();
      setAboutEducation(data);
    };
    const fetchAboutEducationHeading = async () => {
      const res = await fetch(
        `https://api.chaulanart.com/about/about-education-heading`
      );
      const data = await res.json();
      setAboutEducationHeading(data);
    };

    fetchAboutEducaition();
    fetchAboutEducationHeading();
  }, []);
  return (
    <section className="about-education">
      <h3
        className="about-education-heading"
        dangerouslySetInnerHTML={{ __html: aboutEducationHeading.heading }}
      />
      <div className="about-education-list">
        {aboutEducation &&
          aboutEducation.length > 0 &&
          aboutEducation.map((item) => (
            <div className="about-education-item">
              <h4
                className="about-education-title"
                dangerouslySetInnerHTML={{ __html: item.heading }}
              />
              <p
                className="about-education-position"
                style={{ whiteSpace: 'pre-line' }}
                dangerouslySetInnerHTML={{ __html: item.description }}
              />
            </div>
          ))}
      </div>
    </section>
  );
};

export default AboutEducation;
