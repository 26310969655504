import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Header.css';

const Header = () => {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [headerLogo, setHeaderLogo] = useState({});
  const [headerMenu, setHeaderMenu] = useState([]);
  const headerRef = useRef();

  useEffect(() => {
    const fetchHeaderLogo = async () => {
      const res = await fetch(`https://api.chaulanart.com/header/header`);
      const data = await res.json();
      setHeaderLogo(data);
    };
    const fetchHeaderMenu = async () => {
      const res = await fetch(`https://api.chaulanart.com/header/header-menu`);
      const data = await res.json();
      setHeaderMenu(data);
    };
    fetchHeaderLogo();
    fetchHeaderMenu();
  }, []);

  const isActive = (pathname) => {
    if (pathname === '/work') {
      // Kiểm tra nếu đường dẫn chính xác là '/work' hoặc đường dẫn bắt đầu bằng '/work/'
      return (
        location.pathname === '/work' ||
        location.pathname.startsWith('/work/') ||
        location.pathname === '/'
      );
    }
    // Cho phép kích hoạt đường dẫn chính xác, bao gồm cả đường dẫn con
    return location.pathname.startsWith(pathname);
  };

  const toggleMenu = () => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
  };

  const handleClickOutside = (event) => {
    if (headerRef.current && !headerRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    window.addEventListener('click', handleClickOutside);
    return () => window.removeEventListener('click', handleClickOutside);
  }, []);

  useEffect(() => {
    setIsOpen(false);
  }, [location]);
  return (
    <header
      className="header is-fixed"
      ref={headerRef}>
      <div className="container-fixed">
        <nav className="header-wrapper">
          <div className="header-list">
            <Link
              to="/work"
              className="header-logo">
              <div className="header-logo-image">
                <img
                  src={headerLogo.logo}
                  alt=""
                />
              </div>
            </Link>
            <ul className={`header-menu ${isOpen ? 'is-active' : ''}`}>
              {headerMenu.map((menu, index) => (
                <li
                  className="header-menu-item"
                  key={index}>
                  <Link
                    to={`/${menu.page.toLowerCase()}`}
                    className={`header-menu-link ${
                      isActive(`/${menu.page.toLowerCase()}`) ? 'active' : ''
                    }`}>
                    {menu.name}
                  </Link>
                </li>
              ))}
            </ul>
            <div
              className="header-menu-toggle"
              onClick={toggleMenu}>
              <div
                className={`header-toggle ${isOpen ? 'is-active' : ''}`}></div>
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Header;
