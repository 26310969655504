import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import FormCourseAddCate from './FormCourseAddCate';
import FormCourseEditCate from './FormCourseEditCate';
import FormEditCourseThumnail from './FormEditCourseThumnail';

const CoursePage = () => {
  const [courseCate, setCourseCate] = useState([]);
  const [thumnailCoursePage, setThumnailCoursePage] = useState({});
  const [courseCateItem, setCourseCateItem] = useState('');
  const [refreshKey, setRefreshKey] = useState(0);
  const [showFormAddCourseCate, setShowFormAddCourseCate] = useState(false);
  const [showFormEditCourseCate, setShowFormEditCourseCate] = useState(false);
  const [showFormEditCourseThumbnail, setShowFormEditCourseThumbnail] =
    useState(false);

  const handleOpenFormCourseCate = () => {
    setShowFormAddCourseCate(true);
  };
  const handleAddFormCourseCate = (item) => {
    setShowFormEditCourseCate(true);
    setShowFormAddCourseCate(false);
    setCourseCateItem(item);
  };

  const handleCloseFormCourseCate = () => {
    setShowFormAddCourseCate(false);
  };
  const handleClodeFormEditCourseCate = () => {
    setShowFormEditCourseCate(false);
  };

  const handleOpenFormEditCourseThumbnail = () => {
    setShowFormEditCourseThumbnail(true);
  };
  const handleClodeFormEditCourseThumbnail = () => {
    setShowFormEditCourseThumbnail(false);
  };

  useEffect(() => {
    const fetchCourseCate = async () => {
      const res = await fetch(`https://api.chaulanart.com/course/course-cate`);
      const data = await res.json();
      setCourseCate(data);
    };
    const fetchCourseThumnail = async () => {
      const res = await fetch(
        `https://api.chaulanart.com/course/course-thumnail`
      );
      const data = await res.json();
      setThumnailCoursePage(data);
    };
    fetchCourseThumnail();
    fetchCourseCate();
    setShowFormEditCourseThumbnail(false);
    setShowFormEditCourseCate(false);
    setShowFormAddCourseCate(false);
  }, [refreshKey]);

  const deleteTitle = (id) => {
    if (window.confirm('Bạn muốn xóa không?')) {
      fetch(`https://api.chaulanart.com/course/delete-course-cate/${id}`, {
        method: 'DELETE',
      })
        .then((res) => res.json())
        .then(() => {
          // Fetch the list of projects again after a project has been deleted
          const fetchProjects = async () => {
            const res = await fetch(
              `https://api.chaulanart.com/course/course-cate`
            );
            const data = await res.json();
            setCourseCate(data);
          };

          fetchProjects();
        })
        .catch((error) => console.error('Lỗi xóa:', error));
    }
  };

  const checkURL = (url) => {
    return /\.(jpeg|jpg|gif|png)$/i.test(url);
  };
  const isValidYoutubeEmbedUrl = (url) => {
    const pattern = /^https:\/\/www\.youtube\.com\/embed\/.+/i;
    return pattern.test(url);
  };

  return (
    <div className="workpage">
      <h1> Loại khóa học</h1>
      {showFormAddCourseCate && (
        <>
          <FormCourseAddCate onRefresh={() => setRefreshKey(refreshKey + 1)} />
          <div
            className="workpage-add"
            onClick={handleCloseFormCourseCate}>
            <span>Đóng</span>
          </div>
        </>
      )}
      {showFormEditCourseCate && (
        <>
          <FormCourseEditCate
            onRefresh={() => setRefreshKey(refreshKey + 1)}
            courseCate={courseCateItem}
          />
          <div
            className="workpage-add"
            onClick={handleClodeFormEditCourseCate}>
            <span>Đóng</span>
          </div>
        </>
      )}
      <div className="workpage-tags">
        {courseCate && courseCate.length > 0 ? (
          courseCate.map((item) => (
            <div className="workpage-tags-item">
              <Link to={`/admin/course-cate/${item.course_cate_id}`}>
                <span>
                  <div dangerouslySetInnerHTML={{ __html: item.heading }} />
                </span>
              </Link>
              <div
                className="workpage-tags-item-delete"
                onClick={() => deleteTitle(item.course_cate_id)}>
                <i className="fa-solid fa-xmark"></i>
              </div>
              <div
                className="workpage-tags-item-edit"
                onClick={() => handleAddFormCourseCate(item)}>
                <i className="fa-solid fa-pen"></i>
              </div>
            </div>
          ))
        ) : (
          <div className="workpage-tags-item">
            <span>Chưa có khóa học</span>
          </div>
        )}
      </div>
      <div
        className="project-detail-add"
        onClick={handleOpenFormCourseCate}>
        <span>Thêm loại khóa học</span>
      </div>
      <h2>Thumbnail CoursePage</h2>
      {showFormEditCourseThumbnail ? (
        <>
          <FormEditCourseThumnail
            onRefresh={() => setRefreshKey(refreshKey + 1)}
            courseThumnail={thumnailCoursePage}
          />
          <div
            className="workpage-add"
            onClick={handleClodeFormEditCourseThumbnail}>
            <span>Đóng</span>
          </div>
        </>
      ) : null}
      <div className="project-image-detail">
        <div className="project-image-detail-item">
          {checkURL(thumnailCoursePage.thumnail) ? (
            <img
              src={thumnailCoursePage.thumnail}
              title="Uploaded Image"
              alt="Uploaded"
              className="project-image"
            />
          ) : isValidYoutubeEmbedUrl(thumnailCoursePage.thumnail) ? (
            <iframe
              src={thumnailCoursePage.thumnail}
              title="Uploaded Video"
              alt="Uploaded"
              className="project-image"
            />
          ) : (
            <iframe
              src={thumnailCoursePage.thumnail}
              title="Uploaded Video"
              alt="Uploaded"
              className="project-image"
            />
          )}
        </div>
      </div>
      <div
        className="project-detail-add"
        onClick={handleOpenFormEditCourseThumbnail}>
        <span>Sửa thông tin</span>
      </div>
    </div>
  );
};

export default CoursePage;
