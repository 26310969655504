import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { DevTool } from '@hookform/devtools';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const schema = yup.object({
  name_visual: yup
    .string()
    .min(2, 'Tiêu đề có tối thiểu 2 ký tự')
    .required('Không được bỏ trống'),
});

const FormEditProjectVisual = ({
  project_id = null,
  onRefresh,
  projectVisual,
}) => {
  const form = useForm({
    defaultValues: {
      name_visual: projectVisual.name_visual,
      project_id: project_id,
    },
    resolver: yupResolver(schema),
  });
  const { register, handleSubmit, reset, formState, control } = form;
  const { errors, isSubmitSuccessful } = formState;

  const handleSubmitProjectImage = async (data) => {
    if (!project_id) {
      console.error('No project_id provided');
      return;
    }
    try {
      const url = `https://api.chaulanart.com/projects/update-project-visual/${projectVisual.project_visual_id}`;
      const opt = {
        method: 'put',
        body: JSON.stringify(data),
        headers: { 'Content-Type': 'application/json' },
      };
      const res = await fetch(url, opt);
      const reponseData = await res.json();
      alert('Xác nhận thành công,', reponseData);
    } catch (error) {
      console.error('Lỗi ', error);
    }
  };

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
      onRefresh();
    }
  }, [isSubmitSuccessful, reset, onRefresh]);

  return (
    <div className="form-project-visual">
      <form onSubmit={handleSubmit(handleSubmitProjectImage)}>
        <div className="form-project-item">
          <label>Tên tags</label>
          <input
            type="text"
            placeholder="Layout"
            {...register('name_visual')}
          />
          {errors.name_visual && (
            <p className="error">{errors.name_visual.message}</p>
          )}
        </div>
        <div className="form-project-item">
          <button>Xác nhận</button>
        </div>
      </form>
      <DevTool control={control} />
    </div>
  );
};

export default FormEditProjectVisual;
