import React, { useEffect, useState } from 'react';
import './WorkPage.css';
import FormWork from '../FormWork/FormWork';
import FormWorkTitle from '../FormWork/FormWorkTitle';
import FormEditWorkTitle from '../FormWork/FormEditWorkTitle';
import FormEditWorkThumnail from '../FormWork/FormEditWorkThumnail';

const WorkPage = () => {
  const [workHeading, setWorkHeading] = useState({});
  const [workHeadingItem, setWorkHeadingItem] = useState([]);
  const [showFormEditWork, setShowFormEditWork] = useState(false);
  const [showFormAddWorkItem, setShowFormAddWorkItem] = useState(false);
  const [showFormEditWorkItem, setShowFormEditWorkItem] = useState(false);
  const [showFormEditWorkThumbnail, setShowFormEditWorkThumbnail] =
    useState(false);
  const [tileItem, setTitleItem] = useState('');
  const [refreshKey, setRefreshKey] = useState(0);
  const [thumnailWorkPage, setThumnailWorkPage] = useState({});

  const handleAddFormEditWork = () => {
    setShowFormEditWork(true);
  };
  const handleAddFormWorkItem = () => {
    setShowFormAddWorkItem(true);
    setShowFormEditWorkItem(false);
  };
  const handleAddFormEditWorkItem = (title) => {
    setShowFormAddWorkItem(false);
    setShowFormEditWorkItem(true);
    setTitleItem(title);
  };
  const handleClodeFormEditWorkItem = () => {
    setShowFormEditWorkItem(false);
  };
  const handleClodeFormWorkItem = () => {
    setShowFormAddWorkItem(false);
  };
  const handleClodeFormEditWork = () => {
    setShowFormEditWork(false);
  };
  const handleOpenFormEditWorkThumbnail = () => {
    setShowFormEditWorkThumbnail(true);
  };
  const handleClodeFormEditWorkThumbnail = () => {
    setShowFormEditWorkThumbnail(false);
  };
  useEffect(() => {
    const fetchWorkHeading = async () => {
      const res = await fetch(`https://api.chaulanart.com/work-page/get-work`);
      const data = await res.json();
      setWorkHeading(data);
    };
    const fetchWorkHeadingItem = async () => {
      const res = await fetch(
        `https://api.chaulanart.com/work-page/get-work-item`
      );
      const data = await res.json();
      setWorkHeadingItem(data);
    };
    const fetchThumnailWorkPage = async () => {
      const res = await fetch(
        `https://api.chaulanart.com/work-page/get-work-thumnail`
      );
      const data = await res.json();
      setThumnailWorkPage(data);
    };
    fetchWorkHeadingItem();
    fetchWorkHeading();
    fetchThumnailWorkPage();
    setShowFormEditWork(false);
    setShowFormAddWorkItem(false);
    setShowFormEditWorkItem(false);
    setShowFormEditWorkThumbnail(false);
  }, [refreshKey]);

  const deleteTitle = (id_tag) => {
    if (window.confirm('Bạn muốn xóa tag này không?')) {
      fetch(
        `https://api.chaulanart.com/work-page/delete-work-heading-item/${id_tag}`,
        {
          method: 'DELETE',
        }
      )
        .then((res) => res.json())
        .then(() => {
          // Fetch the list of projects again after a project has been deleted
          const fetchProjects = async () => {
            const res = await fetch(
              `https://api.chaulanart.com/work-page/get-work-item`
            );
            const data = await res.json();
            setWorkHeadingItem(data);
          };

          fetchProjects();
        })
        .catch((error) => console.error('Lỗi xóa:', error));
    }
  };

  const checkURL = (url) => {
    return /\.(jpeg|jpg|gif|png)$/i.test(url);
  };
  const isValidYoutubeEmbedUrl = (url) => {
    const pattern = /^https:\/\/www\.youtube\.com\/embed\/.+/i;
    return pattern.test(url);
  };

  return (
    <div className="workpage">
      <h1>Chi tiết trang work</h1>
      {showFormEditWork && (
        <>
          <FormWork
            workHeading={workHeading}
            onRefresh={() => setRefreshKey(refreshKey + 1)}
          />
          <div
            className="workpage-add"
            onClick={handleClodeFormEditWork}>
            <span>Đóng</span>
          </div>
        </>
      )}
      <div className="workpage-info">
        <div className="workpage-info-item">
          <label>Tiêu đề:</label>
          <p>
            <div dangerouslySetInnerHTML={{ __html: workHeading.heading }} />
          </p>
        </div>
      </div>
      <div
        className="workpage-add"
        onClick={handleAddFormEditWork}>
        <span>Sửa thông tin</span>
      </div>
      <h2>Các tiêu đề con</h2>
      {showFormAddWorkItem && (
        <>
          <FormWorkTitle onRefresh={() => setRefreshKey(refreshKey + 1)} />
          <div
            className="workpage-add"
            onClick={handleClodeFormWorkItem}>
            <span>Đóng</span>
          </div>
        </>
      )}
      {showFormEditWorkItem && (
        <>
          <FormEditWorkTitle
            onRefresh={() => setRefreshKey(refreshKey + 1)}
            workTitle={tileItem}
          />
          <div
            className="workpage-add"
            onClick={handleClodeFormEditWorkItem}>
            <span>Đóng</span>
          </div>
        </>
      )}
      <div className="workpage-tags">
        {workHeadingItem && workHeadingItem.length > 0 ? (
          workHeadingItem.map((item) => (
            <div className="workpage-tags-item">
              <span>
                <div dangerouslySetInnerHTML={{ __html: item.title }} />
              </span>
              <div
                className="workpage-tags-item-delete"
                onClick={() => deleteTitle(item.whi_id)}>
                <i className="fa-solid fa-xmark"></i>
              </div>
              <div
                className="workpage-tags-item-edit"
                onClick={() => handleAddFormEditWorkItem(item)}>
                <i className="fa-solid fa-pen"></i>
              </div>
            </div>
          ))
        ) : (
          <div className="workpage-tags-item">
            <span>Chưa có tiêu đề</span>
          </div>
        )}
      </div>
      <div
        className="project-detail-add"
        onClick={handleAddFormWorkItem}>
        <span>Thêm tiêu đề</span>
      </div>
      <h2>Thumbnail WorkPage</h2>
      {showFormEditWorkThumbnail ? (
        <>
          <FormEditWorkThumnail
            onRefresh={() => setRefreshKey(refreshKey + 1)}
            workThumnail={thumnailWorkPage}
          />
          <div
            className="workpage-add"
            onClick={handleClodeFormEditWorkThumbnail}>
            <span>Đóng</span>
          </div>
        </>
      ) : null}
      <div className="project-image-detail">
        <div className="project-image-detail-item">
          {checkURL(thumnailWorkPage.thumnail) ? (
            <img
              src={thumnailWorkPage.thumnail}
              title="Uploaded Image"
              alt="Uploaded"
              className="project-image"
            />
          ) : isValidYoutubeEmbedUrl(thumnailWorkPage.thumnail) ? (
            <iframe
              src={thumnailWorkPage.thumnail}
              title="Uploaded Video"
              alt="Uploaded"
              className="project-image"
            />
          ) : (
            <iframe
              src={thumnailWorkPage.thumnail}
              title="Uploaded Video"
              alt="Uploaded"
              className="project-image"
            />
          )}
        </div>
      </div>
      <div
        className="project-detail-add"
        onClick={handleOpenFormEditWorkThumbnail}>
        <span>Sửa thông tin</span>
      </div>
    </div>
  );
};

export default WorkPage;
