import React, { useEffect, useState } from 'react';
import './WorkDetail.css';
import MoreProject from '../MoreProject/MoreProject';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
const WorkDetail = () => {
  const [workTags, setWorkTags] = useState([]);
  const [workItem, setWorkItem] = useState({});
  let { slug } = useParams();
  const pageDescription = stripHtml(workItem.heading);
  const baseUrl = 'https://chaulanart.com';
  const pageUrl = `${baseUrl}/work/${slug}`;
  const imageUrl = workItem.image;

  function stripHtml(html) {
    // Temporary element for parsing HTML content
    var tempDivElement = document.createElement('div');
    tempDivElement.innerHTML = html;
    return tempDivElement.textContent || tempDivElement.innerText || '';
  }

  useEffect(() => {
    const fetchProjectData = async () => {
      try {
        const projectResponse = await fetch(
          `https://api.chaulanart.com/projects/get-project/${slug}`
        );
        const projectData = await projectResponse.json();
        const textTitle = stripHtml(projectData.heading);
        document.title = `${textTitle} - Chau Lan Art`;
        setWorkItem(projectData);
      } catch (error) {
        console.error('Failed to fetch project data:', error);
      }
    };

    const fetchProjectImages = async () => {
      try {
        const imagesResponse = await fetch(
          `https://api.chaulanart.com/projects/get-project-images/${slug}`
        );
        const imagesData = await imagesResponse.json();
        setWorkTags(imagesData);
      } catch (error) {
        console.error('Failed to fetch project images:', error);
      }
    };
    fetchProjectImages();
    fetchProjectData();
  }, [slug]);

  const checkURL = (url) => {
    return /\.(jpeg|jpg|gif|png)$/i.test(url);
  };
  const isValidYoutubeEmbedUrl = (url) => {
    const pattern = /^https:\/\/www\.youtube\.com\/embed\/.+/i;
    return pattern.test(url);
  };

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content={pageDescription}
        />
        <meta
          property="og:url"
          content={pageUrl}
        />
        <meta
          property="og:image"
          content={imageUrl}
        />
      </Helmet>
      <section className="work-detail">
        {workTags &&
          workTags.map((workTag, index) => (
            <>
              {workTag.image !== '' ? (
                <div className="work-detail-heading">
                  {checkURL(workTag.image) ? (
                    <img
                      src={workTag.image}
                      title="Uploaded Image"
                      alt="Uploaded"
                      className="work-detail-body-img"
                    />
                  ) : isValidYoutubeEmbedUrl(workTag.image) ? (
                    <iframe
                      src={workTag.image}
                      title="Uploaded Video"
                      alt="Uploaded"
                      className="work-detail-body-img"
                    />
                  ) : (
                    <iframe
                      src={workTag.image}
                      title="Uploaded Video"
                      alt="Uploaded"
                      className="work-detail-body-img"
                    />
                  )}
                </div>
              ) : null}
              <div className="container">
                {workTag.visualizer !== '' ||
                workTag.heading !== '' ||
                workTag.description !== '' ? (
                  <div className="work-detail-list">
                    <div
                      className="work-detail-author"
                      dangerouslySetInnerHTML={{
                        __html: workTag.visualizer,
                      }}
                    />
                    <div className="work-detail-content">
                      {workTag.heading !== '' ? (
                        <div
                          className="work-detail-title"
                          dangerouslySetInnerHTML={{ __html: workTag.heading }}
                        />
                      ) : (
                        ''
                      )}
                      <p
                        className="work-detail-desc"
                        dangerouslySetInnerHTML={{
                          __html: workTag.description,
                        }}></p>
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </div>
            </>
          ))}
        <MoreProject></MoreProject>
      </section>
    </>
  );
};

export default WorkDetail;
