import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import FormEditAboutExContent from './FormEditAboutExContent';

const AboutExDetail = () => {
  const [aboutExDetail, setAboutExDetail] = useState({});
  const [showForm, setShowForm] = useState(false);
  const [refreshKey, setRefreshKey] = useState(0);
  const handleRefresh = useCallback(
    () => setRefreshKey(refreshKey + 1),
    [refreshKey]
  );
  let { id } = useParams();

  const handleOpenAboutAddAward = () => {
    setShowForm(true);
  };
  const handleCloseAboutAddAward = () => {
    setShowForm(false);
  };

  useEffect(() => {
    const fetchAboutAward = async () => {
      const res = await fetch(
        `https://api.chaulanart.com/about/about-ex-content/${id}`
      );
      const data = await res.json();
      setAboutExDetail(data);
    };

    fetchAboutAward();
  }, [id, refreshKey]);

  return (
    <div className="project-detail">
      <h1>Chi tiết dự án</h1>
      {showForm && (
        <>
          <FormEditAboutExContent
            aboutContent={aboutExDetail}
            onRefresh={handleRefresh}
          />
          <div
            className="project-detail-add"
            onClick={handleCloseAboutAddAward}>
            <span>Đóng</span>
          </div>
        </>
      )}
      <div className="project-detail-info">
        <div className="project-detail-info-item">
          <label>Tên dự án</label>
          <div dangerouslySetInnerHTML={{ __html: aboutExDetail.heading }} />
        </div>
        <div className="project-detail-info-item">
          <label>Tiêu đề</label>
          <div dangerouslySetInnerHTML={{ __html: aboutExDetail.title }} />
        </div>
        <div className="project-detail-info-item">
          <label>Mô tả</label>
          <div
            dangerouslySetInnerHTML={{ __html: aboutExDetail.description }}
          />
        </div>
      </div>
      <div
        className="project-detail-add"
        onClick={handleOpenAboutAddAward}>
        <span>Sửa thông tin</span>
      </div>
    </div>
  );
};

export default AboutExDetail;
