const API_URL = 'https://api.chaulanart.com';

async function cloudinaryUpload(file) {
  try {
    const res = await fetch(API_URL + '/upload-image/cloudinary-upload', {
      method: 'POST',
      body: file,
    });
    const data = await res.json();
    return data;
  } catch (err) {
    return console.log(err);
  }
}

export default cloudinaryUpload;
