import React, { useEffect, useRef, useState } from 'react';

const AboutAward = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const contentRefs = useRef([]);
  const [aboutAward, setAboutAward] = useState([]);
  const [aboutAwardHeading, setAboutAwardHeading] = useState({});

  useEffect(() => {
    contentRefs.current.forEach((content, index) => {
      if (content) {
        content.style.height =
          activeIndex === index ? `${content.scrollHeight + 20}px` : '0px';
      }
    });
  }, [activeIndex]);

  const checkURL = (url) => {
    return /\.(jpeg|jpg|gif|png)$/i.test(url);
  };
  const isValidYoutubeEmbedUrl = (url) => {
    const pattern = /^https:\/\/www\.youtube\.com\/embed\/.+/i;
    return pattern.test(url);
  };

  useEffect(() => {
    const fetchAboutAward = async () => {
      const res = await fetch('https://api.chaulanart.com/about/about-awards');
      const data = await res.json();
      setAboutAward(data);
    };
    const fetchAboutAwardHeading = async () => {
      const res = await fetch(
        'https://api.chaulanart.com/about/about-award-heading'
      );
      const data = await res.json();
      setAboutAwardHeading(data);
    };
    fetchAboutAward();
    fetchAboutAwardHeading();
  }, []);

  // Event handlers
  const handleAccordionClick = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };
  return (
    <section className="about-awards">
      <h3
        className="about-awards-heading"
        dangerouslySetInnerHTML={{ __html: aboutAwardHeading.heading }}
      />
      <div className="about-awards-wrapper">
        <div className="about-awards-list">
          {aboutAward &&
            aboutAward.map((item, index) => (
              <div
                key={index}
                className={`about-awards-item ${index === 0 ? 'first' : ''} ${
                  activeIndex === index ? 'is-active' : ''
                }`}>
                <div
                  className="about-awards-main"
                  onClick={() => handleAccordionClick(index)}>
                  <h4
                    className="about-awards-title"
                    dangerouslySetInnerHTML={{ __html: item.heading }}
                  />
                  <span
                    className="about-awards-date"
                    dangerouslySetInnerHTML={{ __html: item.time }}
                  />
                </div>
                <div
                  ref={(el) => (contentRefs.current[index] = el)}
                  className={`about-awards-content ${
                    index === 0 ? 'first' : ''
                  } ${activeIndex === index ? 'is-active' : ''}`}>
                  {checkURL(item.image) ? (
                    <img
                      src={item.image}
                      title="Uploaded Image"
                      alt="Uploaded"
                      className="about-awards-img"
                    />
                  ) : isValidYoutubeEmbedUrl(item.image) ? (
                    <iframe
                      src={item.image}
                      title="Uploaded Video"
                      alt="Uploaded"
                      className="about-awards-img"
                    />
                  ) : (
                    <iframe
                      src={item.image}
                      title="Uploaded Video"
                      alt="Uploaded"
                      className="about-awards-img"
                    />
                  )}
                  <p
                    className="about-awards-text"
                    dangerouslySetInnerHTML={{ __html: item.description }}
                  />
                </div>
              </div>
            ))}
        </div>
      </div>
    </section>
  );
};

export default AboutAward;
