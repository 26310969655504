import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Course.css';

const Courses = () => {
  const [courseCateList, setCourseCateList] = useState([]);
  const [courseItem, setCourseItem] = useState({});
  const [courseThumnail, setCourseThumnail] = useState({});
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const res = await fetch(
          `https://api.chaulanart.com/course/course-cate`
        );
        const projects = await res.json();

        setCourseCateList(projects);

        // Fetch tags for all projects
        const tagsPromises = projects.map((project) =>
          fetch(
            `https://api.chaulanart.com/course/course-cate/${project.course_cate_id}`
          )
            .then((res) => res.json())
            .then((tagData) => ({
              projectId: project.course_cate_id,
              tags: tagData,
            }))
        );

        Promise.all(tagsPromises).then((results) => {
          const tagsByProject = results.reduce((acc, current) => {
            acc[current.projectId] = current.tags;
            return acc;
          }, {});
          setCourseItem(tagsByProject);
        });
      } catch (error) {
        console.error('Failed to fetch project data:', error);
      }
    };
    fetch(`https://api.chaulanart.com/course/course-thumnail`)
      .then((res) => res.json())
      .then((data) => {
        setCourseThumnail(data);
      });

    fetchProjects();
    fetch(`https://api.chaulanart.com/header/header-menu`)
      .then((res) => res.json())
      .then((data) => {
        const title = data.find((item) => item.menu_id === 3);
        document.title = `${title.name} - Chau Lan Art `;
      });
  }, []);

  const checkURL = (url) => {
    return /\.(jpeg|jpg|gif|png)$/i.test(url);
  };
  const isValidYoutubeEmbedUrl = (url) => {
    const pattern = /^https:\/\/www\.youtube\.com\/embed\/.+/i;
    return pattern.test(url);
  };

  function SampleNextArrow(props) {
    const { className, onClick } = props;
    return (
      <div
        className={`${className} course-next`}
        onClick={onClick}>
        <i className="fas fa-chevron-right"></i>
      </div>
    );
  }

  function SamplePrevArrow(props) {
    const { className, onClick } = props;
    return (
      <div
        className={`${className} course-prev`}
        onClick={onClick}>
        {' '}
        <i className="fas fa-chevron-left"></i>
      </div>
    );
  }

  const pageDescription = courseThumnail.description;
  const baseUrl = 'https://chaulanart.com';
  const pageUrl = `${baseUrl}/courses`;
  const imageUrl = courseThumnail.thumnail;

  function stripHtml(html) {
    // Temporary element for parsing HTML content
    var tempDivElement = document.createElement('div');
    tempDivElement.innerHTML = html;
    return tempDivElement.textContent || tempDivElement.innerText || '';
  }

  function convertToHyphenatedString(htmlString) {
    // Create a new DOM parser
    const parser = new DOMParser();

    // Parse the string into an HTML document
    const doc = parser.parseFromString(htmlString, 'text/html');

    // Select all <p> elements
    const paragraphs = doc.querySelectorAll('p');

    // Extract text content from each <p> element, join them with a hyphen, and convert to lowercase
    let combinedText = Array.from(paragraphs)
      .map((p) => p.textContent)
      .join('-')
      .replace(/ /g, '-')
      .toLowerCase();

    return combinedText;
  }

  return (
    <>
      <Helmet>
        <meta
          property="og:description"
          content={pageDescription}
        />
        <meta
          property="og:url"
          content={pageUrl || baseUrl}
        />
        <meta
          property="og:image"
          content={imageUrl}
        />
      </Helmet>
      <section className="course">
        <div className="container">
          {courseCateList.map((item, index) => {
            const itemCount = courseItem[item.course_cate_id]?.length || 0;
            const settings = {
              dots: false,
              infinite: true,
              speed: 1000,
              slidesToShow: Math.min(3, itemCount), // Dynamically set based on item count
              slidesToScroll: 1,
              nextArrow: <SampleNextArrow />,
              prevArrow: <SamplePrevArrow />,
              responsive: [
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: Math.min(3, itemCount),
                    slidesToScroll: 1,
                  },
                },
                {
                  breakpoint: 768,
                  settings: {
                    slidesToShow: Math.min(2, itemCount),
                    slidesToScroll: 1,
                  },
                },
                {
                  breakpoint: 480,
                  settings: { slidesToShow: 1, slidesToScroll: 1 },
                },
              ],
            };

            return courseItem[item.course_cate_id]?.length > 0 ? (
              <section
                className={`course-dpi`}
                key={item.course_cate_id}>
                <div
                  className="course-heading"
                  dangerouslySetInnerHTML={{ __html: item.heading }}
                />
                <div className="course-flex">
                  {courseItem[item.course_cate_id]?.length > 2 ? (
                    <Slider
                      {...settings}
                      className="course-list">
                      {courseItem[item.course_cate_id]?.map((course, idx) => (
                        <Link
                          key={idx}
                          to={`/course/${
                            item.course_cate_id === 1
                              ? 'dpi-center'
                              : 'cbs-art-space'
                          }/${
                            course.course_item_id
                          }-${convertToHyphenatedString(course.name)}`}
                          className={`course-item-link ${
                            course.status === 0 ? 'coming' : ''
                          }`}>
                          <div className="course-item">
                            <div className="course-item-image">
                              {checkURL(course.image) ? (
                                <img
                                  src={course.image}
                                  title="Uploaded Image"
                                  alt="Uploaded"
                                  className="course-img"
                                />
                              ) : isValidYoutubeEmbedUrl(course.image) ? (
                                <iframe
                                  src={course.image}
                                  title="Uploaded Video"
                                  alt="Uploaded"
                                  className="course-img"
                                />
                              ) : (
                                <iframe
                                  src={course.image}
                                  title="Uploaded Video"
                                  alt="Uploaded"
                                  className="course-img"
                                />
                              )}
                              {course.status === 0 ? (
                                <div className="course-item-coming">
                                  <h3 className="course-coming-heading">
                                    Coming soon
                                  </h3>
                                </div>
                              ) : (
                                ''
                              )}
                            </div>
                            <div
                              className="course-name"
                              dangerouslySetInnerHTML={{ __html: course.name }}>
                              {console.log(stripHtml(course.name))}
                            </div>
                          </div>
                        </Link>
                      ))}
                    </Slider>
                  ) : courseItem[item.course_cate_id]?.length > 1 &&
                    windowWidth <= 480 ? (
                    <Slider
                      {...settings}
                      className="course-list">
                      {courseItem[item.course_cate_id]?.map((course, idx) => (
                        <Link
                          key={idx}
                          to={`/course/${
                            item.course_cate_id === 1
                              ? 'dpi-center'
                              : 'cbs-art-space'
                          }/${course.course_item_id}`}
                          className={`course-item-link ${
                            course.status === 0 ? 'coming' : ''
                          }`}>
                          <div
                            className="course-item"
                            style={{ margin: '0 auto' }}>
                            <div className="course-item-image">
                              {checkURL(course.image) ? (
                                <img
                                  src={course.image}
                                  title="Uploaded Image"
                                  alt="Uploaded"
                                  className="course-img"
                                />
                              ) : isValidYoutubeEmbedUrl(course.image) ? (
                                <iframe
                                  src={course.image}
                                  title="Uploaded Video"
                                  alt="Uploaded"
                                  className="course-img"
                                />
                              ) : (
                                <iframe
                                  src={course.image}
                                  title="Uploaded Video"
                                  alt="Uploaded"
                                  className="course-img"
                                />
                              )}
                              {course.status === 0 ? (
                                <div className="course-item-coming">
                                  <h3 className="course-coming-heading">
                                    Coming soon
                                  </h3>
                                </div>
                              ) : (
                                ''
                              )}
                            </div>
                            <div
                              className="course-name"
                              dangerouslySetInnerHTML={{ __html: course.name }}
                            />
                          </div>
                        </Link>
                      ))}
                    </Slider>
                  ) : (
                    <div className="course-list">
                      {courseItem[item.course_cate_id]?.map((course, idx) => (
                        <Link
                          key={idx}
                          to={`/course/${
                            item.course_cate_id === 1
                              ? 'dpi-center'
                              : 'cbs-art-space'
                          }/${course.course_item_id}`}
                          className={`course-item-link ${
                            course.status === 0 ? 'coming' : ''
                          }`}>
                          <div
                            className="course-item"
                            style={{ margin: '0 auto' }}>
                            <div className="course-item-image">
                              {checkURL(course.image) ? (
                                <img
                                  src={course.image}
                                  title="Uploaded Image"
                                  alt="Uploaded"
                                  className="course-img course-img-single"
                                />
                              ) : isValidYoutubeEmbedUrl(course.image) ? (
                                <iframe
                                  src={course.image}
                                  title="Uploaded Video"
                                  alt="Uploaded"
                                  className="course-img course-img-single"
                                />
                              ) : (
                                <iframe
                                  src={course.image}
                                  title="Uploaded Video"
                                  alt="Uploaded"
                                  className="course-img course-img-single"
                                />
                              )}
                              {course.status === 0 ? (
                                <div className="course-item-coming">
                                  <h3 className="course-coming-heading">
                                    Coming soon
                                  </h3>
                                </div>
                              ) : (
                                ''
                              )}
                            </div>
                            <div
                              className="course-name"
                              dangerouslySetInnerHTML={{ __html: course.name }}
                            />
                          </div>
                        </Link>
                      ))}
                    </div>
                  )}
                  <div className="course-border"></div>
                </div>
              </section>
            ) : null;
          })}
        </div>
      </section>
    </>
  );
};

export default Courses;
