import React, { useEffect, useState } from 'react';
import FormAddStudent from './FormAddStudent';
import FormEditStudent from './FormEditStudent';
import { useParams } from 'react-router-dom';
import ReactPaginate from 'react-paginate';

const Student = () => {
  const [showForm, setShowForm] = useState(false);
  const [showFormEdit, setShowFormEdit] = useState(false);
  const [courseStudent, setCourseStudent] = useState([]);
  const [courseItemEdit, setCourseItemEdit] = useState('');
  const [refreshKey, setRefreshKey] = useState(0);
  let { id } = useParams();

  const handleAddProjectClick = () => {
    setShowForm(true);
  };
  const handleEditCourseItem = (item) => {
    setShowFormEdit(true);
    setCourseItemEdit(item);
  };
  const handleCloseProjectClick = () => {
    setShowForm(false);
  };
  const handleCloseFormCourseItem = () => {
    setShowFormEdit(false);
  };

  useEffect(() => {
    const fetchProjects = async () => {
      const res = await fetch(
        `https://api.chaulanart.com/course/course-student/${id}`
      );
      const data = await res.json();

      setCourseStudent(data);
    };

    fetchProjects();
    setShowForm(false);
    setShowFormEdit(false);
  }, [id, refreshKey]);

  const deleteProject = (idItem) => {
    if (window.confirm('Bạn muốn xóa không?')) {
      fetch(
        `https://api.chaulanart.com/course/delete-course-student/${idItem}`,
        {
          method: 'DELETE',
        }
      )
        .then((res) => res.json())
        .then(() => {
          // Fetch the list of projects again after a project has been deleted
          const fetchProjects = async () => {
            const res = await fetch(
              `https://api.chaulanart.com/course/course-student/${id}`
            );
            const data = await res.json();
            setCourseStudent(data);
          };
          setRefreshKey((prevKey) => prevKey + 1);
          fetchProjects();
        })
        .catch((error) => console.error('Lỗi xóa:', error));
    }
  };

  const checkURL = (url) => {
    return /\.(jpeg|jpg|gif|png)$/i.test(url);
  };
  const isValidYoutubeEmbedUrl = (url) => {
    const pattern = /^https:\/\/www\.youtube\.com\/embed\/.+/i;
    return pattern.test(url);
  };

  function LayoutInPage({ itemInPage }) {
    return (
      <tbody className="project-list">
        {itemInPage.slice(0, 10).map((project) => (
          <tr>
            <td>
              <div dangerouslySetInnerHTML={{ __html: project.name }} />
            </td>
            <td>
              {checkURL(project.image) ? (
                <img
                  src={project.image}
                  title="Uploaded Image"
                  alt="Uploaded"
                  className="project-image"
                />
              ) : isValidYoutubeEmbedUrl(project.image) ? (
                <iframe
                  src={project.image}
                  title="Uploaded Video"
                  alt="Uploaded"
                  className="project-image"
                />
              ) : (
                <iframe
                  src={project.image}
                  title="Uploaded Video"
                  alt="Uploaded"
                  className="project-image"
                />
              )}
            </td>
            <td>
              <p
                className="project-actions"
                onClick={() => handleEditCourseItem(project)}>
                Sửa
              </p>
            </td>
            <td>
              <p
                className="project-actions"
                onClick={() => deleteProject(project.course_student_id)}>
                Xóa
              </p>
            </td>
          </tr>
        ))}
      </tbody>
    );
  }
  const PageItem = ({ pageSize }) => {
    const [currentPage, setCurrentPage] = useState(0);
    const pageCount = Math.ceil(courseStudent.length / pageSize);

    const handlePageClick = (selectedItem) => {
      setCurrentPage(selectedItem.selected);
      window.scrollTo(0, 0);
    };

    return (
      <>
        <LayoutInPage
          itemInPage={courseStudent.slice(
            currentPage * pageSize,
            (currentPage + 1) * pageSize
          )}
        />
        <ReactPaginate
          previousLabel={currentPage === 0 ? '' : 'Prev'}
          nextLabel={currentPage === pageCount - 1 ? '' : 'Next'}
          onPageChange={handlePageClick}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          containerClassName="project-pagination"
          activeClassName="active"
          forcePage={currentPage}
        />
      </>
    );
  };

  return (
    <div class="recent-orders">
      <h1>Student Artwork</h1>
      <div className="project-form">
        {showForm && (
          <>
            <FormAddStudent
              idCourse={id}
              onRefresh={() => setRefreshKey(refreshKey + 1)}
            />
            <div
              className="project-detail-add"
              onClick={handleCloseProjectClick}>
              <span>Đóng</span>
            </div>
          </>
        )}
        {showFormEdit && (
          <>
            <FormEditStudent
              onRefresh={() => setRefreshKey(refreshKey + 1)}
              studentItem={courseItemEdit}
              idCourse={id}
            />
            <div
              className="project-detail-add"
              onClick={handleCloseFormCourseItem}>
              <span>Đóng</span>
            </div>
          </>
        )}
      </div>
      <h2>Các dự án của học viên</h2>
      <table>
        <thead>
          <tr>
            <th>Tên học viên</th>
            <th>Hình minh họa</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <PageItem pageSize={6} />
      </table>
      <div
        className="project-add"
        onClick={handleAddProjectClick}>
        <span>Thêm dự án mới</span>
      </div>
    </div>
  );
};

export default Student;
