import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './ProjectDetail.css';
import FormProjectImage from '../FormProjectImage/FormProjectImage';
import FormProjectVisual from '../FormProjectVisual/FormProjectVisual';
import FormEditProject from '../FormProject/FormEditProject';
import FormEditProjectImage from '../FormProjectImage/FormEditProjectImage';
import FormEditProjectVisual from '../FormProjectVisual/FormEditProjectVisual';

const ProjectDetail = () => {
  const [projectDetail, setProjectDetail] = useState({});
  const [projectImage, setProjectImage] = useState([]);
  const [projectTag, setProjectTag] = useState([]);
  const [showFormImage, setShowFormImage] = useState(false);
  const [showFormTag, setShowFormTag] = useState(false);
  const [showFormEditProject, setShowEditProject] = useState(false);
  const [showFormEditProjectImage, setShowEditProjectImage] = useState(false);
  const [showFormEditProjectTag, setShowEditProjectTag] = useState(false);
  const [tagItem, setTagItem] = useState('');
  const [detailItem, setDetailItem] = useState('');
  const [indexItem, setIndexItem] = useState('');
  const [refreshKey, setRefreshKey] = useState(0);
  const handleRefresh = useCallback(
    () => setRefreshKey(refreshKey + 1),
    [refreshKey]
  );
  let { id } = useParams();
  const handleAddProjectImageClick = () => {
    setShowFormImage(true);
  };
  const handleAddProjectTagClick = () => {
    setShowFormTag(true);
    setShowEditProjectTag(false);
  };
  const handleAddProjectEditClick = () => {
    setShowEditProject(true);
  };
  const handleAddProjectEditImageClick = (detailItem, index) => {
    setShowEditProjectImage(true);
    setDetailItem(detailItem);
    setIndexItem(index);
  };
  const handleAddProjectEditTagClick = (tag) => {
    setShowEditProjectTag(true);
    setShowFormTag(false);
    setTagItem(tag);
  };
  const handleClodeProjectEditClick = () => {
    setShowEditProject(false);
  };
  const handleClodeProjectImageClick = () => {
    setShowFormImage(false);
  };
  const handleClodeProjectTagClick = () => {
    setShowFormTag(false);
  };
  const handleClodeProjectEditImageClick = () => {
    setShowEditProjectImage(false);
  };
  const handleClodeProjectEditTagClick = () => {
    setShowEditProjectTag(false);
  };

  useEffect(() => {
    const fetchProjectsDetail = async () => {
      const res = await fetch(
        `https://api.chaulanart.com/projects/get-project/${id}`
      );
      const data = await res.json();
      setProjectDetail(data);
    };
    const fetchProjectImages = async () => {
      const res = await fetch(
        `https://api.chaulanart.com/projects/get-project-images/${id}`
      );
      const data = await res.json();
      setProjectImage(data);
    };
    const fetchProjectTags = async () => {
      const res = await fetch(
        `https://api.chaulanart.com/projects/get-project-visuals/${id}`
      );
      const data = await res.json();
      setProjectTag(data);
    };
    fetchProjectTags();
    fetchProjectImages();
    fetchProjectsDetail();
    setShowFormImage(false);
    setShowFormTag(false);
    setShowEditProject(false);
    setShowEditProjectImage(false);
    setShowEditProjectTag(false);
  }, [id, refreshKey]);

  const checkURL = (url) => {
    return /\.(jpeg|jpg|gif|png)$/i.test(url);
  };
  const isValidYoutubeEmbedUrl = (url) => {
    const pattern = /^https:\/\/www\.youtube\.com\/embed\/.+/i;
    return pattern.test(url);
  };

  const deleteTag = (id_tag) => {
    if (window.confirm('Bạn muốn xóa không?')) {
      fetch(
        `https://api.chaulanart.com/projects/delete-project-visual/${id_tag}`,
        {
          method: 'DELETE',
        }
      )
        .then((res) => res.json())
        .then(() => {
          // Fetch the list of projects again after a project has been deleted
          const fetchProjects = async () => {
            const res = await fetch(
              `https://api.chaulanart.com/projects/get-project-visuals/${id}`
            );
            const data = await res.json();
            setProjectTag(data);
          };

          fetchProjects();
        })
        .catch((error) => console.error('Lỗi xóa:', error));
    }
  };
  const deleteContent = (id_content) => {
    if (window.confirm('Bạn muốn xóa nội dung này không?')) {
      fetch(
        `https://api.chaulanart.com/projects/delete-project-images/${id_content}`,
        {
          method: 'DELETE',
        }
      )
        .then((res) => res.json())
        .then(() => {
          // Fetch the list of projects again after a project has been deleted
          const fetchProjects = async () => {
            const res = await fetch(
              `https://api.chaulanart.com/projects/get-project-images/${id}`
            );
            const data = await res.json();
            setProjectImage(data);
          };

          fetchProjects();
        })
        .catch((error) => console.error('Lỗi xóa:', error));
    }
  };

  return (
    <div className="project-detail">
      <h1>Chi tiết dự án</h1>
      {showFormEditProject && (
        <>
          <FormEditProject
            onRefresh={() => setRefreshKey(refreshKey + 1)}
            project={projectDetail}
          />
          <div
            className="project-detail-add"
            onClick={handleClodeProjectEditClick}>
            <span>Đóng</span>
          </div>
        </>
      )}
      <div className="project-detail-info">
        <div className="project-detail-info-item">
          <label>Hình Thumbnail</label>
          {checkURL(projectDetail.image_main) ? (
            <img
              src={projectDetail.image_main}
              title="Uploaded Image"
              alt="Uploaded"
              className="project-detail-image"
            />
          ) : isValidYoutubeEmbedUrl(projectDetail.image_main) ? (
            <iframe
              src={projectDetail.image_main}
              title="Uploaded Video"
              alt="Uploaded"
              className="project-detail-image"
            />
          ) : (
            <iframe
              src={projectDetail.image_main}
              title="Uploaded Video"
              alt="Uploaded"
              className="project-detail-image"
            />
          )}
        </div>
        <div className="project-detail-info-item">
          <label>Tên dự án</label>
          <p>
            <div dangerouslySetInnerHTML={{ __html: projectDetail.heading }} />
          </p>
        </div>
        <div className="project-detail-info-item">
          <label>Trạng thái</label>
          <p>
            {projectDetail.status === 1
              ? 'Hoàn thành'
              : projectDetail.status === 2
              ? 'Ẩn'
              : 'Coming soon'}
          </p>
        </div>
      </div>
      <div
        className="project-detail-add"
        onClick={handleAddProjectEditClick}>
        <span>Sửa thông tin</span>
      </div>
      <h2>Các tags</h2>
      {showFormTag && (
        <>
          <FormProjectVisual
            onRefresh={() => setRefreshKey(refreshKey + 1)}
            project_id={id}
          />
          <div
            className="project-detail-add"
            onClick={handleClodeProjectTagClick}>
            <span>Đóng</span>
          </div>
        </>
      )}
      {showFormEditProjectTag && (
        <>
          <FormEditProjectVisual
            onRefresh={handleRefresh}
            project_id={id}
            projectVisual={tagItem}
          />
          <div
            className="project-detail-add"
            onClick={handleClodeProjectEditTagClick}>
            <span>Đóng</span>
          </div>
        </>
      )}
      {projectTag && projectTag.length > 0 ? (
        <div className="project-detail-tags">
          {projectTag.map((tag) => (
            <div className="project-detail-tag-item">
              <span key={tag.project_visual_id}>{tag.name_visual}</span>
              <div
                className="project-detail-tag-item-delete"
                onClick={() => deleteTag(tag.project_visual_id)}>
                <i class="fa-solid fa-xmark"></i>
              </div>
              <div
                className="project-detail-tag-item-edit"
                onClick={() => handleAddProjectEditTagClick(tag)}>
                <i class="fa-solid fa-pen"></i>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="project-detail-tags">
          <span>Chưa có tag</span>
        </div>
      )}
      <div
        className="project-detail-add"
        onClick={handleAddProjectTagClick}>
        <span>Thêm tags</span>
      </div>
      <h2>Nội dung dự án</h2>

      {projectImage && projectImage.length > 0 ? (
        projectImage.map((item, index) => (
          <>
            {showFormEditProjectImage && indexItem === index ? (
              <>
                <FormEditProjectImage
                  onRefresh={() => setRefreshKey(refreshKey + 1)}
                  project_id={id}
                  oldImages={detailItem}
                />
                <div
                  className="project-detail-add"
                  onClick={handleClodeProjectEditImageClick}>
                  <span>Đóng</span>
                </div>
              </>
            ) : (
              ''
            )}
            <div className="project-image-detail">
              <div className="project-image-detail-item">
                {checkURL(item.image) ? (
                  <img
                    src={item.image}
                    title="Uploaded Image"
                    alt="Uploaded"
                    className="project-image"
                  />
                ) : isValidYoutubeEmbedUrl(item.image) ? (
                  <iframe
                    src={item.image}
                    title="Uploaded Video"
                    alt="Uploaded"
                    className="project-image"
                  />
                ) : (
                  <iframe
                    src={item.image}
                    title="Uploaded Video"
                    alt="Uploaded"
                    className="project-image"
                  />
                )}
              </div>
              <div className="project-image-detail-item">
                <div dangerouslySetInnerHTML={{ __html: item.heading }} />
              </div>
              <div className="project-image-detail-item">
                <div dangerouslySetInnerHTML={{ __html: item.visualizer }} />
              </div>
              <div className="project-image-detail-item">
                <div dangerouslySetInnerHTML={{ __html: item.description }} />
              </div>
            </div>
            <div
              className="project-detail-add"
              onClick={() => handleAddProjectEditImageClick(item, index)}>
              <span>Sửa nội dung</span>
            </div>
            <div
              className="project-detail-add"
              onClick={() => deleteContent(item.project_detail_id)}>
              <span>Xóa</span>
            </div>
          </>
        ))
      ) : (
        <div className="project-image-detail">
          <p>Không có nội dung</p>
        </div>
      )}
      {showFormImage && (
        <>
          <FormProjectImage
            onRefresh={() => setRefreshKey(refreshKey + 1)}
            project_id={id}
          />
          <div
            className="project-detail-add"
            onClick={handleClodeProjectImageClick}>
            <span>Đóng</span>
          </div>
        </>
      )}
      <div
        className="project-detail-add"
        onClick={handleAddProjectImageClick}>
        <span>Thêm nội dung</span>
      </div>
    </div>
  );
};

export default ProjectDetail;
