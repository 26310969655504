import React, { useCallback, useEffect, useState } from 'react';
import FormEditFooter from './FormEditFooter';
import FormAddMenuLeft from './FormAddMenuLeft';
import FormAddMenuRight from './FormAddMenuRight';
import FormEditMenuLeft from './FormEditMenuLeft';
import FormEditMenuRight from './FormEditMenuRight';

const Footer = () => {
  const [courseHeader, setCourseHeader] = useState({});
  const [menuLeft, setMenuLeft] = useState([]);
  const [menuRight, setMenuRight] = useState([]);
  const [refreshKey, setRefreshKey] = useState(0);
  const [showFormAddHeader, setShowFormAddHeader] = useState(false);
  const [showFormAddMenuLeft, setShowFormAddMenuLeft] = useState(false);
  const [showFormAddMenuRight, setShowFormAddMenuRight] = useState(false);
  const [showFormEditMenuLeft, setShowFormEditMenuLeft] = useState(false);
  const [showFormEditMenuRight, setShowFormEditMenuRight] = useState(false);
  const [menuLeftItem, setMenuLeftItem] = useState('');
  const [menuRightItem, setMenuRightItem] = useState('');
  const handleRefresh = useCallback(
    () => setRefreshKey(refreshKey + 1),
    [refreshKey]
  );
  const handleOpenFormAddHeader = () => {
    setShowFormAddHeader(true);
  };
  const handleCloseFormAddHeader = () => {
    setShowFormAddHeader(false);
  };

  const handleOpenFormAddMenuLeft = () => {
    setShowFormAddMenuLeft(true);
  };
  const handleCloseFormAddMenuLeft = () => {
    setShowFormAddMenuLeft(false);
  };

  const handleOpenFormAddMenuRight = () => {
    setShowFormAddMenuRight(true);
  };
  const handleCloseFormAddMenuRight = () => {
    setShowFormAddMenuRight(false);
  };

  const handleOpenFormEditMenuLeft = (menuItem) => {
    setShowFormEditMenuLeft(true);
    setMenuLeftItem(menuItem);
  };
  const handleCloseFormEditMenuLeft = () => {
    setShowFormEditMenuLeft(false);
  };

  const handleOpenFormEditMenuRight = (menuItem) => {
    setShowFormEditMenuRight(true);
    setMenuRightItem(menuItem);
  };
  const handleCloseFormEditMenuRight = () => {
    setShowFormEditMenuRight(false);
  };

  useEffect(() => {
    const fetchAboutHeader = async () => {
      const res = await fetch(`https://api.chaulanart.com/footer/footer`);
      const text = await res.text();
      const data = text ? JSON.parse(text) : {};
      setCourseHeader(data);
    };
    const fetchMenuLeft = async () => {
      const res = await fetch(
        `https://api.chaulanart.com/footer/footer-menu-left`
      );
      const text = await res.text();
      const data = text ? JSON.parse(text) : {};
      setMenuLeft(data);
    };
    const fetchMenuRight = async () => {
      const res = await fetch(
        `https://api.chaulanart.com/footer/footer-menu-right`
      );
      const text = await res.text();
      const data = text ? JSON.parse(text) : {};
      setMenuRight(data);
    };
    fetchAboutHeader();
    fetchMenuLeft();
    fetchMenuRight();
    setShowFormAddHeader(false);
    setShowFormEditMenuLeft(false);
    setShowFormEditMenuRight(false);
    setShowFormAddMenuLeft(false);
    setShowFormAddMenuRight(false);
  }, [refreshKey]);

  const deleteMenuLeft = (id) => {
    if (window.confirm('Bạn muốn xóa không?')) {
      fetch(`https://api.chaulanart.com/footer/delete-footer-menu-left/${id}`, {
        method: 'DELETE',
      })
        .then((res) => res.json())
        .then(() => {
          // Fetch the list of projects again after a project has been deleted
          const fetchProjects = async () => {
            const res = await fetch(
              `https://api.chaulanart.com/footer/footer-menu-left`
            );
            const data = await res.json();
            setMenuLeft(data);
          };

          fetchProjects();
        })
        .catch((error) => console.error('Lỗi xóa:', error));
    }
  };
  const deleteMenuRight = (id) => {
    if (window.confirm('Bạn muốn xóa không?')) {
      fetch(
        `https://api.chaulanart.com/footer/delete-footer-menu-right/${id}`,
        {
          method: 'DELETE',
        }
      )
        .then((res) => res.json())
        .then(() => {
          // Fetch the list of projects again after a project has been deleted
          const fetchProjects = async () => {
            const res = await fetch(
              `https://api.chaulanart.com/footer/footer-menu-right`
            );
            const data = await res.json();
            setMenuRight(data);
          };

          fetchProjects();
        })
        .catch((error) => console.error('Lỗi xóa:', error));
    }
  };

  return (
    <div className="podcastPage">
      <h1>Thông tin footer</h1>
      {showFormAddHeader && (
        <>
          <FormEditFooter
            onRefresh={handleRefresh}
            footerItem={courseHeader}
          />
          <div
            className="workpage-add"
            onClick={handleCloseFormAddHeader}>
            <span>Đóng</span>
          </div>
        </>
      )}
      <div className="podcastPage-header">
        <div className="podcastPage-info-item">
          <label>Email</label>
          <p dangerouslySetInnerHTML={{ __html: courseHeader.email }} />
        </div>
        <div className="podcastPage-header-item">
          <label>Địa chỉ</label>
          <p dangerouslySetInnerHTML={{ __html: courseHeader.address }} />
        </div>
        <div className="podcastPage-info-item">
          <label>Số điện thoại</label>
          <p dangerouslySetInnerHTML={{ __html: courseHeader.phone }} />
        </div>
      </div>
      <div
        className="project-detail-add"
        onClick={handleOpenFormAddHeader}>
        <span>Sửa thông tin</span>
      </div>
      <h2>Menu cột trái</h2>
      {showFormAddMenuLeft ? (
        <>
          <FormAddMenuLeft onRefresh={() => setRefreshKey(refreshKey + 1)} />
          <div
            className="workpage-add"
            onClick={handleCloseFormAddMenuLeft}>
            <span>Đóng</span>
          </div>
        </>
      ) : null}
      {showFormEditMenuLeft ? (
        <>
          <FormEditMenuLeft
            menuItem={menuLeftItem}
            onRefresh={() => setRefreshKey(refreshKey + 1)}
          />
          <div
            className="workpage-add"
            onClick={handleCloseFormEditMenuLeft}>
            <span>Đóng</span>
          </div>
        </>
      ) : null}
      <div className="workpage-tags">
        {menuLeft && menuLeft.length > 0 ? (
          menuLeft.map((menu, index) => (
            <div
              className="workpage-tags-item"
              key={index}>
              <span>{menu.name}</span>
              <div
                className="workpage-tags-item-delete"
                onClick={() => deleteMenuLeft(menu.fml_id)}>
                <i className="fa-solid fa-xmark"></i>
              </div>
              <div
                className="workpage-tags-item-edit"
                onClick={() => handleOpenFormEditMenuLeft(menu)}>
                <i className="fa-solid fa-pen"></i>
              </div>
            </div>
          ))
        ) : (
          <div className="workpage-tags-item">
            <span>Menu</span>
          </div>
        )}
      </div>
      <div
        className="project-detail-add"
        onClick={handleOpenFormAddMenuLeft}>
        <span>Thêm mới</span>
      </div>
      <h2>Menu cột phải</h2>
      {showFormAddMenuRight ? (
        <>
          <FormAddMenuRight onRefresh={() => setRefreshKey(refreshKey + 1)} />
          <div
            className="workpage-add"
            onClick={handleCloseFormAddMenuRight}>
            <span>Đóng</span>
          </div>
        </>
      ) : null}
      {showFormEditMenuRight ? (
        <>
          <FormEditMenuRight
            menuItem={menuRightItem}
            onRefresh={() => setRefreshKey(refreshKey + 1)}
          />
          <div
            className="workpage-add"
            onClick={handleCloseFormEditMenuRight}>
            <span>Đóng</span>
          </div>
        </>
      ) : null}
      <div className="workpage-tags">
        {menuRight && menuRight.length > 0 ? (
          menuRight.map((menu, index) => (
            <div
              className="workpage-tags-item"
              key={index}>
              <span>{menu.name}</span>
              <div
                className="workpage-tags-item-delete"
                onClick={() => deleteMenuRight(menu.fmr_id)}>
                <i className="fa-solid fa-xmark"></i>
              </div>
              <div
                className="workpage-tags-item-edit"
                onClick={() => handleOpenFormEditMenuRight(menu)}>
                <i className="fa-solid fa-pen"></i>
              </div>
            </div>
          ))
        ) : (
          <div className="workpage-tags-item">
            <span>Menu</span>
          </div>
        )}
      </div>
      <div
        className="project-detail-add"
        onClick={handleOpenFormAddMenuRight}>
        <span>Thêm mới</span>
      </div>
    </div>
  );
};

export default Footer;
