import React, { useEffect, useState } from 'react';
import './MoreProject.css';
import { Link } from 'react-router-dom';

const MoreProject = ({ idWork }) => {
  const [workList, setWorkList] = useState([]);
  const [workTags, setWorkTags] = useState({});

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const res = await fetch(
          `https://api.chaulanart.com/projects/get-projects-next/${idWork}`
        );
        const projects = await res.json();

        setWorkList(projects);

        // Fetch tags for all projects
        const tagsPromises = projects.map((project) =>
          fetch(
            `https://api.chaulanart.com/projects/get-project-visuals/${project.project_id}`
          )
            .then((res) => res.json())
            .then((tagData) => ({
              projectId: project.project_id,
              tags: tagData,
            }))
        );

        Promise.all(tagsPromises).then((results) => {
          const tagsByProject = results.reduce((acc, current) => {
            acc[current.projectId] = current.tags;
            return acc;
          }, {});
          setWorkTags(tagsByProject);
        });
      } catch (error) {
        console.error('Failed to fetch project data:', error);
      }
    };

    fetchProjects();
  }, [idWork]);

  const checkURL = (url) => {
    return /\.(jpeg|jpg|gif|png)$/i.test(url);
  };
  const isValidYoutubeEmbedUrl = (url) => {
    const pattern = /^https:\/\/www\.youtube\.com\/embed\/.+/i;
    return pattern.test(url);
  };

  function stripHtml(html) {
    // Temporary element for parsing HTML content
    var tempDivElement = document.createElement('div');
    tempDivElement.innerHTML = html;
    return tempDivElement.textContent || tempDivElement.innerText || '';
  }

  return (
    <section className="more-project">
      <div className="container">
        <h3 className="more-project-heading">More Project</h3>
        <div className="work-list">
          {workList.map((item) =>
            item.status !== 2 ? (
              <div className={`work-item ${item.status === 0 ? 'coming' : ''}`}>
                <Link
                  className={`work-item-link ${
                    item.status === 0 ? 'coming' : ''
                  }`}
                  to={`/work/${item.project_id}-${stripHtml(item.heading)
                    .replace(/ /g, '-')
                    .toLowerCase()}`}
                  key={item.project_id}>
                  <div className="work-item-content">
                    <div className="work-item-image">
                      {checkURL(item.image_main) ? (
                        <img
                          src={item.image_main}
                          title="Uploaded Image"
                          alt="Uploaded"
                          className="work-item-img"
                        />
                      ) : isValidYoutubeEmbedUrl(item.image_main) ? (
                        <iframe
                          src={item.image_main}
                          title="Uploaded Video"
                          alt="Uploaded"
                          className="work-item-img"
                        />
                      ) : (
                        <iframe
                          src={item.image_main}
                          title="Uploaded Video"
                          alt="Uploaded"
                          className="work-item-img"
                        />
                      )}
                      {item.status === 0 ? (
                        <div className="work-item-coming">
                          <h3 className="work-coming-heading">Coming soon</h3>
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                    <h4
                      className="work-title"
                      dangerouslySetInnerHTML={{ __html: item.heading }}
                    />
                    <div className="work-tag">
                      {workTags[item.project_id]?.map((tag, tagIndex) => (
                        <p
                          className="work-tag-item"
                          key={tagIndex}>
                          {tag.name_visual}
                        </p>
                      ))}
                    </div>
                  </div>
                </Link>
              </div>
            ) : (
              ''
            )
          )}
        </div>
      </div>
    </section>
  );
};

export default MoreProject;
