import React, { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { DevTool } from '@hookform/devtools';
import { useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import cloudinaryUpload from '../../../server/uploads.js';
import cloudinaryDelete from '../../../server/delete.js';
import * as yup from 'yup';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import quillEmoji from 'react-quill-emoji';
import 'react-quill-emoji/dist/quill-emoji.css';
import { SnowTheme } from 'quill-color-picker-enhance';
import 'quill-color-picker-enhance/dist/index.css';
Quill.register('themes/snow-quill-color-picker-enhance', SnowTheme);

Quill.register(
  {
    'formats/emoji': quillEmoji.EmojiBlot,
    'modules/emoji-toolbar': quillEmoji.ToolbarEmoji,
    'modules/emoji-shortname': quillEmoji.ShortNameEmoji,
  },
  true
);
const schema = yup.object({
  heading: yup
    .string()
    .trim()
    .required('Không được bỏ trống')
    .min(2, 'Tiêu đề có tối thiểu 2 ký tự'),
  description: yup
    .string()
    .trim()
    .required('Không được bỏ trống')
    .min(2, 'Mô tả có tối thiểu 10 ký tự'),
  image: yup.string().required('Không được bỏ trống'),
  infomation: yup.string().trim().required('Không được bỏ trống'),
});

let Parchment = Quill.import('parchment');
let LineHeightAttributor = new Parchment.Attributor.Style(
  'lineHeight', // Sử dụng camelCase cho tên định dạng
  'line-height',
  {
    scope: Parchment.Scope.BLOCK_ATTRIBUTE,
  }
);
Quill.register(
  {
    'formats/lineHeight': LineHeightAttributor,
  },
  true
);

var Size = Quill.import('attributors/style/size');
Size.whitelist = Array.from(
  { length: 100 },
  (_, i) => (i + 1).toString() + 'px'
);
Quill.register(Size, true);

const FormEditCourseHeader = ({ idCourse, onRefresh, courseHeader }) => {
  const [uploadedImageUrl, setUploadedImageUrl] = useState('');
  const [uploadedImagePublicId, setUploadedImagePublicId] = useState('');
  const [isImageUploaded, setIsImageUploaded] = useState(false);
  const [uploadOption, setUploadOption] = useState('file');
  const [isLoaded, setIsLoaded] = useState(false);

  const reactQuillRef = useRef(null);
  useEffect(() => {
    let sizePickerItems = document.querySelectorAll('.ql-size .ql-picker-item');
    let lHeightPickerItems = document.querySelectorAll(
      '.ql-lineHeight .ql-picker-item'
    );

    for (let i = 0; i < sizePickerItems.length; i++) {
      let item = sizePickerItems[i];
      let value = item.getAttribute('data-value');
      if (value) {
        item.textContent = value;
      }
    }
    for (let i = 0; i < lHeightPickerItems.length; i++) {
      let item = lHeightPickerItems[i];
      let value = item.getAttribute('data-value');
      if (value) {
        item.textContent = value;
      }
    }
  }, []);

  const form = useForm({
    defaultValues: {
      heading: courseHeader.heading,
      description: courseHeader.description,
      image: courseHeader.image,
      infomation: courseHeader.infomation,
      course_item_id: idCourse,
    },
    resolver: yupResolver(schema),
  });
  const { handleSubmit, reset, formState, control, setValue } = form;
  const { errors, isSubmitSuccessful } = formState;

  const handleFileUpload = async (e) => {
    setIsLoaded(true);
    const fileData = new FormData();
    fileData.append('file', e.target.files[0]);

    try {
      const res = await cloudinaryUpload(fileData);
      setUploadedImageUrl(res.secure_url);
      setUploadedImagePublicId(res.publicId);
      setValue('image', res.secure_url);
      setIsImageUploaded(true);
    } catch (err) {
      console.error('Upload Error:', err);
    }
  };

  const handleDeleteImage = async () => {
    if (!uploadedImagePublicId) {
      alert('No image to delete');
      return;
    }
    try {
      cloudinaryDelete(uploadedImagePublicId);
      setUploadedImageUrl('');
      setUploadedImagePublicId('');
      setIsImageUploaded(false);
      setValue('image', '');
      setIsLoaded(false);
    } catch (err) {
      console.error('Deletion Error:', err.message);
      alert('Failed to delete image');
    }
  };

  const handleSubmitProject = async (data) => {
    function convertToUnicode(input) {
      return input.replace(
        /([\uD800-\uDBFF][\uDC00-\uDFFF])/g,
        function (char) {
          return '&#x' + char.codePointAt(0).toString(16) + ';';
        }
      );
    }
    function convertObjectToUnicode(obj) {
      for (let key in obj) {
        if (typeof obj[key] === 'object') {
          convertObjectToUnicode(obj[key]); // Nếu phần tử là một đối tượng, gọi đệ quy
        } else if (typeof obj[key] === 'string') {
          obj[key] = convertToUnicode(obj[key]); // Nếu phần tử là một chuỗi, chuyển đổi thành Unicode
        }
      }
      return obj;
    }

    const convertedData = convertObjectToUnicode(data);

    try {
      const url = `https://api.chaulanart.com/course/update-course-header/${courseHeader.course_header_id}`;
      const opt = {
        method: 'put',
        body: JSON.stringify(convertedData),
        headers: { 'Content-Type': 'application/json' },
      };
      const res = await fetch(url, opt);
      const responseData = await res.json();
      alert('Xác nhận thành công', responseData);
    } catch (error) {
      console.error('Lỗi khi cập nhật sản phẩm: ', error);
    }
  };

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
      onRefresh();
    }
  }, [isSubmitSuccessful, reset, onRefresh]);

  const handleUploadOptionChange = (e) => {
    setUploadOption(e.target.value);
  };
  const extractSrcFromIframe = (iframeString) => {
    const srcMatch = iframeString.match(
      /src\s*=\s*"([^"]+)"|src\s*=\s*'([^']+)'/
    );
    return srcMatch ? srcMatch[1] || srcMatch[2] : null;
  };

  const handleInputChange = (e) => {
    const embedCode = e.target.value;
    const url = extractSrcFromIframe(embedCode);
    setValue('image', url);
  };
  const checkURL = (url) => {
    return /\.(jpeg|jpg|gif|png)$/i.test(url);
  };
  const isValidYoutubeEmbedUrl = (url) => {
    const pattern = /^https:\/\/www\.youtube\.com\/embed\/.+/i;
    return pattern.test(url);
  };

  return (
    <div className="form-project">
      <form
        onSubmit={handleSubmit(handleSubmitProject)}
        noValidate>
        <div className="form-project-item">
          <div className="form-project-flex">
            <div className="form-project-flex-item">
              <label>Hình ảnh chính</label>
              <select onChange={(e) => handleUploadOptionChange(e)}>
                <option value="file">Tải lên từ máy</option>
                <option value="url">Nhập link từ YouTube</option>
              </select>
              {uploadOption === 'file' ? (
                <input
                  type="file"
                  onChange={(e) => handleFileUpload(e)}
                  accept="image/*,video/*"
                />
              ) : (
                <input
                  type="text"
                  placeholder="Nhập mã nhúng từ YouTube"
                  onChange={handleInputChange}
                />
              )}
              {errors.image && <p className="error">{errors.image.message}</p>}
            </div>
            <div className="form-project-flex-item">
              {isLoaded ? (
                isImageUploaded && isImageUploaded ? (
                  <div className="form-project-flex-img">
                    {checkURL(uploadedImageUrl) ? (
                      <img
                        src={uploadedImageUrl}
                        title="Uploaded Image"
                        alt="Uploaded"
                      />
                    ) : (
                      <iframe
                        src={uploadedImageUrl}
                        title="Uploaded Video"
                        alt="Uploaded"
                      />
                    )}
                    <p onClick={handleDeleteImage}>Xóa</p>
                  </div>
                ) : (
                  <p className="project-loading">Loading ...</p>
                )
              ) : (
                <div className="form-project-flex-img">
                  {checkURL(courseHeader.image) ? (
                    <img
                      src={courseHeader.image}
                      title="Uploaded Image"
                      alt="Uploaded"
                    />
                  ) : isValidYoutubeEmbedUrl(courseHeader.image) ? (
                    <iframe
                      src={courseHeader.image}
                      title="Uploaded Video"
                      alt="Uploaded"
                    />
                  ) : (
                    <iframe
                      src={courseHeader.image}
                      title="Uploaded Video"
                      alt="Uploaded"
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="form-project-item">
          <label>Tên tiêu đề</label>
          <ReactQuill
            ref={reactQuillRef}
            theme="snow-quill-color-picker-enhance"
            value={form.watch('heading')}
            onChange={(content) => form.setValue('heading', content)}
            modules={{
              toolbar: {
                container: [
                  ['bold', 'italic', 'underline', 'strike'],
                  ['blockquote', 'code-block'],
                  [{ list: 'ordered' }, { list: 'bullet' }],
                  [{ script: 'sub' }, { script: 'super' }],
                  [{ indent: '-1' }, { indent: '+1' }],
                  [{ direction: 'rtl' }],
                  [
                    {
                      lineHeight: [
                        '1',
                        '1.1',
                        '1.2',
                        '1.3',
                        '1.4',
                        '1.5',
                        '1.6',
                        '1.7',
                        '1.8',
                        '1.9',
                        '2',
                        '2.5',
                        '3',
                      ],
                    },
                  ], // Đảm bảo cú pháp này chính xác
                  [{ size: Size.whitelist }],
                  [{ header: [2, 3, 4, 5, 6, false] }],
                  [{ color: [] }, { background: [] }],
                  [{ font: [] }],
                  [{ align: [] }],
                  ['clean'],
                  ['link'],
                  ['emoji'],
                ],
                'emoji-toolbar': true,
              },
              'emoji-textarea': true,
              'emoji-shortname': true,
              clipboard: {
                matchVisual: false,
              },
            }}
          />
          {errors.heading && <p className="error">{errors.heading.message}</p>}
        </div>
        <div className="form-project-item">
          <label>Thông tin khóa học</label>
          <ReactQuill
            ref={reactQuillRef}
            theme="snow-quill-color-picker-enhance"
            value={form.watch('infomation')}
            onChange={(content) => form.setValue('infomation', content)}
            modules={{
              toolbar: {
                container: [
                  ['bold', 'italic', 'underline', 'strike'],
                  ['blockquote', 'code-block'],
                  [{ list: 'ordered' }, { list: 'bullet' }],
                  [{ script: 'sub' }, { script: 'super' }],
                  [{ indent: '-1' }, { indent: '+1' }],
                  [{ direction: 'rtl' }],
                  [
                    {
                      lineHeight: [
                        '1',
                        '1.1',
                        '1.2',
                        '1.3',
                        '1.4',
                        '1.5',
                        '1.6',
                        '1.7',
                        '1.8',
                        '1.9',
                        '2',
                        '2.5',
                        '3',
                      ],
                    },
                  ], // Đảm bảo cú pháp này chính xác
                  [{ size: Size.whitelist }],
                  [{ header: [2, 3, 4, 5, 6, false] }],
                  [{ color: [] }, { background: [] }],
                  [{ font: [] }],
                  [{ align: [] }],
                  ['clean'],
                  ['link'],
                  ['emoji'],
                ],
                'emoji-toolbar': true,
              },
              'emoji-textarea': true,
              'emoji-shortname': true,
              clipboard: {
                matchVisual: false,
              },
            }}
          />
          {errors.infomation && (
            <p className="error">{errors.infomation.message}</p>
          )}
        </div>
        <div className="form-project-item">
          <label>Mô tả</label>
          <ReactQuill
            ref={reactQuillRef}
            theme="snow-quill-color-picker-enhance"
            value={form.watch('description')}
            onChange={(content) => form.setValue('description', content)}
            modules={{
              toolbar: {
                container: [
                  ['bold', 'italic', 'underline', 'strike'],
                  ['blockquote', 'code-block'],
                  [{ list: 'ordered' }, { list: 'bullet' }],
                  [{ script: 'sub' }, { script: 'super' }],
                  [{ indent: '-1' }, { indent: '+1' }],
                  [{ direction: 'rtl' }],
                  [
                    {
                      lineHeight: [
                        '1',
                        '1.1',
                        '1.2',
                        '1.3',
                        '1.4',
                        '1.5',
                        '1.6',
                        '1.7',
                        '1.8',
                        '1.9',
                        '2',
                        '2.5',
                        '3',
                      ],
                    },
                  ], // Đảm bảo cú pháp này chính xác
                  [{ size: Size.whitelist }],
                  [{ header: [2, 3, 4, 5, 6, false] }],
                  [{ color: [] }, { background: [] }],
                  [{ font: [] }],
                  [{ align: [] }],
                  ['clean'],
                  ['link'],
                  ['emoji'],
                ],
                'emoji-toolbar': true,
              },
              'emoji-textarea': true,
              'emoji-shortname': true,
              clipboard: {
                matchVisual: false,
              },
            }}
          />
          {errors.description && (
            <p className="error">{errors.description.message}</p>
          )}
        </div>
        <div className="form-project-item">
          <button>Xác nhận</button>
        </div>
      </form>
      <DevTool control={control} />
    </div>
  );
};

export default FormEditCourseHeader;
