import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import FormAddAboutExContent from './FormAddAboutExContent';
import ReactPaginate from 'react-paginate';

const AboutEx = () => {
  const [aboutEx, setAboutEx] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [refreshKey, setRefreshKey] = useState(0);

  const handleOpenAboutAddEx = () => {
    setShowForm(true);
  };
  const handleCloseAboutAddEx = () => {
    setShowForm(false);
  };

  useEffect(() => {
    const fetchAboutAward = async () => {
      const res = await fetch(
        'https://api.chaulanart.com/about/about-ex-content'
      );
      const data = await res.json();
      setAboutEx(data);
    };
    fetchAboutAward();
  }, [refreshKey]);

  const deleteAboutEx = (id) => {
    if (window.confirm('Bạn muốn xóa project này không?')) {
      fetch(`https://api.chaulanart.com/about/delete-about-ex-content/${id}`, {
        method: 'DELETE',
      })
        .then((res) => res.json())
        .then(() => {
          // Fetch the list of projects again after a project has been deleted
          const fetchProjects = async () => {
            const res = await fetch(
              'https://api.chaulanart.com/about/about-ex-content'
            );
            const data = await res.json();
            setAboutEx(data);
          };

          fetchProjects();
        })
        .catch((error) => console.error('Lỗi xóa:', error));
    }
  };

  function LayoutInPage({ itemInPage }) {
    return (
      <tbody className="project-list">
        {itemInPage &&
          itemInPage.slice(0, 10).map((item) => (
            <tr>
              <td>
                <div dangerouslySetInnerHTML={{ __html: item.heading }} />
              </td>
              <td>
                <div dangerouslySetInnerHTML={{ __html: item.title }} />
              </td>
              <td>
                <Link
                  className="project-actions"
                  to={`/admin/about-ex-content/${item.about_ex_content_id}`}>
                  Chi tiết
                </Link>
              </td>
              <td>
                <p
                  className="project-actions"
                  onClick={() => deleteAboutEx(item.about_ex_content_id)}>
                  Xóa
                </p>
              </td>
            </tr>
          ))}
      </tbody>
    );
  }
  const PageItem = ({ pageSize }) => {
    const [currentPage, setCurrentPage] = useState(0);
    const pageCount = Math.ceil(aboutEx.length / pageSize);

    const handlePageClick = (selectedItem) => {
      setCurrentPage(selectedItem.selected);
      window.scrollTo(0, 0);
    };

    return (
      <>
        <LayoutInPage
          itemInPage={aboutEx.slice(
            currentPage * pageSize,
            (currentPage + 1) * pageSize
          )}
        />
        <ReactPaginate
          previousLabel={currentPage === 0 ? '' : 'Prev'}
          nextLabel={currentPage === pageCount - 1 ? '' : 'Next'}
          onPageChange={handlePageClick}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          containerClassName="project-pagination"
          activeClassName="active"
          forcePage={currentPage}
        />
      </>
    );
  };

  return (
    <div class="recent-orders">
      {showForm && (
        <>
          <FormAddAboutExContent
            onRefresh={() => setRefreshKey(refreshKey + 1)}
          />
          <div
            className="project-detail-add"
            onClick={handleCloseAboutAddEx}>
            <span>Đóng</span>
          </div>
        </>
      )}
      <h1>About Ex</h1>
      <table>
        <thead>
          <tr>
            <th>Tên dự án</th>
            <th>Tên tiêu đề</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <PageItem pageSize={10} />
      </table>
      <div
        className="project-add"
        onClick={handleOpenAboutAddEx}>
        <span>Thêm dự án mới</span>
      </div>
    </div>
  );
};

export default AboutEx;
