import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const AboutResume = () => {
  const [aboutResune, setAboutResume] = useState({});

  useEffect(() => {
    const fetchAboutEducaition = async () => {
      const res = await fetch(`https://api.chaulanart.com/about/about-resume`);
      const data = await res.json();
      setAboutResume(data);
    };

    fetchAboutEducaition();
  }, []);

  return (
    <section className="about-resume">
      <h3
        className="about-resume-heading"
        dangerouslySetInnerHTML={{
          __html: aboutResune.heading,
        }}
      />
      <div className="about-resume-wrapper">
        <h4
          className="about-resume-title"
          dangerouslySetInnerHTML={{
            __html: aboutResune.title,
          }}
        />
        <Link
          className="about-resume-text"
          to={aboutResune.link}
          target="_blank"
          dangerouslySetInnerHTML={{ __html: aboutResune.text }}
        />
      </div>
    </section>
  );
};

export default AboutResume;
