import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import './About.css';
import AboutAward from './AboutAward/AboutAward';
import AboutStory from './AboutStory/AboutStory';
import AboutEducation from './AboutEducation/AboutEducation';
import AboutExperiences from './AboutExperiences/AboutExperiences';
import AboutResume from './AboutResume/AboutResume';

const About = () => {
  const [aboutHeader, setAboutHeader] = useState({});
  const [aboutThumnail, setAboutThumnail] = useState({});

  useEffect(() => {
    const fetchAboutHeader = async () => {
      const res = await fetch(`https://api.chaulanart.com/about/about-header`);
      const data = await res.json();
      setAboutHeader(data);
    };
    const fetchAboutThumnail = async () => {
      const res = await fetch(
        `https://api.chaulanart.com/about/about-thumnail`
      );
      const data = await res.json();
      setAboutThumnail(data);
    };
    fetchAboutHeader();
    fetchAboutThumnail();
    fetch(`https://api.chaulanart.com/header/header-menu`)
      .then((res) => res.json())
      .then((data) => {
        const title = data.find((item) => item.menu_id === 6);
        document.title = `${title.name} - Chau Lan Art `;
      });
  }, []);

  const checkURL = (url) => {
    return /\.(jpeg|jpg|gif|png)$/i.test(url);
  };
  const isValidYoutubeEmbedUrl = (url) => {
    const pattern = /^https:\/\/www\.youtube\.com\/embed\/.+/i;
    return pattern.test(url);
  };

  const pageDescription = aboutThumnail.description;
  const baseUrl = 'https://chaulanart.com';
  const pageUrl = `${baseUrl}/about`;
  const imageUrl = aboutThumnail.thumnail;

  return (
    <>
      <Helmet>
        <meta
          property="og:description"
          content={pageDescription}
        />
        <meta
          property="og:url"
          content={pageUrl}
        />
        <meta
          property="og:image"
          content={imageUrl}
        />
      </Helmet>
      <section className="about">
        <section className="about-banner">
          {checkURL(aboutHeader.image) ? (
            <img
              src={aboutHeader.image}
              title="Uploaded Image"
              alt="Uploaded"
              className="about-banner-imgae"
            />
          ) : isValidYoutubeEmbedUrl(aboutHeader.image) ? (
            <iframe
              src={aboutHeader.image}
              title="Uploaded Video"
              alt="Uploaded"
              className="about-banner-imgae"
            />
          ) : (
            <iframe
              src={aboutHeader.image}
              title="Uploaded Video"
              alt="Uploaded"
              className="about-banner-imgae"
            />
          )}
        </section>
        <div className="container">
          <section className="about-describe">
            <p
              className="about-describe-content"
              dangerouslySetInnerHTML={{ __html: aboutHeader.description }}
            />
          </section>
          <AboutAward></AboutAward>
          <AboutStory></AboutStory>
          <AboutEducation></AboutEducation>
          <AboutExperiences></AboutExperiences>
          <AboutResume></AboutResume>
        </div>
      </section>
    </>
  );
};

export default About;
