import React from 'react';
import WorkList from '../WorkList/WorkList';
import Model from '../Model3d/Model';

const Home = () => {
  return (
    <section className="Home">
      <Model></Model>
      <WorkList></WorkList>
    </section>
  );
};

export default Home;
