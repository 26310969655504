import { Canvas, useFrame } from '@react-three/fiber';
import React, { useRef, useEffect } from 'react';
import {
  useGLTF,
  PerspectiveCamera,
  useAnimations,
  OrbitControls,
} from '@react-three/drei';
import './Model.css';

export function Scene(props) {
  const group = useRef();
  const { nodes, materials, animations } = useGLTF('../../../Model3d.glb');
  const { actions } = useAnimations(animations, group);

  useFrame(({ clock }) => {
    group.current.rotation.y = clock.getElapsedTime() * 0.3;
  });

  useEffect(() => {
    // Automatically play all animations
    Object.keys(actions).forEach((key) => {
      actions[key].play();
    });
  }, [actions]);

  return (
    <group
      ref={group}
      {...props}
      dispose={null}>
      <group
        name="Scene_anim_motion_robot_and_circle_anim"
        position={[0, -0.5, 0]}>
        <group
          name="Camera004"
          position={[-5.662, 1.397, -0.012]}
          rotation={[1.571, -0.126, 1.572]}>
          <PerspectiveCamera
            name="Camera004_Orientation"
            makeDefault={false}
            far={100}
            near={0.1}
            fov={22.895}
            rotation={[-Math.PI / 2, 0, 0]}
          />
        </group>
        <group
          name="Armature014"
          position={[0.019, 0.522, -0.077]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={0.01}>
          <primitive object={nodes.mixamorigHips} />
          <group name="Cube004">
            <skinnedMesh
              name="Cube003"
              geometry={nodes.Cube003.geometry}
              material={materials['Material.026']}
              skeleton={nodes.Cube003.skeleton}
            />
            <skinnedMesh
              name="Cube003_1"
              geometry={nodes.Cube003_1.geometry}
              material={materials['Material.027']}
              skeleton={nodes.Cube003_1.skeleton}
            />
            <skinnedMesh
              name="Cube003_2"
              geometry={nodes.Cube003_2.geometry}
              material={materials['Red light eye']}
              skeleton={nodes.Cube003_2.skeleton}
            />
            <skinnedMesh
              name="Cube003_3"
              geometry={nodes.Cube003_3.geometry}
              material={materials['Red dark.003']}
              skeleton={nodes.Cube003_3.skeleton}
            />
            <skinnedMesh
              name="Cube003_4"
              geometry={nodes.Cube003_4.geometry}
              material={materials['Silver dark.003']}
              skeleton={nodes.Cube003_4.skeleton}
            />
            <skinnedMesh
              name="Cube003_5"
              geometry={nodes.Cube003_5.geometry}
              material={materials['Red light.006']}
              skeleton={nodes.Cube003_5.skeleton}
            />
          </group>
        </group>
        <group
          name="Light056"
          position={[-0.107, 2.254, -0.14]}
          rotation={[1.89, 0.881, -2.045]}>
          <pointLight
            name="Light056_Orientation"
            intensity={10}
            decay={2}
            color="#ff9ea1"
            rotation={[-Math.PI / 2, 0, 0]}
          />
        </group>
        <group
          name="Light057"
          position={[-1.241, 0.851, -0.733]}
          rotation={[1.89, 0.881, -2.045]}>
          <pointLight
            name="Light057_Orientation"
            intensity={60}
            decay={2}
            color="#ff9ea1"
            rotation={[-Math.PI / 2, 0, 0]}
          />
        </group>
        <group
          name="Light058"
          position={[-0.443, 0.851, 1.092]}
          rotation={[1.89, 0.881, -2.045]}>
          <pointLight
            name="Light058_Orientation"
            intensity={30}
            decay={2}
            color="#ff9ea1"
            rotation={[-Math.PI / 2, 0, 0]}
          />
        </group>
        <group
          name="Light059"
          position={[-1.016, 2.548, 1.429]}
          rotation={[1.89, 0.881, -2.045]}>
          <pointLight
            name="Light059_Orientation"
            intensity={20}
            decay={2}
            color="#ff9ea1"
            rotation={[-Math.PI / 2, 0, 0]}
          />
        </group>
        <group
          name="Light060"
          position={[-1.182, 0.428, 0.729]}
          rotation={[1.89, 0.881, -2.045]}>
          <pointLight
            name="Light060_Orientation"
            intensity={20}
            decay={2}
            color="#ff9ea1"
            rotation={[-Math.PI / 2, 0, 0]}
          />
        </group>
        <group
          name="Light061"
          position={[-1.016, -0.138, -1.264]}
          rotation={[1.89, 0.881, -2.045]}>
          <pointLight
            name="Light061_Orientation"
            intensity={20}
            decay={2}
            color="#ff9ea1"
            rotation={[-Math.PI / 2, 0, 0]}
          />
        </group>
        <group
          name="Light062"
          position={[0.995, 0.851, -0.799]}
          rotation={[1.89, 0.881, -2.045]}>
          <pointLight
            name="Light062_Orientation"
            intensity={10}
            decay={2}
            color="#ff9ea1"
            rotation={[-Math.PI / 2, 0, 0]}
          />
        </group>
        <group
          name="Light063"
          position={[1.37, 0.851, 1.092]}
          rotation={[1.89, 0.881, -2.045]}>
          <pointLight
            name="Light063_Orientation"
            intensity={30}
            decay={2}
            color="#ff9ea1"
            rotation={[-Math.PI / 2, 0, 0]}
          />
        </group>
        <group
          name="Light064"
          position={[0.797, 2.548, 1.429]}
          rotation={[1.89, 0.881, -2.045]}>
          <pointLight
            name="Light064_Orientation"
            intensity={20}
            decay={2}
            color="#ff9ea1"
            rotation={[-Math.PI / 2, 0, 0]}
          />
        </group>
        <group
          name="Light065"
          position={[0.916, 0.428, 0.557]}
          rotation={[1.89, 0.881, -2.045]}>
          <pointLight
            name="Light065_Orientation"
            intensity={20}
            decay={2}
            color="#ff9ea1"
            rotation={[-Math.PI / 2, 0, 0]}
          />
        </group>
        <group
          name="Light066"
          position={[0.797, -0.138, -1.264]}
          rotation={[1.89, 0.881, -2.045]}>
          <pointLight
            name="Light066_Orientation"
            intensity={20}
            decay={2}
            color="#ff9ea1"
            rotation={[-Math.PI / 2, 0, 0]}
          />
        </group>
        <group
          name="Light067"
          position={[0.203, 0.849, 0.95]}
          rotation={[1.89, 0.881, -2.045]}>
          <pointLight
            name="Light067_Orientation"
            intensity={1}
            decay={2}
            color="#ff9ea1"
            rotation={[-Math.PI / 2, 0, 0]}
          />
        </group>
        <group
          name="Light068"
          position={[1.366, 0.851, 0.086]}
          rotation={[1.89, 0.881, -2.045]}>
          <pointLight
            name="Light068_Orientation"
            intensity={60}
            decay={2}
            color="#ff9ea1"
            rotation={[-Math.PI / 2, 0, 0]}
          />
        </group>
        <group
          name="Light069"
          position={[-0.022, -0.581, -0.635]}
          rotation={[1.89, 0.881, -2.045]}>
          <pointLight
            name="Light069_Orientation"
            intensity={1}
            decay={2}
            color="#ff9ea1"
            rotation={[-Math.PI / 2, 0, 0]}
          />
        </group>
        <group
          name="Armature015"
          position={[0.169, 1.548, 0.941]}
          scale={0.172}>
          <primitive object={nodes.Bone} />
          <skinnedMesh
            name="Circle057"
            geometry={nodes.Circle057.geometry}
            material={materials['Material.007']}
            skeleton={nodes.Circle057.skeleton}
          />
        </group>
        <group
          name="Armature016"
          position={[0.169, 1.318, 0.426]}
          scale={0.172}>
          <primitive object={nodes.Bone_1} />
          <skinnedMesh
            name="Circle047"
            geometry={nodes.Circle047.geometry}
            material={materials.S1}
            skeleton={nodes.Circle047.skeleton}
          />
        </group>
        <group
          name="Armature017"
          position={[0.169, 0.902, 0.772]}
          scale={0.172}>
          <primitive object={nodes.Bone_2} />
          <skinnedMesh
            name="Circle058"
            geometry={nodes.Circle058.geometry}
            material={materials['Red light.001']}
            skeleton={nodes.Circle058.skeleton}
          />
        </group>
        <group
          name="Armature018"
          position={[-0.493, 0.378, 1.015]}
          scale={0.172}>
          <primitive object={nodes.Bone_3} />
          <skinnedMesh
            name="Circle050"
            geometry={nodes.Circle050.geometry}
            material={materials['Material.004']}
            skeleton={nodes.Circle050.skeleton}
          />
        </group>
        <group
          name="Armature019"
          position={[0.523, 0.556, 0.435]}
          scale={0.172}>
          <primitive object={nodes.Bone_4} />
          <skinnedMesh
            name="Circle051"
            geometry={nodes.Circle051.geometry}
            material={materials.S2}
            skeleton={nodes.Circle051.skeleton}
          />
        </group>
        <group
          name="Armature020"
          position={[0.169, 0.049, 0.227]}
          scale={0.172}>
          <primitive object={nodes.Bone_5} />
          <skinnedMesh
            name="Circle053"
            geometry={nodes.Circle053.geometry}
            material={materials.S1}
            skeleton={nodes.Circle053.skeleton}
          />
        </group>
        <group
          name="Armature021"
          position={[0.169, 0.534, -1.029]}
          scale={0.172}>
          <primitive object={nodes.Bone_6} />
          <skinnedMesh
            name="Circle049"
            geometry={nodes.Circle049.geometry}
            material={materials['S 3']}
            skeleton={nodes.Circle049.skeleton}
          />
        </group>
        <group
          name="Armature022"
          position={[0.432, 0.132, -1.103]}
          scale={0.172}>
          <primitive object={nodes.Bone_7} />
          <skinnedMesh
            name="Circle055"
            geometry={nodes.Circle055.geometry}
            material={materials.S2}
            skeleton={nodes.Circle055.skeleton}
          />
        </group>
        <group
          name="Armature023"
          position={[0.496, 1.011, -0.977]}
          scale={0.172}>
          <primitive object={nodes.Bone_8} />
          <skinnedMesh
            name="Circle054"
            geometry={nodes.Circle054.geometry}
            material={materials.S1}
            skeleton={nodes.Circle054.skeleton}
          />
        </group>
        <group
          name="Armature024"
          position={[0.169, 1.448, -0.657]}
          scale={0.172}>
          <primitive object={nodes.Bone_9} />
          <skinnedMesh
            name="Circle048"
            geometry={nodes.Circle048.geometry}
            material={materials.S2}
            skeleton={nodes.Circle048.skeleton}
          />
        </group>
        <group
          name="Armature025"
          position={[0.169, -0.185, 0.963]}
          scale={0.172}>
          <primitive object={nodes.Bone_10} />
          <skinnedMesh
            name="Circle056"
            geometry={nodes.Circle056.geometry}
            material={materials.S2}
            skeleton={nodes.Circle056.skeleton}
          />
        </group>
        <group
          name="Armature026"
          position={[0.169, 1.608, 0.955]}>
          <group name="Bone_11" />
        </group>
        <group
          name="Armature038"
          position={[0.148, 1.606, -0.151]}
          scale={0.081}>
          <primitive object={nodes.Bone_12} />
          <skinnedMesh
            name="Circle052"
            geometry={nodes.Circle052.geometry}
            material={materials['Material.007']}
            skeleton={nodes.Circle052.skeleton}
          />
        </group>
      </group>
    </group>
  );
}

const Model = () => {
  const [hovered, setHovered] = React.useState(true);
  useEffect(() => {
    if (window.innerHeight < 450) {
      setHovered(false);
    }
  }, []);

  return (
    <div className="model-banner">
      <Canvas>
        <Scene />
        <PerspectiveCamera
          makeDefault
          position={[2, 2, 7]}
          far={100}
          near={0.1}
          fov={22.895}
        />
        <OrbitControls
          enableZoom={false}
          enableRotate={hovered}
          enablePan={true}
          enableDamping={true}
        />
      </Canvas>
    </div>
  );
};

export default Model;
