import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import './Expertise.css';

const Expertise = () => {
  const [expertiseHeading, setExpertiseHeading] = useState({});
  const [expertise, setExpertise] = useState([]);
  const [process, setProcess] = useState([]);
  const [processHeading, setProcessHeading] = useState({});
  const [experThumnail, setExperThumnail] = useState({});
  useEffect(() => {
    const handleScroll = () => {
      const processItems = document.querySelectorAll('.expertise-process-item');
      const process = document.querySelector('.expertise-process');
      processItems.forEach((item) => {
        const windowScrollTop = window.scrollY;
        if (windowScrollTop > process.offsetTop) {
          item.classList.add('active');
        }
      });
    };

    window.addEventListener('scroll', handleScroll);

    // Cleanup function to remove event listener
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    fetch(`https://api.chaulanart.com/expertise-page/get-expertise`)
      .then((res) => res.json())
      .then((data) => setExpertise(data));
    fetch(`https://api.chaulanart.com/expertise-page/get-expertise-heading`)
      .then((res) => res.json())
      .then((data) => setExpertiseHeading(data));
    fetch(`https://api.chaulanart.com/expertise-page/get-working-process`)
      .then((res) => res.json())
      .then((data) => setProcess(data));
    fetch(`https://api.chaulanart.com/expertise-page/get-expertise-thumnail`)
      .then((res) => res.json())
      .then((data) => setExperThumnail(data));
    fetch(
      `https://api.chaulanart.com/expertise-page/get-working-process-heading`
    )
      .then((res) => res.json())
      .then((data) => setProcessHeading(data));
    fetch(`https://api.chaulanart.com/header/header-menu`)
      .then((res) => res.json())
      .then((data) => {
        const title = data.find((item) => item.menu_id === 2);
        document.title = `${title.name} - Chau Lan Art `;
      });
  }, []);

  const pageDescription = experThumnail.description;
  const baseUrl = 'https://chaulanart.com';
  const pageUrl = `${baseUrl}/epertise`;
  const imageUrl = experThumnail.thumnail;

  return (
    <>
      <Helmet>
        <meta
          property="og:description"
          content={pageDescription}
        />
        <meta
          property="og:url"
          content={pageUrl || baseUrl}
        />
        <meta
          property="og:image"
          content={imageUrl}
        />
      </Helmet>
      <section className="expertise">
        <div className="container">
          <h2
            className="expertise-heading"
            dangerouslySetInnerHTML={{ __html: expertiseHeading.heading }}
          />
          <section className="expertise-solution">
            {expertise.map((item) => (
              <div className="expertie-solution-item">
                <h3
                  className="expertie-solution-title"
                  dangerouslySetInnerHTML={{ __html: item.heading }}
                />
                <div className="expertie-solution-info">
                  <p
                    className="expertie-solution-content"
                    dangerouslySetInnerHTML={{ __html: item.content }}
                  />
                </div>
              </div>
            ))}
          </section>
          <section className="expertise-process">
            <h2
              className="expertise-process-heading"
              dangerouslySetInnerHTML={{ __html: processHeading.heading }}
            />
            <div className="expertise-process-list">
              {process.map((item, index) => (
                <div className={`expertise-process-item item-${index + 1}`}>
                  <div className="expertise-process-cricle">
                    <h3
                      className="expertise-process-name"
                      dangerouslySetInnerHTML={{ __html: item.heading }}
                    />
                  </div>
                  <p
                    className="expertise-process-info"
                    dangerouslySetInnerHTML={{ __html: item.content }}
                  />
                </div>
              ))}
            </div>
          </section>
        </div>
      </section>
    </>
  );
};

export default Expertise;
