import React, { useEffect, useState } from 'react';

const ContactHome = () => {
  const [courseContact, setCourseContact] = useState([]);

  useEffect(() => {
    const fetchProjects = async () => {
      const res = await fetch(`https://api.chaulanart.com/contact/home`);
      const data = await res.json();

      setCourseContact(data);
    };

    fetchProjects();
  }, []);

  return (
    <div class="recent-orders">
      <h2>Danh sách đăng ký tư vấn</h2>
      <table>
        <thead>
          <tr>
            <th>Họ tên</th>
            <th>Số điện thoại</th>
            <th>Email</th>
          </tr>
        </thead>
        <tbody>
          {courseContact.map((project) => (
            <tr>
              <td>{project.name}</td>
              <td>{project.phone}</td>
              <td>{project.email}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ContactHome;
