import React, { useCallback, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import FormAddCourseHeader from './FormAddCourseHeader';
import FormEditCourseHeader from './FormEditCourseHeader';
import FormAddCourseContent from './FormAddCourseContent';
import FormEditCourseContent from './FormEditCourseContent';
import FormAddCourseQuestion from './FormAddCourseQuestion';
import FormEditCourseQuestion from './FormEditCourseQuestion';
import FormEditCourseContentHeading from './FormEditCourseContentHeading';
import FormEditCourseQuestionHeading from './FormEditCourseQuestionHeading';
import FormEditCourseStudentHeading from './FormEditCourseStudentHeading';
import FormEditCourseFeedbackHeading from './FormEditCourseFeedbackHeading';

const CourseDetail = () => {
  const [refreshKey, setRefreshKey] = useState(0);
  const handleRefresh = useCallback(
    () => setRefreshKey(refreshKey + 1),
    [refreshKey]
  );
  const [courseHeader, setCourseHeader] = useState({});
  const [courseContent, setCourseContent] = useState([]);
  const [courseQuestion, setCourseQuestion] = useState([]);
  const [courseContentHeading, setCourseContentHeading] = useState({});
  const [courseQuestionHeading, setCourseQuestionHeading] = useState({});
  const [courseStudentHeading, setCourseStudentHeading] = useState({});
  const [courseFeedbackHeading, setCourseFeedbackHeading] = useState({});
  const [showFormAddHeader, setShowFormAddHeader] = useState(false);
  const [showFormEditHeader, setShowFormEditHeader] = useState(false);
  const [showFormAddContent, setShowFormAddContent] = useState(false);
  const [showFormEditContent, setShowFormEditContent] = useState(false);
  const [showFormAddQuestion, setShowFormAddQuestion] = useState(false);
  const [showFormEditQuestion, setShowFormEditQuestion] = useState(false);
  const [
    showFormEditCourseContentHeading,
    setShowFormEditCourseContentHeading,
  ] = useState(false);
  const [
    showFormEditCourseQuestionHeading,
    setShowFormEditCourseQuestionHeading,
  ] = useState(false);
  const [showFormEditStudentHeading, setShowFormEditStudentHeading] =
    useState(false);
  const [showFormEditFeedbackHeading, setShowFormEditFeedbackHeading] =
    useState(false);
  const [courseContentItem, setCourseContentItem] = useState('');
  const [courseQuestionItem, setCourseQuestionItem] = useState('');
  let { id } = useParams();

  const handleOpenFormAddHeader = () => {
    setShowFormAddHeader(true);
  };
  const handleOpenFormEditHeader = () => {
    setShowFormEditHeader(true);
  };
  const handleOpenFormAddContent = () => {
    setShowFormAddContent(true);
  };
  const handleOpenFormEditContent = (item) => {
    setShowFormEditContent(true);
    setCourseContentItem(item);
  };
  const handleOpenFormAddQuestion = () => {
    setShowFormAddQuestion(true);
  };
  const handleOpenFormEditQuestion = (item) => {
    setShowFormEditQuestion(true);
    setCourseQuestionItem(item);
  };

  const handleCloseFormAddHeader = () => {
    setShowFormAddHeader(false);
  };
  const handleCloseFormEditHeader = () => {
    setShowFormEditHeader(false);
  };
  const handleCloseFormAddContent = () => {
    setShowFormAddContent(false);
  };
  const handleCloseFormEditContent = () => {
    setShowFormEditContent(false);
  };
  const handleCloseFormAddQuestion = () => {
    setShowFormAddQuestion(false);
  };
  const handleCloseFormEditQuestion = () => {
    setShowFormEditQuestion(false);
  };

  const handleOpenCourseContentHeading = () => {
    setShowFormEditCourseContentHeading(true);
  };
  const handleCloseCourseContentHeading = () => {
    setShowFormEditCourseContentHeading(false);
  };

  const handleOpenCourseQuestionHeading = () => {
    setShowFormEditCourseQuestionHeading(true);
  };
  const handleCloseCourseQuestionHeading = () => {
    setShowFormEditCourseQuestionHeading(false);
  };

  const handleOpenCourseStudentHeading = () => {
    setShowFormEditStudentHeading(true);
  };
  const handleCloseCourseStudentHeading = () => {
    setShowFormEditStudentHeading(false);
  };

  const handleOpenCourseFeedbackHeading = () => {
    setShowFormEditFeedbackHeading(true);
  };
  const handleCloseCourseFeedbackHeading = () => {
    setShowFormEditFeedbackHeading(false);
  };

  useEffect(() => {
    const fetchAboutHeader = async () => {
      const res = await fetch(
        `https://api.chaulanart.com/course/course-header/${id}`
      );
      const text = await res.text();
      const data = text ? JSON.parse(text) : {};
      setCourseHeader(data);
    };
    const fetchCourseContent = async () => {
      const res = await fetch(
        `https://api.chaulanart.com/course/course-content/${id}`
      );
      const text = await res.text();
      const data = text ? JSON.parse(text) : {};
      setCourseContent(data);
    };
    const fetchCourseQuestion = async () => {
      const res = await fetch(
        `https://api.chaulanart.com/course/course-question/${id}`
      );
      const text = await res.text();
      const data = text ? JSON.parse(text) : {};
      setCourseQuestion(data);
    };
    const fetchCourseContentHeading = async () => {
      const res = await fetch(
        `https://api.chaulanart.com/course/course-content-heading`
      );
      const text = await res.text();
      const data = text ? JSON.parse(text) : {};
      setCourseContentHeading(data);
    };
    const fetchCourseQuestionHeading = async () => {
      const res = await fetch(
        `https://api.chaulanart.com/course/course-question-heading`
      );
      const text = await res.text();
      const data = text ? JSON.parse(text) : {};
      setCourseQuestionHeading(data);
    };
    const fetchCourseStudentHeading = async () => {
      const res = await fetch(
        `https://api.chaulanart.com/course/course-student-heading`
      );
      const text = await res.text();
      const data = text ? JSON.parse(text) : {};
      setCourseStudentHeading(data);
    };
    const fetchCourseFeedbackHeading = async () => {
      const res = await fetch(
        `https://api.chaulanart.com/course/course-feedback-heading`
      );
      const text = await res.text();
      const data = text ? JSON.parse(text) : {};
      setCourseFeedbackHeading(data);
    };
    fetchAboutHeader();
    fetchCourseContent();
    fetchCourseQuestion();
    fetchCourseContentHeading();
    fetchCourseQuestionHeading();
    fetchCourseStudentHeading();
    fetchCourseFeedbackHeading();
    setShowFormAddHeader(false);
    setShowFormEditHeader(false);
    setShowFormAddContent(false);
    setShowFormEditContent(false);
    setShowFormAddQuestion(false);
    setShowFormEditQuestion(false);
    setShowFormEditCourseContentHeading(false);
    setShowFormEditCourseQuestionHeading(false);
    setShowFormEditStudentHeading(false);
    setShowFormEditFeedbackHeading(false);
  }, [id, refreshKey]);

  const deleteContent = (id_tag) => {
    if (window.confirm('Bạn muốn xóa tag này không?')) {
      fetch(
        `https://api.chaulanart.com/course/delete-course-content/${id_tag}`,
        {
          method: 'DELETE',
        }
      )
        .then((res) => res.json())
        .then(() => {
          // Fetch the list of projects again after a project has been deleted
          const fetchProjects = async () => {
            const res = await fetch(
              `https://api.chaulanart.com/course/course-content/${id}`
            );
            const data = await res.json();
            setCourseContent(data);
          };
          setRefreshKey((prevKey) => prevKey + 1);
          fetchProjects();
        })
        .catch((error) => console.error('Lỗi xóa:', error));
    }
  };
  const deleteQuestion = (id_tag) => {
    if (window.confirm('Bạn muốn xóa tag này không?')) {
      fetch(
        `https://api.chaulanart.com/course/delete-course-question/${id_tag}`,
        {
          method: 'DELETE',
        }
      )
        .then((res) => res.json())
        .then(() => {
          // Fetch the list of projects again after a project has been deleted
          const fetchProjects = async () => {
            const res = await fetch(
              `https://api.chaulanart.com/course/course-question/${id}`
            );
            const data = await res.json();
            setCourseQuestion(data);
          };
          setRefreshKey((prevKey) => prevKey + 1);
          fetchProjects();
        })
        .catch((error) => console.error('Lỗi xóa:', error));
    }
  };

  const checkURL = (url) => {
    return /\.(jpeg|jpg|gif|png)$/i.test(url);
  };
  const isValidYoutubeEmbedUrl = (url) => {
    const pattern = /^https:\/\/www\.youtube\.com\/embed\/.+/i;
    return pattern.test(url);
  };

  return (
    <div className="podcastPage">
      <h1>Chi tiết khóa học</h1>
      {showFormAddHeader && (
        <>
          <FormAddCourseHeader
            idCourse={id}
            onRefresh={handleRefresh}
          />
          <div
            className="project-detail-add"
            onClick={handleCloseFormAddHeader}>
            <span>Đóng</span>
          </div>
        </>
      )}
      {showFormEditHeader && (
        <>
          <FormEditCourseHeader
            idCourse={id}
            onRefresh={handleRefresh}
            courseHeader={courseHeader}
          />
          <div
            className="project-detail-add"
            onClick={handleCloseFormEditHeader}>
            <span>Đóng</span>
          </div>
        </>
      )}
      {!courseHeader || Object.keys(courseHeader).length === 0 ? (
        <div className="podcastPage-header">
          <div className="podcastPage-info-item">
            <p>Chưa có thông tin</p>
          </div>
        </div>
      ) : (
        <div className="podcastPage-header">
          <div className="podcastPage-header-item item-course">
            <label>Hình minh họa</label>
            {checkURL(courseHeader.image) ? (
              <img
                src={courseHeader.image}
                title="Uploaded Image"
                alt="Uploaded"
                className="project-image"
              />
            ) : isValidYoutubeEmbedUrl(courseHeader.image) ? (
              <iframe
                src={courseHeader.image}
                title="Uploaded Video"
                alt="Uploaded"
                className="project-image"
              />
            ) : (
              <iframe
                src={courseHeader.image}
                title="Uploaded Video"
                alt="Uploaded"
                className="project-image"
              />
            )}
          </div>
          <div className="podcastPage-info-item">
            <label>Tiêu đề:</label>
            <p>
              <div dangerouslySetInnerHTML={{ __html: courseHeader.heading }} />
            </p>
          </div>
          <div className="podcastPage-header-item">
            <label>Mô tả</label>
            <p>
              <div
                dangerouslySetInnerHTML={{ __html: courseHeader.description }}
              />
            </p>
          </div>

          <div className="podcastPage-info-item">
            <label>Thông tin</label>
            <p>
              <div
                dangerouslySetInnerHTML={{ __html: courseHeader.infomation }}
              />
            </p>
          </div>
        </div>
      )}

      {!courseHeader || Object.keys(courseHeader).length === 0 ? (
        <div
          className="project-detail-add"
          onClick={handleOpenFormAddHeader}>
          <span>Thêm thông tin</span>
        </div>
      ) : (
        <div
          className="project-detail-add"
          onClick={handleOpenFormEditHeader}>
          <span>Sửa thông tin</span>
        </div>
      )}
      <h2>Nội dung chương trình học</h2>
      {showFormEditCourseContentHeading && (
        <>
          <FormEditCourseContentHeading
            courseContentHeading={courseContentHeading}
            onRefresh={handleRefresh}
          />
          <div
            className="project-detail-add"
            onClick={handleCloseCourseContentHeading}>
            <span>Đóng</span>
          </div>
        </>
      )}
      <div className="podcastPage-info">
        <div className="podcastPage-info-item">
          <label>Tiêu đề:</label>
          <p
            dangerouslySetInnerHTML={{ __html: courseContentHeading.heading }}
          />
        </div>
      </div>
      <div
        className="project-detail-add"
        onClick={handleOpenCourseContentHeading}>
        <span>Sửa thông tin</span>
      </div>
      {showFormAddContent && (
        <>
          <FormAddCourseContent
            idCourse={id}
            onRefresh={handleRefresh}
          />
          <div
            className="project-detail-add"
            onClick={handleCloseFormAddContent}>
            <span>Đóng</span>
          </div>
        </>
      )}
      {showFormEditContent && (
        <>
          <FormEditCourseContent
            idCourse={id}
            onRefresh={handleRefresh}
            courseContent={courseContentItem}
          />
          <div
            className="project-detail-add"
            onClick={handleCloseFormEditContent}>
            <span>Đóng</span>
          </div>
        </>
      )}
      <div className="workpage-tags">
        {courseContent && courseContent.length > 0 ? (
          courseContent.map((item) => (
            <div className="workpage-tags-item">
              <span>
                <div dangerouslySetInnerHTML={{ __html: item.day_number }} />
              </span>
              <p>
                <div dangerouslySetInnerHTML={{ __html: item.title }} />
              </p>
              <div
                className="workpage-tags-item-delete"
                onClick={() => deleteContent(item.course_content_id)}>
                <i className="fa-solid fa-xmark"></i>
              </div>
              <div
                className="workpage-tags-item-edit"
                onClick={() => handleOpenFormEditContent(item)}>
                <i className="fa-solid fa-pen"></i>
              </div>
            </div>
          ))
        ) : (
          <div className="workpage-tags-item">
            <span>Chưa có tiêu đề</span>
          </div>
        )}
      </div>
      <div
        className="project-detail-add"
        onClick={handleOpenFormAddContent}>
        <span>Thêm buổi học</span>
      </div>
      <h2>Các câu hỏi thường gặp</h2>
      {showFormEditCourseQuestionHeading && (
        <>
          <FormEditCourseQuestionHeading
            courseQuestionHeading={courseQuestionHeading}
            onRefresh={handleRefresh}
          />
          <div
            className="project-detail-add"
            onClick={handleCloseCourseQuestionHeading}>
            <span>Đóng</span>
          </div>
        </>
      )}
      <div className="podcastPage-info">
        <div className="podcastPage-info-item">
          <label>Tiêu đề:</label>
          <p
            dangerouslySetInnerHTML={{ __html: courseQuestionHeading.heading }}
          />
        </div>
      </div>
      <div
        className="project-detail-add"
        onClick={handleOpenCourseQuestionHeading}>
        <span>Sửa thông tin</span>
      </div>
      {showFormAddQuestion && (
        <>
          <FormAddCourseQuestion
            idCourse={id}
            onRefresh={handleRefresh}
          />
          <div
            className="project-detail-add"
            onClick={handleCloseFormAddQuestion}>
            <span>Đóng</span>
          </div>
        </>
      )}
      {showFormEditQuestion && (
        <>
          <FormEditCourseQuestion
            idCourse={id}
            onRefresh={handleRefresh}
            courseQuestion={courseQuestionItem}
          />
          <div
            className="project-detail-add"
            onClick={handleCloseFormEditQuestion}>
            <span>Đóng</span>
          </div>
        </>
      )}
      <div className="workpage-tags">
        {courseQuestion && courseQuestion.length > 0 ? (
          courseQuestion.map((item) => (
            <div className="workpage-tags-item">
              <span>
                <div dangerouslySetInnerHTML={{ __html: item.heading }} />
              </span>
              <div
                className="workpage-tags-item-delete"
                onClick={() => deleteQuestion(item.course_question_id)}>
                <i className="fa-solid fa-xmark"></i>
              </div>
              <div
                className="workpage-tags-item-edit"
                onClick={() => handleOpenFormEditQuestion(item)}>
                <i className="fa-solid fa-pen"></i>
              </div>
            </div>
          ))
        ) : (
          <div className="workpage-tags-item">
            <span>Chưa có tiêu đề</span>
          </div>
        )}
      </div>
      <div
        className="project-detail-add"
        onClick={handleOpenFormAddQuestion}>
        <span>Thêm Câu hỏi</span>
      </div>
      <h2>Sản phẩm của học viên</h2>
      {showFormEditStudentHeading && (
        <>
          <FormEditCourseStudentHeading
            courseStudentHeading={courseStudentHeading}
            onRefresh={handleRefresh}
          />
          <div
            className="project-detail-add"
            onClick={handleCloseCourseStudentHeading}>
            <span>Đóng</span>
          </div>
        </>
      )}
      <div className="podcastPage-info">
        <div className="podcastPage-info-item">
          <label>Tiêu đề:</label>
          <p
            dangerouslySetInnerHTML={{ __html: courseStudentHeading.heading }}
          />
        </div>
      </div>
      <div
        className="project-detail-add"
        onClick={handleOpenCourseStudentHeading}>
        <span>Sửa thông tin</span>
      </div>
      <Link
        to={`/admin/course-student/${id}`}
        className="project-detail-add">
        <span>Danh sách sản phẩm</span>
      </Link>
      <h2>Phản hồi của học viên</h2>
      {showFormEditFeedbackHeading && (
        <>
          <FormEditCourseFeedbackHeading
            courseFeedbackHeading={courseFeedbackHeading}
            onRefresh={handleRefresh}
          />
          <div
            className="project-detail-add"
            onClick={handleCloseCourseFeedbackHeading}>
            <span>Đóng</span>
          </div>
        </>
      )}
      <div className="podcastPage-info">
        <div className="podcastPage-info-item">
          <label>Tiêu đề:</label>
          <p
            dangerouslySetInnerHTML={{ __html: courseFeedbackHeading.heading }}
          />
        </div>
      </div>
      <div
        className="project-detail-add"
        onClick={handleOpenCourseFeedbackHeading}>
        <span>Sửa thông tin</span>
      </div>
      <Link
        to={`/admin/course-feedback/${id}`}
        className="project-detail-add">
        <span>Danh sách phản hồi</span>
      </Link>
    </div>
  );
};

export default CourseDetail;
