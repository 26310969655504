import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import './InfoCourse.css';

const Infocourse = ({ id }) => {
  const [courseHeader, setCourseHeader] = useState({});
  const [courseThumbnail, setCourseThumbnail] = useState({});

  function stripHtml(html) {
    // Temporary element for parsing HTML content
    var tempDivElement = document.createElement('div');
    tempDivElement.innerHTML = html;
    return tempDivElement.textContent || tempDivElement.innerText || '';
  }

  useEffect(() => {
    const fetchAboutHeader = async () => {
      const res = await fetch(
        `https://api.chaulanart.com/course/course-header/${id}`
      );
      const text = await res.text();
      const data = text ? JSON.parse(text) : {};
      setCourseHeader(data);
      const textTitle = stripHtml(courseHeader.heading);
      document.title = `${textTitle} - Chau Lan Art`;
    };
    const fetchCourseThumnaill = async () => {
      const res = await fetch(
        `https://api.chaulanart.com/course/course-cate/${id}`
      );
      const text = await res.text();
      const data = text ? JSON.parse(text) : {};
      setCourseThumbnail(data);
    };
    fetchAboutHeader();
    fetchCourseThumnaill();
  }, [id, courseHeader.heading]);

  const checkURL = (url) => {
    return /\.(jpeg|jpg|gif|png)$/i.test(url);
  };
  const isValidYoutubeEmbedUrl = (url) => {
    const pattern = /^https:\/\/www\.youtube\.com\/embed\/.+/i;
    return pattern.test(url);
  };

  const pageDescription = stripHtml(courseThumbnail.name);
  const baseUrl = 'https://chaulanart.com';
  const pageUrlCBS = `${baseUrl}/course/cbs-art-space/${id}`;
  const pageUrlDPI = `${baseUrl}/course/dpi-center${id}`;
  const imageUrl = courseThumbnail.image;

  return (
    <>
      <Helmet>
        <meta
          property="og:description"
          content={pageDescription}
        />
        <meta
          property="og:url"
          content={pageUrlCBS || pageUrlDPI}
        />
        <meta
          property="og:image"
          content={imageUrl}
        />
      </Helmet>
      <section className="infoCourse">
        <div className="container">
          <div className="infoCourse-wrapper">
            <h3
              className="infoCourse-heading"
              dangerouslySetInnerHTML={{ __html: courseHeader.heading }}
            />
            <div className="infoCourse-list">
              <div className="infoCourse-item">
                <p
                  className="infoCourse-desc"
                  dangerouslySetInnerHTML={{ __html: courseHeader.description }}
                />
              </div>
              <div className="infoCourse-item">
                <div
                  className="infoCourse-info"
                  dangerouslySetInnerHTML={{ __html: courseHeader.infomation }}
                />
              </div>
            </div>
            {checkURL(courseHeader.image) ? (
              <div className="inforCour-image">
                <img
                  src={courseHeader.image}
                  title="Uploaded Image"
                  alt="Uploaded"
                />
              </div>
            ) : isValidYoutubeEmbedUrl(courseHeader.image) ? (
              <div className="inforCour-iframe">
                <iframe
                  src={courseHeader.image}
                  title="Uploaded Video"
                  alt="Uploaded"
                />
              </div>
            ) : (
              <div className="inforCour-image">
                <iframe
                  src={courseHeader.image}
                  title="Uploaded Video"
                  alt="Uploaded"
                />
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default Infocourse;
