/* eslint-disable jsx-a11y/no-distracting-elements */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import './Podcast.css';

const Podcast = () => {
  const [podcastHeader, setPodcastHeader] = useState({});
  const [podcastSocialHeading, setPodcastSocialHeading] = useState({});
  const [podcastInfo, setPodcastInfo] = useState({});
  const [podcastSocial, setPodcastSocial] = useState([]);
  const [podcastMarquee, setPodcastMarquee] = useState([]);
  const [podcastThumanail, setPodcastThumanail] = useState([]);

  useEffect(() => {
    const handleScroll = () => {
      const processItems = document.querySelectorAll('.podcast-author-item');
      const process = document.querySelector('.podcast-author-view');
      processItems.forEach((item) => {
        const windowScrollTop = window.scrollY;
        // Thêm điều kiện về width
        if (window.innerWidth > 1023) {
          // Khi width lớn hơn 1023px
          if (windowScrollTop > process.offsetTop - process.offsetHeight * 4) {
            item.classList.add('active');
          }
        } else {
          // Khi width nhỏ hơn hoặc bằng 1023px
          if (windowScrollTop > process.offsetTop - process.offsetHeight) {
            item.classList.add('active');
          }
        }
      });
    };
    window.addEventListener('scroll', handleScroll);

    // Cleanup function to remove event listener
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const checkURL = (url) => {
    return /\.(jpeg|jpg|gif|png)$/i.test(url);
  };
  const checkMP4 = (url) => {
    return /\.(mp4)$/i.test(url);
  };
  const isValidYoutubeEmbedUrl = (url) => {
    const pattern = /^https:\/\/www\.youtube\.com\/embed\/.+/i;
    return pattern.test(url);
  };

  useEffect(() => {
    const fetchPodcastHeader = async () => {
      const res = await fetch(
        `https://api.chaulanart.com/podcast/podcast-header`
      );
      const data = await res.json();
      setPodcastHeader(data);
    };
    const fetchPodcastSocialHeading = async () => {
      const res = await fetch(
        `https://api.chaulanart.com/podcast/podcast-social-heading`
      );
      const data = await res.json();
      setPodcastSocialHeading(data);
    };
    const fetchPodcastSocial = async () => {
      const res = await fetch(
        `https://api.chaulanart.com/podcast/podcast-social`
      );
      const data = await res.json();
      setPodcastSocial(data);
    };
    const fetchPodcastMarquee = async () => {
      const res = await fetch(
        `https://api.chaulanart.com/podcast/podcast-marquee`
      );
      const data = await res.json();
      setPodcastMarquee(data);
    };
    const fetchPodcastInfo = async () => {
      const res = await fetch(
        `https://api.chaulanart.com/podcast/podcast-info`
      );
      const data = await res.json();
      setPodcastInfo(data);
    };
    const fetchPodcastThumnail = async () => {
      const res = await fetch(
        `https://api.chaulanart.com/podcast/podcast-thumnail`
      );
      const data = await res.json();
      setPodcastThumanail(data);
    };

    fetchPodcastHeader();
    fetchPodcastThumnail();
    fetchPodcastSocialHeading();
    fetchPodcastSocial();
    fetchPodcastMarquee();
    fetchPodcastInfo();

    fetch(`https://api.chaulanart.com/header/header-menu`)
      .then((res) => res.json())
      .then((data) => {
        const title = data.find((item) => item.menu_id === 4);
        document.title = `${title.name} - Chau Lan Art `;
      });
  }, []);

  function stripHtml(html) {
    // Temporary element for parsing HTML content
    var tempDivElement = document.createElement('div');
    tempDivElement.innerHTML = html;
    return tempDivElement.textContent || tempDivElement.innerText || '';
  }

  const pageDescription = podcastThumanail.description;
  const baseUrl = 'https://chaulanart.com';
  const pageUrl = `${baseUrl}/podcast`;
  const imageUrl = podcastThumanail.thumnail;

  return (
    <>
      <Helmet>
        <meta
          property="og:description"
          content={pageDescription}
        />
        <meta
          property="og:url"
          content={pageUrl}
        />
        <meta
          property="og:image"
          content={imageUrl}
        />
      </Helmet>
      <section className="podcast">
        <div className="container">
          <div className="podcast-banner">
            {checkURL(podcastHeader.banner) ? (
              <img
                src={podcastHeader.banner}
                title="Uploaded Image"
                alt="Uploaded"
                className="podcast-banner-image"
              />
            ) : isValidYoutubeEmbedUrl(podcastHeader.banner) ? (
              <iframe
                src={podcastHeader.banner}
                title="Uploaded Video"
                alt="Uploaded"
                className="podcast-banner-image"
              />
            ) : (
              <iframe
                src={podcastHeader.banner}
                title="Uploaded Video"
                alt="Uploaded"
                className="podcast-banner-image"
              />
            )}
          </div>
          <p
            className="podcast-desc"
            dangerouslySetInnerHTML={{ __html: podcastHeader.description }}
          />
          <section className="podcast-author">
            <div className="podcast-author-top">
              <h3
                className="podcast-author-heading"
                dangerouslySetInnerHTML={{
                  __html: podcastSocialHeading.heading,
                }}
              />
            </div>
            <div className="podcast-author-view">
              {podcastSocial &&
                podcastSocial.length > 0 &&
                podcastSocial.map((item, index) => (
                  <Link
                    to={item.link_social}
                    target="_blank"
                    className={`podcast-author-item item-${index + 1}`}>
                    {checkURL(item.social) ? (
                      <img
                        src={item.social}
                        title="Uploaded Image"
                        alt="Uploaded"
                      />
                    ) : isValidYoutubeEmbedUrl(item.social) ? (
                      <iframe
                        src={item.social}
                        title="Uploaded Video"
                        alt="Uploaded"
                      />
                    ) : (
                      <iframe
                        src={item.social}
                        title="Uploaded Video"
                        alt="Uploaded"
                      />
                    )}
                  </Link>
                ))}
            </div>
          </section>
          <section className="podcast-marquee">
            {podcastMarquee &&
              podcastMarquee.length > 0 &&
              podcastMarquee.map((item) => (
                <span className="podcast-marquee-text">
                  {stripHtml(item.heading)}&nbsp;&nbsp;
                </span>
              ))}
          </section>
          <section className="podcast-info">
            <div className="podcast-info-list">
              <Link
                // to={podcastInfo.link}
                // target="_blank"
                className="podcast-info-main">
                {checkURL(podcastInfo.image_main) ? (
                  <div className="podcast-info-main-image">
                    <img
                      src={podcastInfo.image_main}
                      title="Uploaded Image"
                      alt="Uploaded"
                    />
                  </div>
                ) : isValidYoutubeEmbedUrl(podcastInfo.image_main) ? (
                  <div className="podcast-info-main-image">
                    <iframe
                      src={podcastInfo.image_main}
                      title="Uploaded Video"
                      alt="Uploaded"
                    />
                  </div>
                ) : (
                  <div className="podcast-info-main-image">
                    <iframe
                      src={podcastInfo.image_main}
                      title="Uploaded Video"
                      alt="Uploaded"
                    />
                  </div>
                )}
              </Link>
              <div className="podcast-info-gid">
                <div
                  className="podcast-info-gid-item"
                  style={{ backgroundColor: podcastInfo.color_1 }}>
                  {checkURL(podcastInfo.image_1) ? (
                    <img
                      src={podcastInfo.image_1}
                      title="Uploaded Image"
                      alt="Uploaded"
                    />
                  ) : isValidYoutubeEmbedUrl(podcastInfo.image_1) ? (
                    <iframe
                      src={podcastInfo.image_1}
                      title="Uploaded Video"
                      alt="Uploaded"
                    />
                  ) : checkMP4(podcastInfo.image_1) ? (
                    <iframe
                      src={podcastInfo.image_1}
                      title="Uploaded Video"
                      alt="Uploaded"
                    />
                  ) : (
                    <span
                      className="podcast-info-gid-text"
                      dangerouslySetInnerHTML={{ __html: podcastInfo.image_1 }}
                    />
                  )}
                </div>
                <div
                  className="podcast-info-gid-item"
                  style={{ backgroundColor: podcastInfo.color_2 }}>
                  {checkURL(podcastInfo.image_2) ? (
                    <img
                      src={podcastInfo.image_2}
                      title="Uploaded Image"
                      alt="Uploaded"
                    />
                  ) : isValidYoutubeEmbedUrl(podcastInfo.image_2) ? (
                    <iframe
                      src={podcastInfo.image_2}
                      title="Uploaded Video"
                      alt="Uploaded"
                    />
                  ) : checkMP4(podcastInfo.image_2) ? (
                    <iframe
                      src={podcastInfo.image_2}
                      title="Uploaded Video"
                      alt="Uploaded"
                    />
                  ) : (
                    <span
                      className="podcast-info-gid-text"
                      dangerouslySetInnerHTML={{ __html: podcastInfo.image_2 }}
                    />
                  )}
                </div>
                <div
                  className="podcast-info-gid-item"
                  style={{ backgroundColor: podcastInfo.color_3 }}>
                  {checkURL(podcastInfo.image_3) ? (
                    <img
                      src={podcastInfo.image_3}
                      title="Uploaded Image"
                      alt="Uploaded"
                    />
                  ) : isValidYoutubeEmbedUrl(podcastInfo.image_3) ? (
                    <iframe
                      src={podcastInfo.image_3}
                      title="Uploaded Video"
                      alt="Uploaded"
                    />
                  ) : checkMP4(podcastInfo.image_3) ? (
                    <iframe
                      src={podcastInfo.image_3}
                      title="Uploaded Video"
                      alt="Uploaded"
                    />
                  ) : (
                    <span
                      className="podcast-info-gid-text"
                      dangerouslySetInnerHTML={{ __html: podcastInfo.image_3 }}
                    />
                  )}
                </div>
                <div
                  className="podcast-info-gid-item"
                  style={{ backgroundColor: podcastInfo.color_4 }}>
                  {checkURL(podcastInfo.image_4) ? (
                    <img
                      src={podcastInfo.image_4}
                      title="Uploaded Image"
                      alt="Uploaded"
                    />
                  ) : isValidYoutubeEmbedUrl(podcastInfo.image_4) ? (
                    <iframe
                      src={podcastInfo.image_4}
                      title="Uploaded Video"
                      alt="Uploaded"
                    />
                  ) : checkMP4(podcastInfo.image_4) ? (
                    <iframe
                      src={podcastInfo.image_4}
                      title="Uploaded Video"
                      alt="Uploaded"
                    />
                  ) : (
                    <span
                      className="podcast-info-gid-text"
                      dangerouslySetInnerHTML={{ __html: podcastInfo.image_4 }}
                    />
                  )}
                </div>
                <div
                  className="podcast-info-gid-item"
                  style={{ backgroundColor: podcastInfo.color_5 }}>
                  {checkURL(podcastInfo.image_5) ? (
                    <img
                      src={podcastInfo.image_5}
                      title="Uploaded Image"
                      alt="Uploaded"
                    />
                  ) : isValidYoutubeEmbedUrl(podcastInfo.image_5) ? (
                    <iframe
                      src={podcastInfo.image_5}
                      title="Uploaded Video"
                      alt="Uploaded"
                    />
                  ) : checkMP4(podcastInfo.image_5) ? (
                    <iframe
                      src={podcastInfo.image_5}
                      title="Uploaded Video"
                      alt="Uploaded"
                    />
                  ) : (
                    <span
                      className="podcast-info-gid-text"
                      dangerouslySetInnerHTML={{ __html: podcastInfo.image_5 }}
                    />
                  )}
                </div>
                <div
                  className="podcast-info-gid-item"
                  style={{ backgroundColor: podcastInfo.color_6 }}>
                  {checkURL(podcastInfo.image_6) ? (
                    <img
                      src={podcastInfo.image_6}
                      title="Uploaded Image"
                      alt="Uploaded"
                    />
                  ) : isValidYoutubeEmbedUrl(podcastInfo.image_6) ? (
                    <iframe
                      src={podcastInfo.image_6}
                      title="Uploaded Video"
                      alt="Uploaded"
                    />
                  ) : checkMP4(podcastInfo.image_6) ? (
                    <iframe
                      src={podcastInfo.image_6}
                      title="Uploaded Video"
                      alt="Uploaded"
                    />
                  ) : (
                    <span
                      className="podcast-info-gid-text"
                      dangerouslySetInnerHTML={{ __html: podcastInfo.image_6 }}
                    />
                  )}
                </div>
                <div
                  className="podcast-info-gid-item"
                  style={{ backgroundColor: podcastInfo.color_7 }}>
                  {checkURL(podcastInfo.image_7) ? (
                    <img
                      src={podcastInfo.image_7}
                      title="Uploaded Image"
                      alt="Uploaded"
                    />
                  ) : isValidYoutubeEmbedUrl(podcastInfo.image_7) ? (
                    <iframe
                      src={podcastInfo.image_7}
                      title="Uploaded Video"
                      alt="Uploaded"
                    />
                  ) : checkMP4(podcastInfo.image_7) ? (
                    <iframe
                      src={podcastInfo.image_7}
                      title="Uploaded Video"
                      alt="Uploaded"
                    />
                  ) : (
                    <span
                      className="podcast-info-gid-text"
                      dangerouslySetInnerHTML={{ __html: podcastInfo.image_7 }}
                    />
                  )}
                </div>
                <div
                  className="podcast-info-gid-item"
                  style={{ backgroundColor: podcastInfo.color_8 }}>
                  {checkURL(podcastInfo.image_8) ? (
                    <img
                      src={podcastInfo.image_8}
                      title="Uploaded Image"
                      alt="Uploaded"
                    />
                  ) : isValidYoutubeEmbedUrl(podcastInfo.image_8) ? (
                    <iframe
                      src={podcastInfo.image_8}
                      title="Uploaded Video"
                      alt="Uploaded"
                    />
                  ) : checkMP4(podcastInfo.image_8) ? (
                    <iframe
                      src={podcastInfo.image_8}
                      title="Uploaded Video"
                      alt="Uploaded"
                    />
                  ) : (
                    <span
                      className="podcast-info-gid-text"
                      dangerouslySetInnerHTML={{ __html: podcastInfo.image_8 }}
                    />
                  )}
                </div>
                <div
                  className="podcast-info-gid-item"
                  style={{ backgroundColor: podcastInfo.color_9 }}>
                  {checkURL(podcastInfo.image_9) ? (
                    <img
                      src={podcastInfo.image_9}
                      title="Uploaded Image"
                      alt="Uploaded"
                    />
                  ) : isValidYoutubeEmbedUrl(podcastInfo.image_9) ? (
                    <iframe
                      src={podcastInfo.image_9}
                      title="Uploaded Video"
                      alt="Uploaded"
                    />
                  ) : checkMP4(podcastInfo.image_9) ? (
                    <iframe
                      src={podcastInfo.image_9}
                      title="Uploaded Video"
                      alt="Uploaded"
                    />
                  ) : (
                    <span
                      className="podcast-info-gid-text"
                      dangerouslySetInnerHTML={{ __html: podcastInfo.image_9 }}
                    />
                  )}
                </div>
              </div>
            </div>
          </section>
        </div>
      </section>
    </>
  );
};

export default Podcast;
