import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';

const Contact = () => {
  const [courseContact, setCourseContact] = useState([]);

  useEffect(() => {
    const fetchProjects = async () => {
      const res = await fetch(`https://api.chaulanart.com/contact/`);
      const data = await res.json();

      setCourseContact(data);
    };

    fetchProjects();
  }, []);

  function LayoutInPage({ itemInPage }) {
    return (
      <tbody>
        {itemInPage.slice(0, 10).map((project) => (
          <tr>
            <td>{project.name}</td>
            <td>{project.phone}</td>
            <td>{project.email}</td>
          </tr>
        ))}
      </tbody>
    );
  }
  const PageItem = ({ pageSize }) => {
    const [currentPage, setCurrentPage] = useState(0);
    const pageCount = Math.ceil(courseContact.length / pageSize);

    const handlePageClick = (selectedItem) => {
      setCurrentPage(selectedItem.selected);
      window.scrollTo(0, 0);
    };

    return (
      <>
        <LayoutInPage
          itemInPage={courseContact.slice(
            currentPage * pageSize,
            (currentPage + 1) * pageSize
          )}
        />
        <ReactPaginate
          previousLabel={currentPage === 0 ? '' : 'Prev'}
          nextLabel={currentPage === pageCount - 1 ? '' : 'Next'}
          onPageChange={handlePageClick}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          containerClassName="project-pagination"
          activeClassName="active"
          forcePage={currentPage}
        />
      </>
    );
  };

  return (
    <div class="recent-orders">
      <h2>Danh sách đăng ký tư vấn</h2>
      <table>
        <thead>
          <tr>
            <th>Họ tên</th>
            <th>Số điện thoại</th>
            <th>Email</th>
          </tr>
        </thead>
        <PageItem pageSize={10} />
      </table>
    </div>
  );
};
export default Contact;
