import React, { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { DevTool } from '@hookform/devtools';
import { useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import cloudinaryUpload from '../../../server/uploads.js';
import cloudinaryDelete from '../../../server/delete.js';
import * as yup from 'yup';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import quillEmoji from 'react-quill-emoji';
import 'react-quill-emoji/dist/quill-emoji.css';
import { SnowTheme } from 'quill-color-picker-enhance';
import 'quill-color-picker-enhance/dist/index.css';
Quill.register('themes/snow-quill-color-picker-enhance', SnowTheme);

Quill.register(
  {
    'formats/emoji': quillEmoji.EmojiBlot,
    'modules/emoji-toolbar': quillEmoji.ToolbarEmoji,
    'modules/emoji-shortname': quillEmoji.ShortNameEmoji,
  },
  true
);

const schema = yup.object({
  heading: yup
    .string()
    .trim()
    .required('Không được bỏ trống')
    .min(2, 'Tiêu đề có tối thiểu 2 ký tự'),
  description: yup
    .string()
    .trim()
    .required('Không được bỏ trống')
    .min(2, 'Mô tả có tối thiểu 10 ký tự'),
  image_main: yup.string().required('Không được bỏ trống'),
  image_item_1: yup.string().required('Không được bỏ trống'),
  image_item_2: yup.string().required('Không được bỏ trống'),
  image_item_3: yup.string().required('Không được bỏ trống'),
  image_item_4: yup.string().required('Không được bỏ trống'),
});

let Parchment = Quill.import('parchment');
let LineHeightAttributor = new Parchment.Attributor.Style(
  'lineHeight', // Sử dụng camelCase cho tên định dạng
  'line-height',
  {
    scope: Parchment.Scope.BLOCK_ATTRIBUTE,
  }
);
Quill.register(
  {
    'formats/lineHeight': LineHeightAttributor,
  },
  true
);
var Size = Quill.import('attributors/style/size');
Size.whitelist = Array.from(
  { length: 100 },
  (_, i) => (i + 1).toString() + 'px'
);
Quill.register(Size, true);

const FormAboutEditStory = ({ aboutStory, onRefresh }) => {
  const [uploadedImageUrl, setUploadedImageUrl] = useState('');
  const [uploadedImagePublicId, setUploadedImagePublicId] = useState('');
  const [isImageUploaded, setIsImageUploaded] = useState(false);
  const [uploadOption, setUploadOption] = useState('file');
  const [uploadOptions, setUploadOptions] = useState({
    image_item_1: 'file',
    image_item_2: 'file',
    image_item_3: 'file',
    image_item_4: 'file',
  });
  const [isLoadedImage, setIsLoadedImage] = useState(false);

  const reactQuillRef = useRef(null);
  useEffect(() => {
    let sizePickerItems = document.querySelectorAll('.ql-size .ql-picker-item');
    let lHeightPickerItems = document.querySelectorAll(
      '.ql-lineHeight .ql-picker-item'
    );

    for (let i = 0; i < sizePickerItems.length; i++) {
      let item = sizePickerItems[i];
      let value = item.getAttribute('data-value');
      if (value) {
        item.textContent = value;
      }
    }
    for (let i = 0; i < lHeightPickerItems.length; i++) {
      let item = lHeightPickerItems[i];
      let value = item.getAttribute('data-value');
      if (value) {
        item.textContent = value;
      }
    }
  }, []);

  const [images, setImages] = useState({
    image_item_1: {
      url: aboutStory?.image_item_1,
      publicId: '',
      isUploaded: false,
    },
    image_item_2: {
      url: aboutStory?.image_item_2,
      publicId: '',
      isUploaded: false,
    },
    image_item_3: {
      url: aboutStory?.image_item_3,
      publicId: '',
      isUploaded: false,
    },
    image_item_4: {
      url: aboutStory?.image_item_4,
      publicId: '',
      isUploaded: false,
    },
  });
  const [isLoadeds, setIsLoadeds] = useState({
    image_item_1: {
      isLoaded: false,
    },
    image_item_2: {
      isLoaded: false,
    },
    image_item_3: {
      isLoaded: false,
    },
    image_item_4: {
      isLoaded: false,
    },
  });

  const form = useForm({
    defaultValues: {
      heading: aboutStory.heading,
      description: aboutStory.description,
      image_main: aboutStory.image_main,
      image_item_1: aboutStory.image_item_1,
      image_item_2: aboutStory.image_item_2,
      image_item_3: aboutStory.image_item_3,
      image_item_4: aboutStory.image_item_4,
    },
    resolver: yupResolver(schema),
  });
  const { handleSubmit, reset, formState, control, setValue } = form;
  const { errors, isSubmitSuccessful } = formState;

  const handleFileUpload = async (e) => {
    setIsLoadedImage(true);
    const fileData = new FormData();
    fileData.append('file', e.target.files[0]);

    try {
      const res = await cloudinaryUpload(fileData);
      setUploadedImageUrl(res.secure_url);
      setUploadedImagePublicId(res.publicId);
      setValue('image_main', res.secure_url);
      setIsImageUploaded(true);
    } catch (err) {
      console.error('Upload Error:', err);
    }
  };
  const handleFileUploadItem = async (index, file) => {
    const newIsLoadImages = { ...isLoadeds };
    newIsLoadImages[`image_item_${index + 1}`] = { isLoaded: true };
    setIsLoadeds(newIsLoadImages);
    const fileData = new FormData();
    fileData.append('file', file);
    try {
      const res = await cloudinaryUpload(fileData);
      const newImages = { ...images }; // copy the images object
      newImages[`image_item_${index + 1}`] = {
        url: res.secure_url,
        publicId: res.publicId,
        isUploaded: true,
      };
      setImages(newImages);
      setValue(`image_item_${index + 1}`, res.secure_url);
    } catch (err) {
      console.error('Upload Error:', err);
    }
  };

  const handleDeleteImage = async () => {
    if (!uploadedImagePublicId) {
      alert('No image to delete');
      return;
    }
    try {
      cloudinaryDelete(uploadedImagePublicId);
      setUploadedImageUrl('');
      setUploadedImagePublicId('');
      setIsImageUploaded(false);
      setValue('image_main', '');
      setIsLoadedImage(false);
    } catch (err) {
      console.error('Deletion Error:', err.message);
      alert('Failed to delete image');
    }
  };
  const handleDeleteImageItem = async (index) => {
    const publicId = images[`image_item_${index + 1}`].publicId;
    if (!publicId) {
      alert('No image to delete');
      return;
    }
    try {
      await cloudinaryDelete(publicId);
      const newImages = { ...images }; // copy the images object
      newImages[`image_item_${index + 1}`] = {
        url: '',
        publicId: '',
        isUploaded: false,
      };
      setImages(newImages);
      setValue(`image_item_${index + 1}`, '');
      const newIsLoadImages = { ...isLoadeds };
      newIsLoadImages[`image_item_${index + 1}`] = { isLoaded: false };
      setIsLoadeds(newIsLoadImages);
    } catch (err) {
      console.error('Deletion Error:', err.message);
      alert('Failed to delete image');
    }
  };

  const handleUploadOptionChanges = (index, e) => {
    const newOptions = { ...uploadOptions };
    newOptions[`image_item_${index + 1}`] = e.target.value;
    setUploadOptions(newOptions);
  };
  const handleUploadOptionChange = (e) => {
    setUploadOption(e.target.value);
  };
  const extractSrcFromIframe = (iframeString) => {
    const srcMatch = iframeString.match(
      /src\s*=\s*"([^"]+)"|src\s*=\s*'([^']+)'/
    );
    return srcMatch ? srcMatch[1] || srcMatch[2] : null;
  };

  const handleInputChange = (e) => {
    const embedCode = e.target.value;
    const url = extractSrcFromIframe(embedCode);
    setValue('image_main', url);
  };
  const handleInputChangeItem = (index, e) => {
    const embedCode = e.target.value;
    const url = extractSrcFromIframe(embedCode);
    const newImages = { ...images }; // copy the existing images state

    newImages[`image_item_${index + 1}`] = {
      ...images[`image_item_${index + 1}`],
      url: url,
      isUploaded: true, // assume a new URL means a new upload
    };
    setImages(newImages);
    setValue(`image_item_${index + 1}`, url);
  };
  const checkURL = (url) => {
    return /\.(jpeg|jpg|gif|png)$/i.test(url);
  };
  const isValidYoutubeEmbedUrl = (url) => {
    const pattern = /^https:\/\/www\.youtube\.com\/embed\/.+/i;
    return pattern.test(url);
  };

  const renderImageUpload = (index) => (
    <div className="form-project-flex">
      <div className="form-project-flex-item">
        <label>{`Hình ảnh ${index + 1}`}</label>
        <select
          value={uploadOptions[`image_item_${index + 1}`]}
          onChange={(e) => handleUploadOptionChanges(index, e)}>
          <option value="file">Tải lên từ máy</option>
          <option value="url">Nhập link từ YouTube</option>
        </select>
        {uploadOptions[`image_item_${index + 1}`] === 'file' ? (
          <input
            type="file"
            onChange={(e) => handleFileUploadItem(index, e.target.files[0])}
            accept="image/*,video/*"
          />
        ) : (
          <input
            type="text"
            placeholder="Nhập mã nhúng từ YouTube"
            onChange={(e) => handleInputChangeItem(index, e)}
          />
        )}
        {errors[`image_item_${index + 1}`] && (
          <p className="error">{errors[`image_item_${index + 1}`].message}</p>
        )}
      </div>
      {isLoadeds[`image_item_${index + 1}`].isLoaded ? (
        images[`image_item_${index + 1}`].isUploaded ? (
          <div className="form-project-flex-img">
            {checkURL(images[`image_item_${index + 1}`].url) ? (
              <img
                src={images[`image_item_${index + 1}`].url}
                title="Uploaded Image"
                alt="Uploaded"
              />
            ) : (
              <iframe
                src={images[`image_item_${index + 1}`].url}
                title="Uploaded Video"
                alt="Uploaded"
              />
            )}
            <p onClick={() => handleDeleteImageItem(index)}>Xóa</p>
          </div>
        ) : (
          <p className="project-loading">Loading ...</p>
        )
      ) : (
        <div className="form-project-flex-img">
          {checkURL(aboutStory[`image_item_${index + 1}`]) ? (
            <img
              src={aboutStory[`image_item_${index + 1}`]}
              title="Uploaded Image"
              alt="Uploaded"
            />
          ) : isValidYoutubeEmbedUrl(aboutStory[`image_item_${index + 1}`]) ? (
            <iframe
              src={aboutStory[`image_item_${index + 1}`]}
              title="Uploaded Video"
              alt="Uploaded"
            />
          ) : (
            <iframe
              src={aboutStory[`image_item_${index + 1}`]}
              title="Uploaded Video"
              alt="Uploaded"
            />
          )}
        </div>
      )}
    </div>
  );

  const handleSubmitProject = async (data) => {
    function convertToUnicode(input) {
      return input.replace(
        /([\uD800-\uDBFF][\uDC00-\uDFFF])/g,
        function (char) {
          return '&#x' + char.codePointAt(0).toString(16) + ';';
        }
      );
    }
    function convertObjectToUnicode(obj) {
      for (let key in obj) {
        if (typeof obj[key] === 'object') {
          convertObjectToUnicode(obj[key]); // Nếu phần tử là một đối tượng, gọi đệ quy
        } else if (typeof obj[key] === 'string') {
          obj[key] = convertToUnicode(obj[key]); // Nếu phần tử là một chuỗi, chuyển đổi thành Unicode
        }
      }
      return obj;
    }

    const convertedData = convertObjectToUnicode(data);

    try {
      const url = `https://api.chaulanart.com/about/update-about-story`;
      const opt = {
        method: 'put',
        body: JSON.stringify(convertedData),
        headers: { 'Content-Type': 'application/json' },
      };
      const res = await fetch(url, opt);
      const reponseData = await res.json();
      alert('Xác nhận thành công,', reponseData);
    } catch (error) {
      console.error('Lỗi khi cập nhật sản phẩm: ', error);
    }
  };

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
      onRefresh();
    }
  }, [isSubmitSuccessful, reset, onRefresh]);

  return (
    <div className="form-project">
      <form
        onSubmit={handleSubmit(handleSubmitProject)}
        noValidate>
        <div className="form-project-item">
          <label>Tên tiêu đề</label>
          <ReactQuill
            ref={reactQuillRef}
            theme="snow-quill-color-picker-enhance"
            value={form.watch('heading')}
            onChange={(content) => form.setValue('heading', content)}
            modules={{
              toolbar: {
                container: [
                  ['bold', 'italic', 'underline', 'strike'],
                  ['blockquote', 'code-block'],
                  [{ list: 'ordered' }, { list: 'bullet' }],
                  [{ script: 'sub' }, { script: 'super' }],
                  [{ indent: '-1' }, { indent: '+1' }],
                  [{ direction: 'rtl' }],
                  [
                    {
                      lineHeight: [
                        '1',
                        '1.1',
                        '1.2',
                        '1.3',
                        '1.4',
                        '1.5',
                        '1.6',
                        '1.7',
                        '1.8',
                        '1.9',
                        '2',
                        '2.5',
                        '3',
                      ],
                    },
                  ], // Đảm bảo cú pháp này chính xác
                  [{ size: Size.whitelist }],
                  [{ header: [2, 3, 4, 5, 6, false] }],
                  [{ color: [] }, { background: [] }],
                  [{ font: [] }],
                  [{ align: [] }],
                  ['clean'],
                  ['link'],
                  ['emoji'],
                ],
                'emoji-toolbar': true,
              },
              'emoji-textarea': true,
              'emoji-shortname': true,
              clipboard: {
                matchVisual: false,
              },
            }}
          />
          {errors.heading && <p className="error">{errors.heading.message}</p>}
        </div>
        <div className="form-project-item">
          <div className="form-project-flex">
            <div className="form-project-flex-item">
              <label>Hình ảnh chính</label>
              <select onChange={(e) => handleUploadOptionChange(e)}>
                <option value="file">Tải lên từ máy</option>
                <option value="url">Nhập link từ YouTube</option>
              </select>
              {uploadOption === 'file' ? (
                <input
                  type="file"
                  onChange={(e) => handleFileUpload(e)}
                  accept="image/*,video/*"
                />
              ) : (
                <input
                  type="text"
                  placeholder="Nhập mã nhúng từ YouTube"
                  onChange={handleInputChange}
                />
              )}
              {errors.image_main && (
                <p className="error">{errors.image_main.message}</p>
              )}
            </div>
            <div className="form-project-flex-item">
              {/* Hiển thị hình ảnh đã tải lên */}
              {isLoadedImage && isLoadedImage ? (
                isImageUploaded && isImageUploaded ? (
                  <div className="form-project-flex-img">
                    {checkURL(uploadedImageUrl) ? (
                      <img
                        src={uploadedImageUrl}
                        title="Uploaded Image"
                        alt="Uploaded"
                      />
                    ) : (
                      <iframe
                        src={uploadedImageUrl}
                        title="Uploaded Video"
                        alt="Uploaded"
                      />
                    )}
                    <p onClick={handleDeleteImage}>Xóa</p>
                  </div>
                ) : (
                  <p className="project-loading">Loading ...</p>
                )
              ) : (
                <div className="form-project-flex-img">
                  {checkURL(aboutStory.image_main) ? (
                    <img
                      src={aboutStory.image_main}
                      title="Uploaded Image"
                      alt="Uploaded"
                    />
                  ) : isValidYoutubeEmbedUrl(aboutStory.image_main) ? (
                    <iframe
                      src={aboutStory.image_main}
                      title="Uploaded Video"
                      alt="Uploaded"
                    />
                  ) : (
                    <iframe
                      src={aboutStory.image_main}
                      title="Uploaded Video"
                      alt="Uploaded"
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="form-project-item">
          <label>Mô tả</label>
          <ReactQuill
            ref={reactQuillRef}
            theme="snow-quill-color-picker-enhance"
            value={form.watch('description')}
            onChange={(content) => form.setValue('description', content)}
            modules={{
              toolbar: {
                container: [
                  ['bold', 'italic', 'underline', 'strike'],
                  ['blockquote', 'code-block'],
                  [{ list: 'ordered' }, { list: 'bullet' }],
                  [{ script: 'sub' }, { script: 'super' }],
                  [{ indent: '-1' }, { indent: '+1' }],
                  [{ direction: 'rtl' }],
                  [
                    {
                      lineHeight: [
                        '1',
                        '1.1',
                        '1.2',
                        '1.3',
                        '1.4',
                        '1.5',
                        '1.6',
                        '1.7',
                        '1.8',
                        '1.9',
                        '2',
                        '2.5',
                        '3',
                      ],
                    },
                  ], // Đảm bảo cú pháp này chính xác
                  [{ size: Size.whitelist }],
                  [{ header: [2, 3, 4, 5, 6, false] }],
                  [{ color: [] }, { background: [] }],
                  [{ font: [] }],
                  [{ align: [] }],
                  ['clean'],
                  ['link'],
                  ['emoji'],
                ],
                'emoji-toolbar': true,
              },
              'emoji-textarea': true,
              'emoji-shortname': true,
              clipboard: {
                matchVisual: false,
              },
            }}
          />
          {errors.description && (
            <p className="error">{errors.description.message}</p>
          )}
        </div>
        {[0, 1, 2, 3].map((index) => (
          <div
            className="form-project-item"
            key={index}>
            {renderImageUpload(index)}
          </div>
        ))}
        <div className="form-project-item">
          <button>Xác nhận</button>
        </div>
      </form>
      <DevTool control={control} />
    </div>
  );
};

export default FormAboutEditStory;
