import React from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { DevTool } from '@hookform/devtools';
import { useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useSelector } from 'react-redux';
const schema = yup.object().shape({
  username: yup.string().trim().required('Không được bỏ trống'),
  phone: yup
    .string()
    .trim()
    .required('Không được bỏ trống')
    .matches(/^[0-9]+$/, 'Số điện thoại chỉ chứa chữ số')
    .min(10, 'Số điện thoại phải có tối thiểu 10 chữ số'),
});

const Profile = () => {
  const user = useSelector((state) => state.auth.user);
  const form = useForm({
    defaultValues: {
      username: user?.username,
      phone: user?.phone,
    },
    resolver: yupResolver(schema),
  });
  const { register, handleSubmit, reset, formState, control } = form;
  const { errors, isSubmitSuccessful } = formState;
  const handleChangeRessPassword = async (data) => {
    try {
      const response = await fetch(
        `https://api.chaulanart.com/users/update/${user.id_user}`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        }
      );
      const result = await response.json();
      console.log(result);
      alert('Cặp nhật thành công');
    } catch (error) {
      console.error('Lỗi khi gửi form: ', error);
    }
  };
  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
  }, [isSubmitSuccessful, reset]);
  return (
    <div className="resgiter">
      <h1>Profile</h1>
      <div className="resgiter-user">
        <h2>Cặp Nhật Thông Tin</h2>
        <form
          onSubmit={handleSubmit(handleChangeRessPassword)}
          noValidate>
          <div className="resgiter-user-item">
            <label>Tên tài khoản</label>
            <input
              type="text"
              placeholder="Admin"
              {...register('username')}
            />
            <p className="err"> {errors.username?.message}</p>
          </div>
          <div className="resgiter-user-item">
            <label>Số điện thoại</label>
            <input
              type="text"
              placeholder="0123456789"
              {...register('phone')}
            />
            <p className="err">{errors.phone?.message}</p>
          </div>
          <div className="resgiter-user-item">
            <button>Cập nhật</button>
          </div>
          <div className="resgiter-user-link">
            <Link to="/admin/reset-password">Đổi mật khẩu</Link>
          </div>
        </form>
        <DevTool control={control} />
      </div>
    </div>
  );
};

export default Profile;
