import React, { useState, useEffect, useRef } from 'react';
import './ContentCourse.css';

const ContentCourse = ({ id }) => {
  const [activeIndex, setActiveIndex] = useState(null);
  const contentRefs = useRef([]);
  const [courseContent, setCourseContent] = useState([]);
  const [courseContentHeading, setCourseContentHeading] = useState({});

  const handleClickAccordion = (index) => {
    setActiveIndex((prevActiveIndex) =>
      prevActiveIndex === index ? null : index
    );
  };

  useEffect(() => {
    contentRefs.current.forEach((content, index) => {
      if (content) {
        content.style.height =
          activeIndex === index ? `${content.scrollHeight + 20}px` : '0px';
      }
    });
  }, [activeIndex]);

  useEffect(() => {
    const fetchCourseContent = async () => {
      const res = await fetch(
        `https://api.chaulanart.com/course/course-content/${id}`
      );
      const text = await res.text();
      const data = text ? JSON.parse(text) : {};
      setCourseContent(data);
    };
    const fetchCourseContentHeading = async () => {
      const res = await fetch(
        `https://api.chaulanart.com/course/course-content-heading`
      );
      const text = await res.text();
      const data = text ? JSON.parse(text) : {};
      setCourseContentHeading(data);
    };
    fetchCourseContent();
    fetchCourseContentHeading();
  }, [id]);

  return (
    <section className="contentCourse">
      <div className="container">
        <div className="contentCourse-wrapper">
          <div className="contentCourse-list">
            <h3
              className="contentCourse-heading"
              dangerouslySetInnerHTML={{ __html: courseContentHeading.heading }}
            />
            <div className="contentCourse-process">
              {courseContent.map((item, index) => (
                <div
                  className="contentCourse-accordion"
                  key={index}>
                  <div
                    className={`contentCourse-accordion-header ${
                      activeIndex === index ? 'is-active' : ''
                    }`}
                    onClick={() => handleClickAccordion(index)}>
                    <span
                      className="contentCourse-accordion-day"
                      dangerouslySetInnerHTML={{ __html: item.day_number }}
                    />
                    <span
                      className="contentCourse-accordion-title"
                      dangerouslySetInnerHTML={{ __html: item.title }}
                    />
                    <div
                      className={`contentCourse-accordion-icon ${
                        activeIndex === index ? 'is-active' : ''
                      }`}></div>
                  </div>
                  <div
                    className={`contentCourse-accordion-content ${
                      activeIndex === index ? 'is-active' : ''
                    }`}
                    ref={(el) => (contentRefs.current[index] = el)}
                    onClick={() => handleClickAccordion(index)}>
                    <p className="contentCourse-accordion-text"></p>
                    <p
                      className="contentCourse-accordion-inner"
                      dangerouslySetInnerHTML={{ __html: item.content }}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContentCourse;
