import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { Helmet } from 'react-helmet-async';
import './BlogChild.css';
import { Link, useParams } from 'react-router-dom';

const BlogThoughts = ({ color = '#ff7db0' }) => {
  const [blogCate, setBlogCate] = useState({});
  const [blogItem, setBlogItem] = useState([]);
  let { id } = useParams();

  function stripHtml(html) {
    // Temporary element for parsing HTML content
    var tempDivElement = document.createElement('div');
    tempDivElement.innerHTML = html;
    return tempDivElement.textContent || tempDivElement.innerText || '';
  }

  function convertToHyphenatedString(htmlString) {
    // Create a new DOM parser
    const parser = new DOMParser();

    // Parse the string into an HTML document
    const doc = parser.parseFromString(htmlString, 'text/html');

    // Select all <p> elements
    const paragraphs = doc.querySelectorAll('p');

    // Extract text content from each <p> element, join them with a hyphen, and convert to lowercase
    let combinedText = Array.from(paragraphs)
      .map((p) => p.textContent)
      .join('-')
      .replace(/ /g, '-')
      .toLowerCase();

    return combinedText;
  }

  useEffect(() => {
    const fetchCourseCate = async () => {
      const res = await fetch(
        `https://api.chaulanart.com/blog/blog-cate/${id}`
      );
      const data = await res.json();
      setBlogCate(data);
    };
    const fetchProjects = async () => {
      const res = await fetch(
        `https://api.chaulanart.com/blog/blog-child/${id}`
      );
      const data = await res.json();
      setBlogItem(data);
    };
    fetchProjects();
    fetchCourseCate();
    document.title = `Thoughts - Chau Lan Art`;
  }, [id]);

  const BlogPage = ({ itemInPage }) => {
    return (
      <div className="blogChild-post-list">
        {itemInPage.map((blog) => (
          <Link
            to={`/blog/thoughts-detail/${
              blog.blog_child_id
            }-${convertToHyphenatedString(blog.title)}`}>
            <div className="blogChild-post-item thoughts">
              <h3
                className="blogChild-post-title"
                dangerouslySetInnerHTML={{ __html: blog.title }}
              />
            </div>
          </Link>
        ))}
      </div>
    );
  };

  const checkURL = (url) => {
    return /\.(jpeg|jpg|gif|png)$/i.test(url);
  };
  const isValidYoutubeEmbedUrl = (url) => {
    const pattern = /^https:\/\/www\.youtube\.com\/embed\/.+/i;
    return pattern.test(url);
  };

  const PageItem = ({ pageSize }) => {
    const [currentPage, setCurrentPage] = useState(0);
    const pageCount = Math.ceil(blogItem.length / pageSize);

    const handlePageClick = (selectedItem) => {
      setCurrentPage(selectedItem.selected);
      window.scrollTo(0, 0);
    };

    return (
      <>
        <BlogPage
          itemInPage={blogItem.slice(
            currentPage * pageSize,
            (currentPage + 1) * pageSize
          )}
        />
        <ReactPaginate
          onPageChange={handlePageClick}
          pageCount={pageCount}
          marginPagesDisplayed={2} // Hiển thị 2 trang ở hai bên cạnh trang hiện tại
          pageRangeDisplayed={5} // Số lượng số trang hiển thị
          containerClassName="blogChild-pagination"
          activeClassName="active"
          forcePage={currentPage}
          // Ẩn nút "Next" và "Prev"
          nextLabel=""
          previousLabel=""
        />
      </>
    );
  };
  function useWindowSize() {
    const [windowSize, setWindowSize] = useState({
      width: undefined,
      height: undefined,
    });

    useEffect(() => {
      function handleResize() {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }

      window.addEventListener('resize', handleResize);

      // Gọi hàm handleResize ngay lập tức để set kích thước màn hình ban đầu
      handleResize();

      // Dọn dẹp khi component unmount
      return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowSize;
  }

  const { width } = useWindowSize(); // Sử dụng custom hook
  let responsivePageSize;

  if (width <= 767) {
    // Kích thước cho mobile
    responsivePageSize = 4;
  } else if (width <= 1279) {
    // Kích thước cho tablet
    responsivePageSize = 6;
  } else {
    responsivePageSize = 8; // Kích thước mặc định cho desktop
  }

  const pageDescription = stripHtml(blogCate.name);
  const baseUrl = 'https://chaulanart.com';
  const pageUrl = `${baseUrl}/thoughts/${id}`;
  const imageUrl = blogCate.image;

  return (
    <>
      <Helmet>
        <meta
          property="og:description"
          content={pageDescription}
        />
        <meta
          property="og:url"
          content={pageUrl}
        />
        <meta
          property="og:image"
          content={imageUrl}
        />
      </Helmet>
      <section className="blogChild">
        <div className="container">
          <section className="blogChild-wrapper">
            <div className="blogChild-info">
              <h3
                className="blogChild-heading"
                style={{ color: color }}
                dangerouslySetInnerHTML={{ __html: blogCate.name }}
              />
              <p
                className="blogChild-desc"
                dangerouslySetInnerHTML={{ __html: blogCate.description }}
              />
            </div>
            <div className="blogChild-image">
              {checkURL(blogCate.image) ? (
                <img
                  src={blogCate.image}
                  title="Uploaded Image"
                  alt="Uploaded"
                />
              ) : isValidYoutubeEmbedUrl(blogCate.image) ? (
                <iframe
                  src={blogCate.image}
                  title="Uploaded Video"
                  alt="Uploaded"
                />
              ) : (
                <iframe
                  src={blogCate.image}
                  title="Uploaded Video"
                  alt="Uploaded"
                />
              )}
            </div>
          </section>
          <section className="blogChild-post">
            <PageItem pageSize={responsivePageSize} />
          </section>
        </div>
      </section>
    </>
  );
};

export default BlogThoughts;
