import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { DevTool } from '@hookform/devtools';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const schema = yup.object({
  name: yup
    .string()
    .min(2, 'Tiêu đề có tối thiểu 2 ký tự')
    .required('Không được bỏ trống'),
  link: yup
    .string()
    .min(2, 'Tiêu đề có tối thiểu 2 ký tự')
    .required('Không được bỏ trống'),
});

const FormAddMenuRight = ({ onRefresh }) => {
  const form = useForm({
    defaultValues: {
      name: '',
      link: '',
    },
    resolver: yupResolver(schema),
  });
  const { register, handleSubmit, reset, formState, control } = form;
  const { errors, isSubmitSuccessful } = formState;

  const handleSubmitProject = async (data) => {
    try {
      const url = `https://api.chaulanart.com/footer/add-footer-menu-right`;
      const opt = {
        method: 'post',
        body: JSON.stringify(data),
        headers: { 'Content-Type': 'application/json' },
      };
      const res = await fetch(url, opt);
      const reponseData = await res.json();
      alert('Xác nhận thành công,', reponseData);
    } catch (error) {
      console.error('Lỗi khi cập nhật sản phẩm: ', error);
    }
  };

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
      onRefresh();
    }
  }, [isSubmitSuccessful, reset, onRefresh]);
  return (
    <div className="form-project">
      <form
        onSubmit={handleSubmit(handleSubmitProject)}
        noValidate>
        <div className="form-project-item">
          <label>Tên menu</label>
          <input
            type="text"
            placeholder="Tên menu"
            {...register('name')}
          />
          {errors.name && <p className="error">{errors.name.message}</p>}
        </div>
        <div className="form-project-item">
          <label>Link</label>
          <textarea
            type="text"
            placeholder="Link"
            rows="7"
            cols="80"
            {...register('link')}
          />
          {errors.link && <p className="error">{errors.link.message}</p>}
        </div>
        <div className="form-project-item">
          <button>Xác nhận</button>
        </div>
      </form>
      <DevTool control={control} />
    </div>
  );
};

export default FormAddMenuRight;
