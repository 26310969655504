import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import FromAddAboutAward from './FromAddAboutAward';
import ReactPaginate from 'react-paginate';

const AboutAward = () => {
  const [aboutAward, setAboutAward] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [refreshKey, setRefreshKey] = useState(0);

  const handleOpenAboutAddAward = () => {
    setShowForm(true);
  };
  const handleCloseAboutAddAward = () => {
    setShowForm(false);
  };

  useEffect(() => {
    const fetchAboutAward = async () => {
      const res = await fetch('https://api.chaulanart.com/about/about-awards');
      const data = await res.json();
      setAboutAward(data);
    };
    fetchAboutAward();
  }, [refreshKey]);

  const checkURL = (url) => {
    return /\.(jpeg|jpg|gif|png)$/i.test(url);
  };
  const isValidYoutubeEmbedUrl = (url) => {
    const pattern = /^https:\/\/www\.youtube\.com\/embed\/.+/i;
    return pattern.test(url);
  };

  const deleteAboutAward = (id) => {
    if (window.confirm('Bạn muốn xóa không?')) {
      fetch(`https://api.chaulanart.com/about/delete-about-awards/${id}`, {
        method: 'DELETE',
      })
        .then((res) => res.json())
        .then(() => {
          // Fetch the list of projects again after a project has been deleted
          const fetchProjects = async () => {
            const res = await fetch(
              'https://api.chaulanart.com/about/about-awards'
            );
            const data = await res.json();
            setAboutAward(data);
          };

          fetchProjects();
        })
        .catch((error) => console.error('Lỗi xóa:', error));
    }
  };

  function LayoutInPage({ itemInPage }) {
    return (
      <tbody className="project-list">
        {itemInPage &&
          itemInPage.slice(0, 10).map((item) => (
            <tr>
              <td>
                <div dangerouslySetInnerHTML={{ __html: item.heading }} />
              </td>
              <td>
                {checkURL(item.image) ? (
                  <img
                    src={item.image}
                    title="Uploaded Image"
                    alt="Uploaded"
                    className="project-image"
                  />
                ) : isValidYoutubeEmbedUrl(item.image) ? (
                  <iframe
                    src={item.image}
                    title="Uploaded Video"
                    alt="Uploaded"
                    className="project-image"
                  />
                ) : (
                  <iframe
                    src={item.image}
                    title="Uploaded Video"
                    alt="Uploaded"
                    className="project-image"
                  />
                )}
              </td>
              <td>
                <div dangerouslySetInnerHTML={{ __html: item.time }} />
              </td>
              <td>
                <Link
                  className="project-actions"
                  to={`/admin/about-awards/${item.about_awards_id}`}>
                  Chi tiết
                </Link>
              </td>
              <td>
                <p
                  className="project-actions"
                  onClick={() => deleteAboutAward(item.about_awards_id)}>
                  Xóa
                </p>
              </td>
            </tr>
          ))}
      </tbody>
    );
  }
  const PageItem = ({ pageSize }) => {
    const [currentPage, setCurrentPage] = useState(0);
    const pageCount = Math.ceil(aboutAward.length / pageSize);

    const handlePageClick = (selectedItem) => {
      setCurrentPage(selectedItem.selected);
      window.scrollTo(0, 0);
    };

    return (
      <>
        <LayoutInPage
          itemInPage={aboutAward.slice(
            currentPage * pageSize,
            (currentPage + 1) * pageSize
          )}
        />
        <ReactPaginate
          previousLabel={currentPage === 0 ? '' : 'Prev'}
          nextLabel={currentPage === pageCount - 1 ? '' : 'Next'}
          onPageChange={handlePageClick}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          containerClassName="project-pagination"
          activeClassName="active"
          forcePage={currentPage}
        />
      </>
    );
  };

  return (
    <div class="recent-orders">
      <h1>About Awards</h1>
      {showForm && (
        <>
          <FromAddAboutAward onRefresh={() => setRefreshKey(refreshKey + 1)} />
          <div
            className="project-detail-add"
            onClick={handleCloseAboutAddAward}>
            <span>Đóng</span>
          </div>
        </>
      )}
      <table>
        <thead>
          <tr>
            <th>Tên dự án</th>
            <th>Hình minh họa</th>
            <th>Thời gian</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <PageItem pageSize={6} />
      </table>
      <div
        className="project-add"
        onClick={handleOpenAboutAddAward}>
        <span>Thêm dự án mới</span>
      </div>
    </div>
  );
};

export default AboutAward;
