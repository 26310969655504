import React, { useCallback, useEffect, useState } from 'react';
import './PodcastPage.css';
import FormPodcastHeader from '../FormPodcast/FormPodcastHeader';
import FormPodcastSocial from '../FormPodcast/FormPodcastSocial';
import FormPodcastSocialHeading from '../FormPodcast/FormPodcastSocialHeading';
import FormPodcastMarquee from '../FormPodcast/FormPodcastMarquee';
import FormPodcastEditInfo from '../FormPodcast/FormPodcastEditInfo';
import FormEditPodcastThumnail from '../FormPodcast/FormEditPodcastThumnail';

const PodcastPage = () => {
  const [podcastHeader, setPodcastHeader] = useState({});
  const [podcastSocialHeading, setPodcastSocialHeading] = useState({});
  const [podcastInfo, setPodcastInfo] = useState({});
  const [thumnailPodcastPage, setThumnailPodcastPage] = useState({});
  const [podcastSocial, setPodcastSocial] = useState([]);
  const [podcastMarquee, setPodcastMarquee] = useState([]);
  const [refreshKey, setRefreshKey] = useState(0);
  const [showFormEditPodcastHeader, setShowEditPodcastHeader] = useState(false);
  const [showFormEditPodcastSocial, setShowEditPodcastSocial] = useState(false);
  const [showFormEditSocialHeading, setShowEditSocialHeading] = useState(false);
  const [showFormEditPodcastMarquee, setShowEditPodcastMarquee] =
    useState(false);
  const [showFormEditPodcastInfo, setShowEditPodcastInfo] = useState(false);
  const [showFormEditPodcastThumbnail, setShowFormEditPodcastThumbnail] =
    useState(false);
  const [social, setSocial] = useState('');
  const [marquee, setMarquee] = useState('');
  const handleRefresh = useCallback(
    () => setRefreshKey(refreshKey + 1),
    [refreshKey]
  );

  const handleOpenPodcastHeaderEditClick = () => {
    setShowEditPodcastHeader(true);
  };
  const handleOpenPodcastSocialEditClick = (social) => {
    setShowEditPodcastSocial(true);
    setSocial(social);
  };
  const handleOpenEditSocialHeading = () => {
    setShowEditSocialHeading(true);
  };
  const handleOpenEditPodCastMarquee = (marquee) => {
    setShowEditPodcastMarquee(true);
    setMarquee(marquee);
  };
  const handleOpenEditPodCasInfo = () => {
    setShowEditPodcastInfo(true);
  };
  const handleClodePodcastSocialEditClick = () => {
    setShowEditPodcastSocial(false);
  };
  const handleClodePodcastHeaderEditClick = () => {
    setShowEditPodcastHeader(false);
  };
  const handleClodeEditSocialHeading = () => {
    setShowEditSocialHeading(false);
  };
  const handleClodeEditPodcastMarquee = () => {
    setShowEditPodcastMarquee(false);
  };
  const handleClodeEditPodcastInfo = () => {
    setShowEditPodcastInfo(false);
  };

  const handleOpenEditPodcastThumbnail = () => {
    setShowFormEditPodcastThumbnail(true);
  };
  const handleClodeFormEditPodcastThumbnail = () => {
    setShowFormEditPodcastThumbnail(false);
  };

  useEffect(() => {
    const fetchPodcastHeader = async () => {
      const res = await fetch(
        `https://api.chaulanart.com/podcast/podcast-header`
      );
      const data = await res.json();
      setPodcastHeader(data);
    };
    const fetchPodcastSocialHeading = async () => {
      const res = await fetch(
        `https://api.chaulanart.com/podcast/podcast-social-heading`
      );
      const data = await res.json();
      setPodcastSocialHeading(data);
    };
    const fetchPodcastSocial = async () => {
      const res = await fetch(
        `https://api.chaulanart.com/podcast/podcast-social`
      );
      const data = await res.json();
      setPodcastSocial(data);
    };
    const fetchPodcastMarquee = async () => {
      const res = await fetch(
        `https://api.chaulanart.com/podcast/podcast-marquee`
      );
      const data = await res.json();
      setPodcastMarquee(data);
    };
    const fetchPodcastInfo = async () => {
      const res = await fetch(
        `https://api.chaulanart.com/podcast/podcast-info`
      );
      const data = await res.json();
      setPodcastInfo(data);
    };
    const fetchPodcastThumnail = async () => {
      const res = await fetch(
        `https://api.chaulanart.com/podcast/podcast-thumnail`
      );
      const data = await res.json();
      setThumnailPodcastPage(data);
    };
    fetchPodcastHeader();
    fetchPodcastSocialHeading();
    fetchPodcastSocial();
    fetchPodcastMarquee();
    fetchPodcastInfo();
    fetchPodcastThumnail();
    setShowEditPodcastHeader(false);
    setShowEditPodcastSocial(false);
    setShowEditSocialHeading(false);
    setShowEditPodcastMarquee(false);
    setShowFormEditPodcastThumbnail(false);
    setShowEditPodcastInfo(false);
  }, [refreshKey]);

  const checkURL = (url) => {
    return /\.(jpeg|jpg|gif|png)$/i.test(url);
  };
  const checkMP4 = (url) => {
    return /\.(mp4)$/i.test(url);
  };
  const isValidYoutubeEmbedUrl = (url) => {
    const pattern = /^https:\/\/www\.youtube\.com\/embed\/.+/i;
    return pattern.test(url);
  };

  return (
    <div className="podcastPage">
      <h1>Trang podcast</h1>
      <h2>Thumbnail PodcastPage</h2>
      {showFormEditPodcastThumbnail ? (
        <>
          <FormEditPodcastThumnail
            onRefresh={() => setRefreshKey(refreshKey + 1)}
            podcastThumnail={thumnailPodcastPage}
          />
          <div
            className="workpage-add"
            onClick={handleClodeFormEditPodcastThumbnail}>
            <span>Đóng</span>
          </div>
        </>
      ) : null}
      <div className="project-image-detail">
        <div className="project-image-detail-item">
          {checkURL(thumnailPodcastPage.thumnail) ? (
            <img
              src={thumnailPodcastPage.thumnail}
              title="Uploaded Image"
              alt="Uploaded"
              className="project-image"
            />
          ) : isValidYoutubeEmbedUrl(thumnailPodcastPage.thumnail) ? (
            <iframe
              src={thumnailPodcastPage.thumnail}
              title="Uploaded Video"
              alt="Uploaded"
              className="project-image"
            />
          ) : (
            <iframe
              src={thumnailPodcastPage.thumnail}
              title="Uploaded Video"
              alt="Uploaded"
              className="project-image"
            />
          )}
        </div>
      </div>
      <div
        className="project-detail-add"
        onClick={handleOpenEditPodcastThumbnail}>
        <span>Sửa thông tin</span>
      </div>
      {showFormEditPodcastHeader && (
        <>
          <FormPodcastHeader
            podcast={podcastHeader}
            onRefresh={handleRefresh}
          />
          <div
            className="project-detail-add"
            onClick={handleClodePodcastHeaderEditClick}>
            <span>Đóng</span>
          </div>
        </>
      )}
      <div className="podcastPage-header">
        <div className="podcastPage-header-item">
          <label>Hình minh họa</label>
          {checkURL(podcastHeader.banner) ? (
            <img
              src={podcastHeader.banner}
              title="Uploaded Image"
              alt="Uploaded"
              className="podcastPage-header-image"
            />
          ) : isValidYoutubeEmbedUrl(podcastHeader.banner) ? (
            <iframe
              src={podcastHeader.banner}
              title="Uploaded Video"
              alt="Uploaded"
              className="podcastPage-header-image"
            />
          ) : (
            <iframe
              src={podcastHeader.banner}
              title="Uploaded Video"
              alt="Uploaded"
              className="podcastPage-header-image"
            />
          )}
        </div>
        <div className="podcastPage-header-item">
          <label>Mô tả</label>
          <p>
            <div
              dangerouslySetInnerHTML={{ __html: podcastHeader.description }}
            />
          </p>
        </div>
      </div>
      <div
        className="project-detail-add"
        onClick={handleOpenPodcastHeaderEditClick}>
        <span>Sửa thông tin</span>
      </div>
      <h2>Social podcast</h2>
      {showFormEditSocialHeading && (
        <>
          <FormPodcastSocialHeading
            socialHeading={podcastSocialHeading}
            onRefresh={handleRefresh}
          />
          <div
            className="project-detail-add"
            onClick={handleClodeEditSocialHeading}>
            <span>Đóng</span>
          </div>
        </>
      )}
      <div className="podcastPage-info">
        <div className="podcastPage-header-item">
          <label>Tiêu đề:</label>
          <p>
            <div
              dangerouslySetInnerHTML={{ __html: podcastSocialHeading.heading }}
            />
          </p>
        </div>
      </div>
      <div
        className="workpage-add"
        onClick={handleOpenEditSocialHeading}>
        <span>Sửa thông tin</span>
      </div>
      {showFormEditPodcastSocial && (
        <>
          <FormPodcastSocial
            social={social}
            onRefresh={handleRefresh}
          />
          <div
            className="project-detail-add"
            onClick={handleClodePodcastSocialEditClick}>
            <span>Đóng</span>
          </div>
        </>
      )}
      <div className="workpage-tags">
        {podcastSocial &&
          podcastSocial.length > 0 &&
          podcastSocial.map((item) => (
            <div className="workpage-tags-item-img">
              {checkURL(item.social) ? (
                <img
                  src={item.social}
                  title="Uploaded Image"
                  alt="Uploaded"
                  className="project-image"
                />
              ) : isValidYoutubeEmbedUrl(item.social) ? (
                <iframe
                  src={item.social}
                  title="Uploaded Video"
                  alt="Uploaded"
                  className="project-image"
                />
              ) : (
                <iframe
                  src={item.social}
                  title="Uploaded Video"
                  alt="Uploaded"
                  className="project-image"
                />
              )}
              <div
                className="workpage-tags-item-edit"
                onClick={() => handleOpenPodcastSocialEditClick(item)}>
                <i className="fa-solid fa-pen"></i>
              </div>
            </div>
          ))}
      </div>
      <h2>Podcast marquee</h2>
      {showFormEditPodcastMarquee && (
        <>
          <FormPodcastMarquee
            marquee={marquee}
            onRefresh={handleRefresh}
          />
          <div
            className="project-detail-add"
            onClick={handleClodeEditPodcastMarquee}>
            <span>Đóng</span>
          </div>
        </>
      )}
      <div className="workpage-tags">
        {podcastMarquee &&
          podcastMarquee.length > 0 &&
          podcastMarquee.map((item) => (
            <div className="workpage-tags-item">
              <span>
                <div dangerouslySetInnerHTML={{ __html: item.heading }} />
              </span>
              <div
                className="workpage-tags-item-edit"
                onClick={() => handleOpenEditPodCastMarquee(item)}>
                <i className="fa-solid fa-pen"></i>
              </div>
            </div>
          ))}
      </div>
      <h2>Podcast thông tin và hình ảnh</h2>
      {showFormEditPodcastInfo && (
        <>
          <FormPodcastEditInfo
            podcastInfo={podcastInfo}
            onRefresh={handleRefresh}
          />
          <div
            className="project-detail-add"
            onClick={handleClodeEditPodcastInfo}>
            <span>Đóng</span>
          </div>
        </>
      )}
      <div className="podcastPage-info">
        <div className="podcastPage-header-item">
          <label>Hình ảnh chính</label>
          <img
            src={podcastInfo.image_main}
            alt=""
            className="podcastPage-header-image"
          />
        </div>
        <div className="podcastPage-header-item">
          <label>Nội dung 1</label>
          {checkURL(podcastInfo.image_1) ? (
            <img
              src={podcastInfo.image_1}
              title="Uploaded Image"
              alt="Uploaded"
              className="podcastPage-header-image-info"
            />
          ) : isValidYoutubeEmbedUrl(podcastInfo.image_1) ? (
            <iframe
              src={podcastInfo.image_1}
              title="Uploaded Video"
              alt="Uploaded"
              className="podcastPage-header-image-info"
            />
          ) : checkMP4(podcastInfo.image_1) ? (
            <iframe
              src={podcastInfo.image_1}
              title="Uploaded Video"
              alt="Uploaded"
              className="podcastPage-header-image-info"
            />
          ) : (
            <span dangerouslySetInnerHTML={{ __html: podcastInfo.image_1 }} />
          )}
        </div>
        <div className="podcastPage-header-item">
          <label>Nội dung 2</label>
          {checkURL(podcastInfo.image_2) ? (
            <img
              src={podcastInfo.image_2}
              title="Uploaded Image"
              alt="Uploaded"
              className="podcastPage-header-image-info"
            />
          ) : isValidYoutubeEmbedUrl(podcastInfo.image_2) ? (
            <iframe
              src={podcastInfo.image_2}
              title="Uploaded Video"
              alt="Uploaded"
              className="podcastPage-header-image-info"
            />
          ) : checkMP4(podcastInfo.image_2) ? (
            <iframe
              src={podcastInfo.image_2}
              title="Uploaded Video"
              alt="Uploaded"
              className="podcastPage-header-image-info"
            />
          ) : (
            <span dangerouslySetInnerHTML={{ __html: podcastInfo.image_2 }} />
          )}
        </div>
        <div className="podcastPage-header-item">
          <label>Nội dung 3</label>
          {checkURL(podcastInfo.image_3) ? (
            <img
              src={podcastInfo.image_3}
              title="Uploaded Image"
              alt="Uploaded"
              className="podcastPage-header-image-info"
            />
          ) : isValidYoutubeEmbedUrl(podcastInfo.image_3) ? (
            <iframe
              src={podcastInfo.image_3}
              title="Uploaded Video"
              alt="Uploaded"
              className="podcastPage-header-image-info"
            />
          ) : checkMP4(podcastInfo.image_3) ? (
            <iframe
              src={podcastInfo.image_3}
              title="Uploaded Video"
              alt="Uploaded"
              className="podcastPage-header-image-info"
            />
          ) : (
            <span dangerouslySetInnerHTML={{ __html: podcastInfo.image_3 }} />
          )}
        </div>
        <div className="podcastPage-header-item">
          <label>Nội dung 4</label>
          {checkURL(podcastInfo.image_4) ? (
            <img
              src={podcastInfo.image_4}
              title="Uploaded Image"
              alt="Uploaded"
              className="podcastPage-header-image-info"
            />
          ) : isValidYoutubeEmbedUrl(podcastInfo.image_4) ? (
            <iframe
              src={podcastInfo.image_4}
              title="Uploaded Video"
              alt="Uploaded"
              className="podcastPage-header-image-info"
            />
          ) : checkMP4(podcastInfo.image_4) ? (
            <iframe
              src={podcastInfo.image_4}
              title="Uploaded Video"
              alt="Uploaded"
              className="podcastPage-header-image-info"
            />
          ) : (
            <span dangerouslySetInnerHTML={{ __html: podcastInfo.image_4 }} />
          )}
        </div>
        <div className="podcastPage-header-item">
          <label>Nội dung 5</label>
          {checkURL(podcastInfo.image_5) ? (
            <img
              src={podcastInfo.image_5}
              title="Uploaded Image"
              alt="Uploaded"
              className="podcastPage-header-image-info"
            />
          ) : isValidYoutubeEmbedUrl(podcastInfo.image_5) ? (
            <iframe
              src={podcastInfo.image_5}
              title="Uploaded Video"
              alt="Uploaded"
              className="podcastPage-header-image-info"
            />
          ) : checkMP4(podcastInfo.image_5) ? (
            <iframe
              src={podcastInfo.image_5}
              title="Uploaded Video"
              alt="Uploaded"
              className="podcastPage-header-image-info"
            />
          ) : (
            <span dangerouslySetInnerHTML={{ __html: podcastInfo.image_5 }} />
          )}
        </div>
        <div className="podcastPage-header-item">
          <label>Nội dung 6</label>
          {checkURL(podcastInfo.image_6) ? (
            <img
              src={podcastInfo.image_6}
              title="Uploaded Image"
              alt="Uploaded"
              className="podcastPage-header-image-info"
            />
          ) : isValidYoutubeEmbedUrl(podcastInfo.image_6) ? (
            <iframe
              src={podcastInfo.image_6}
              title="Uploaded Video"
              alt="Uploaded"
              className="podcastPage-header-image-info"
            />
          ) : checkMP4(podcastInfo.image_6) ? (
            <iframe
              src={podcastInfo.image_6}
              title="Uploaded Video"
              alt="Uploaded"
              className="podcastPage-header-image-info"
            />
          ) : (
            <span dangerouslySetInnerHTML={{ __html: podcastInfo.image_6 }} />
          )}
        </div>
        <div className="podcastPage-header-item">
          <label>Nội dung 1</label>
          {checkURL(podcastInfo.image_7) ? (
            <img
              src={podcastInfo.image_7}
              title="Uploaded Image"
              alt="Uploaded"
              className="podcastPage-header-image-info"
            />
          ) : isValidYoutubeEmbedUrl(podcastInfo.image_7) ? (
            <iframe
              src={podcastInfo.image_7}
              title="Uploaded Video"
              alt="Uploaded"
              className="podcastPage-header-image-info"
            />
          ) : checkMP4(podcastInfo.image_7) ? (
            <iframe
              src={podcastInfo.image_7}
              title="Uploaded Video"
              alt="Uploaded"
              className="podcastPage-header-image-info"
            />
          ) : (
            <span dangerouslySetInnerHTML={{ __html: podcastInfo.image_7 }} />
          )}
        </div>
        <div className="podcastPage-header-item">
          <label>Nội dung 8</label>
          {checkURL(podcastInfo.image_8) ? (
            <img
              src={podcastInfo.image_8}
              title="Uploaded Image"
              alt="Uploaded"
              className="podcastPage-header-image-info"
            />
          ) : isValidYoutubeEmbedUrl(podcastInfo.image_8) ? (
            <iframe
              src={podcastInfo.image_8}
              title="Uploaded Video"
              alt="Uploaded"
              className="podcastPage-header-image-info"
            />
          ) : checkMP4(podcastInfo.image_8) ? (
            <iframe
              src={podcastInfo.image_8}
              title="Uploaded Video"
              alt="Uploaded"
              className="podcastPage-header-image-info"
            />
          ) : (
            <span dangerouslySetInnerHTML={{ __html: podcastInfo.image_8 }} />
          )}
        </div>
        <div className="podcastPage-header-item">
          <label>Nội dung 9</label>
          {checkURL(podcastInfo.image_9) ? (
            <img
              src={podcastInfo.image_9}
              title="Uploaded Image"
              alt="Uploaded"
              className="podcastPage-header-image-info"
            />
          ) : isValidYoutubeEmbedUrl(podcastInfo.image_9) ? (
            <iframe
              src={podcastInfo.image_9}
              title="Uploaded Video"
              alt="Uploaded"
              className="podcastPage-header-image-info"
            />
          ) : checkMP4(podcastInfo.image_9) ? (
            <iframe
              src={podcastInfo.image_9}
              title="Uploaded Video"
              alt="Uploaded"
              className="podcastPage-header-image-info"
            />
          ) : (
            <span dangerouslySetInnerHTML={{ __html: podcastInfo.image_9 }} />
          )}
        </div>
      </div>
      <div
        className="project-detail-add"
        onClick={handleOpenEditPodCasInfo}>
        <span>Sửa thông tin</span>
      </div>
    </div>
  );
};

export default PodcastPage;
