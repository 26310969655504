import React from 'react';
import Infocourse from './InFoCourse/Infocourse';
import ContentCourse from './ContentCourse/ContentCourse';
import StudentCourse from './StudentCourse/StudentCourse';
import FeedbackCourse from './FeedbackCourse/FeedbackCourse';
import { useParams } from 'react-router-dom';

const DPICenter = () => {
  let { id } = useParams();

  return (
    <section className="course-detail">
      <Infocourse id={id}></Infocourse>
      <ContentCourse id={id}></ContentCourse>
      <StudentCourse id={id}></StudentCourse>
      <FeedbackCourse id={id}></FeedbackCourse>
    </section>
  );
};

export default DPICenter;
