import React, { useRef } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import './LoginPage.css';
import { dalogin } from './authSlice';

const LoginPage = () => {
  const email = useRef();
  const password = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      email: email.current.value,
      password: password.current.value,
    };
    if (data.email === '') {
      alert('Vui lòng nhập email');
      return;
    }
    if (data.password === '') {
      alert('Vui lòng nhập password');
      return;
    }
    try {
      const response = await fetch('https://api.chaulanart.com/users/login', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
      });
      const result = await response.json();
      if (response.ok) {
        // Chuyển đổi result thành chuỗi JSON và lưu vào localStorage
        dispatch(dalogin(result));
        localStorage.setItem('result', JSON.stringify(result));
        navigate('/admin');
      } else {
        alert(result.thongbao);
      }
    } catch (error) {
      console.log(error);
      alert(error.message);
    }
  };
  return (
    <div className="login-wrapper">
      <div className="form-container">
        <p className="title">Welcome</p>
        <form
          className="form"
          onSubmit={handleSubmit}>
          <input
            type="email"
            className="input"
            placeholder="Email"
            ref={email}
          />
          <input
            type="password"
            className="input"
            placeholder="Password"
            ref={password}
          />
          <p className="page-link">
            <Link
              to={'/forgot-password'}
              className="forgot-password-link">
              Quên mật khẩu?
            </Link>
          </p>
          <button className="form-btn">Đăng nhập</button>
        </form>
      </div>
    </div>
  );
};

export default LoginPage;
