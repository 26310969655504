import React, { useEffect, useState } from 'react';
import './ExperPage.css';
import FormExperHeading from '../FormExper/FormExperHeading';
import FormWorkingHeading from '../FormExper/FormWorkingHeading';
import FormExperItem from '../FormExper/FormExperItem';
import FormWorkingItem from '../FormExper/FormWorkingItem';
import FormEditExperThumnail from '../FormExper/FormEditExperThumnail';

const ExperPage = () => {
  const [expertHeading, setExpertHeading] = useState({});
  const [expertHeadingItem, setExpertHeadingItem] = useState([]);
  const [worikingProcess, setWorkingProcess] = useState([]);
  const [workingProcessHeading, setWorkingProcessHeading] = useState({});
  const [thumnailExperPage, setThumnailExperPage] = useState({});
  const [showFormEditExpert, setShowFormEditExpert] = useState(false);
  const [showFormEditWorkingHeading, setShowFormEditWorkingHeading] =
    useState(false);
  const [showFormAddWorkingtItem, setShowFormAddWorkingItem] = useState(false);
  const [showFormEditExpertItem, setShowFormEditExpertItem] = useState(false);
  const [showFormEditExperThumbnail, setShowFormEditExperThumbnail] =
    useState(false);
  const [experItem, setExperItem] = useState('');
  const [workingItem, setWorkingItem] = useState('');
  const [refreshKey, setRefreshKey] = useState(0);

  const handleOpenFormEditExpert = () => {
    setShowFormEditExpert(true);
  };
  const handleOpenFormEditWorkingHeading = () => {
    setShowFormEditWorkingHeading(true);
  };
  const handleOpenFormEditExpertItem = (expertise) => {
    setShowFormEditExpertItem(true);
    setExperItem(expertise);
  };
  const handleOpenFormAddWorkingItem = (woriking) => {
    setShowFormAddWorkingItem(true);
    setWorkingItem(woriking);
  };

  const handleClodeFormEditExpert = () => {
    setShowFormEditExpert(false);
  };
  const handleClodeFormEditWorkingHeading = () => {
    setShowFormEditWorkingHeading(false);
  };
  const handleClodeFormEditExpertItem = () => {
    setShowFormEditExpertItem(false);
  };
  const handleClodeFormAddWorkingItem = () => {
    setShowFormAddWorkingItem(false);
  };

  const handleOpenFormEditExpertThumbnail = () => {
    setShowFormEditExperThumbnail(true);
  };
  const handleClodeFormEditExpertThumbnail = () => {
    setShowFormEditExperThumbnail(false);
  };

  useEffect(() => {
    const fetchExpert = async () => {
      const res = await fetch(
        `https://api.chaulanart.com/expertise-page/get-expertise`
      );
      const data = await res.json();
      setExpertHeadingItem(data);
    };
    const fetchExpertHeading = async () => {
      const res = await fetch(
        `https://api.chaulanart.com/expertise-page/get-expertise-heading`
      );
      const data = await res.json();
      setExpertHeading(data);
    };
    const fetchWorkingProcess = async () => {
      const res = await fetch(
        `https://api.chaulanart.com/expertise-page/get-working-process`
      );
      const data = await res.json();
      setWorkingProcess(data);
    };
    const fetchWorkingProcessHeading = async () => {
      const res = await fetch(
        `https://api.chaulanart.com/expertise-page/get-working-process-heading`
      );
      const data = await res.json();
      setWorkingProcessHeading(data);
    };
    const fetchThumnailExperPage = async () => {
      const res = await fetch(
        `https://api.chaulanart.com/expertise-page/get-expertise-thumnail`
      );
      const data = await res.json();
      setThumnailExperPage(data);
    };

    fetchExpert();
    fetchExpertHeading();
    fetchWorkingProcess();
    fetchWorkingProcessHeading();
    fetchThumnailExperPage();
    setShowFormEditExpert(false);
    setShowFormAddWorkingItem(false);
    setShowFormEditExpertItem(false);
    setShowFormEditWorkingHeading(false);
    setShowFormEditExperThumbnail(false);
  }, [refreshKey]);

  const checkURL = (url) => {
    return /\.(jpeg|jpg|gif|png)$/i.test(url);
  };
  const isValidYoutubeEmbedUrl = (url) => {
    const pattern = /^https:\/\/www\.youtube\.com\/embed\/.+/i;
    return pattern.test(url);
  };

  return (
    <div className="expertpage">
      <h1>Trang Expertise</h1>
      {showFormEditExpert && (
        <>
          <FormExperHeading
            experHeading={expertHeading}
            onRefresh={() => setRefreshKey(refreshKey + 1)}
          />
          <div
            className="expertpage-add"
            onClick={handleClodeFormEditExpert}>
            <span>Đóng</span>
          </div>
        </>
      )}
      <div className="expertpage-info">
        <div className="expertpage-info-item">
          <label>Tiêu đề:</label>
          <p>
            <div dangerouslySetInnerHTML={{ __html: expertHeading.heading }} />
          </p>
        </div>
      </div>
      <div
        className="expertpage-add"
        onClick={handleOpenFormEditExpert}>
        <span>Sửa thông tin</span>
      </div>
      <h2>Nội dung các expertise</h2>
      {showFormEditExpertItem && (
        <>
          <FormExperItem
            experItem={experItem}
            onRefresh={() => setRefreshKey(refreshKey + 1)}
          />
          <div
            className="expertpage-add"
            onClick={handleClodeFormEditExpertItem}>
            <span>Đóng</span>
          </div>
        </>
      )}
      <div className="expertpage-tags">
        {expertHeadingItem && expertHeadingItem.length > 0 ? (
          expertHeadingItem.map((item) => {
            return (
              <div className="expertpage-tags-item">
                <div dangerouslySetInnerHTML={{ __html: item.heading }} />
                <div dangerouslySetInnerHTML={{ __html: item.content }} />
                <div
                  className="expertpage-tags-item-edit"
                  onClick={() => handleOpenFormEditExpertItem(item)}>
                  <i className="fa-solid fa-pen"></i>
                </div>
              </div>
            );
          })
        ) : (
          <div className="expertpage-tags-item">
            <span>Chưa có tiêu đề</span>
          </div>
        )}
      </div>
      {showFormEditWorkingHeading && (
        <>
          <FormWorkingHeading
            workingHeading={workingProcessHeading}
            onRefresh={() => setRefreshKey(refreshKey + 1)}
          />
          <div
            className="expertpage-add"
            onClick={handleClodeFormEditWorkingHeading}>
            <span>Đóng</span>
          </div>
        </>
      )}
      <div className="expertpage-info">
        <div className="expertpage-info-item">
          <label>Tiêu đề:</label>
          <p>
            {' '}
            <div
              dangerouslySetInnerHTML={{
                __html: workingProcessHeading.heading,
              }}
            />
          </p>
        </div>
      </div>
      <div
        className="expertpage-add"
        onClick={handleOpenFormEditWorkingHeading}>
        <span>Sửa thông tin</span>
      </div>
      <h2>Nội dung woriking process</h2>
      {showFormAddWorkingtItem && (
        <>
          <FormWorkingItem
            workingItem={workingItem}
            onRefresh={() => setRefreshKey(refreshKey + 1)}
          />
          <div
            className="expertpage-add"
            onClick={handleClodeFormAddWorkingItem}>
            <span>Đóng</span>
          </div>
        </>
      )}
      <div className="expertpage-tags">
        {worikingProcess && worikingProcess.length > 0 ? (
          worikingProcess.map((item) => {
            return (
              <div className="expertpage-tags-item">
                <div dangerouslySetInnerHTML={{ __html: item.heading }} />
                <div dangerouslySetInnerHTML={{ __html: item.content }} />
                <div
                  className="expertpage-tags-item-edit"
                  onClick={() => handleOpenFormAddWorkingItem(item)}>
                  <i className="fa-solid fa-pen"></i>
                </div>
              </div>
            );
          })
        ) : (
          <div className="expertpage-tags-item">
            <span>Chưa có tiêu đề</span>
          </div>
        )}
      </div>
      <h2>Thumbnail ExpertisePage</h2>
      {showFormEditExperThumbnail ? (
        <>
          <FormEditExperThumnail
            onRefresh={() => setRefreshKey(refreshKey + 1)}
            experThumnail={thumnailExperPage}
          />
          <div
            className="workpage-add"
            onClick={handleClodeFormEditExpertThumbnail}>
            <span>Đóng</span>
          </div>
        </>
      ) : null}
      <div className="project-image-detail">
        <div className="project-image-detail-item">
          {checkURL(thumnailExperPage.thumnail) ? (
            <img
              src={thumnailExperPage.thumnail}
              title="Uploaded Image"
              alt="Uploaded"
              className="project-image"
            />
          ) : isValidYoutubeEmbedUrl(thumnailExperPage.thumnail) ? (
            <iframe
              src={thumnailExperPage.thumnail}
              title="Uploaded Video"
              alt="Uploaded"
              className="project-image"
            />
          ) : (
            <iframe
              src={thumnailExperPage.thumnail}
              title="Uploaded Video"
              alt="Uploaded"
              className="project-image"
            />
          )}
        </div>
      </div>
      <div
        className="project-detail-add"
        onClick={handleOpenFormEditExpertThumbnail}>
        <span>Sửa thông tin</span>
      </div>
    </div>
  );
};

export default ExperPage;
