import React, { useEffect, useState } from 'react';
import FormHeader from './FormHeader';
import FormHeaderMenu from './FormHeaderMenu';

const HeaderPage = () => {
  const [headerPage, setHeaderPage] = useState({});
  const [headerMenu, setHeaderMenu] = useState([]);
  const [showFormHeader, setShowFormHeader] = useState(false);
  const [showFormHeaderMenu, setShowFormHeaderMenu] = useState(false);
  const [refreshKey, setRefreshKey] = useState(0);
  const [menu, setMenu] = useState('');

  const handleOpenEditHeader = () => {
    setShowFormHeader(true);
  };
  const handleCloseEditHeader = () => {
    setShowFormHeader(false);
  };
  const handleOpenEditHeaderMenu = (item) => {
    setShowFormHeaderMenu(true);
    setMenu(item);
  };
  const handleCloseEditHeaderMenu = () => {
    setShowFormHeaderMenu(false);
  };

  useEffect(() => {
    const fetchHeader = async () => {
      const res = await fetch(`https://api.chaulanart.com/header/header`);
      const data = await res.json();
      setHeaderPage(data);
    };
    const fetchHeaderMenu = async () => {
      const res = await fetch(`https://api.chaulanart.com/header/header-menu`);
      const data = await res.json();
      setHeaderMenu(data);
    };
    fetchHeader();
    fetchHeaderMenu();
    setShowFormHeader(false);
    setShowFormHeaderMenu(false);
  }, [refreshKey]);

  const checkURL = (url) => {
    if (typeof url !== 'string') {
      console.error('Invalid input: URL must be a string.', url);
      return false;
    }
    return url.match(/\.(jpeg|jpg|gif|png)$/) != null;
  };
  const isValidYoutubeEmbedUrl = (url) => {
    const pattern = /^https:\/\/www\.youtube\.com\/embed\/.+/i;
    return pattern.test(url);
  };

  return (
    <div className="podcastPage">
      <h1>Header</h1>
      {showFormHeader && (
        <>
          <FormHeader
            header={headerPage}
            onRefresh={() => setRefreshKey(refreshKey + 1)}
          />
          <div
            className="workpage-add"
            onClick={handleCloseEditHeader}>
            <span>Đóng</span>
          </div>
        </>
      )}
      <div className="podcastPage-header">
        <div className="podcastPage-header-item">
          <label>Logo website</label>
          {checkURL(headerPage.logo) ? (
            <img
              src={headerPage.logo}
              title="Uploaded Image"
              alt="Uploaded"
              className="project-image"
            />
          ) : isValidYoutubeEmbedUrl(headerPage.logo) ? (
            <iframe
              src={headerPage.logo}
              title="Uploaded Video"
              alt="Uploaded"
              className="project-image"
            />
          ) : (
            <iframe
              src={headerPage.logo}
              title="Uploaded Video"
              alt="Uploaded"
              className="project-image"
            />
          )}
        </div>
        <div className="podcastPage-header-item">
          <label>Icon website</label>
          <p>16 x 16 hoặc 32 x 32</p>
          {checkURL(headerPage.icon) ? (
            <img
              src={headerPage.icon}
              title="Uploaded Image"
              alt="Uploaded"
              className="project-image"
            />
          ) : isValidYoutubeEmbedUrl(headerPage.icon) ? (
            <iframe
              src={headerPage.icon}
              title="Uploaded Video"
              alt="Uploaded"
              className="project-image"
            />
          ) : (
            <iframe
              src={headerPage.icon}
              title="Uploaded Video"
              alt="Uploaded"
              className="project-image"
            />
          )}
        </div>
      </div>
      <div
        className="project-detail-add"
        onClick={handleOpenEditHeader}>
        <span>Sửa thông tin</span>
      </div>
      <h2>Menu</h2>
      {showFormHeaderMenu && (
        <>
          <FormHeaderMenu
            menu={menu}
            onRefresh={() => setRefreshKey(refreshKey + 1)}
          />
          <div
            className="workpage-add"
            onClick={handleCloseEditHeaderMenu}>
            <span>Đóng</span>
          </div>
        </>
      )}
      <div className="expertpage-tags">
        {headerMenu.map((menu, index) => (
          <div className="expertpage-tags-item">
            <span key={index}>{menu.name}</span>
            <div
              className="expertpage-tags-item-edit"
              onClick={() => handleOpenEditHeaderMenu(menu)}>
              <i className="fa-solid fa-pen"></i>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HeaderPage;
