import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import FormAboutEditHeader from '../FormAbout/FormAboutEditHeader';
import FormAboutEditStory from '../FormAbout/FormAboutEditStory';
import FormAboutEditEducationHeading from '../FormAbout/FormAboutEditEducationHeading';
import FormAboutEditEducation from '../FormAbout/FormAboutEditEducation';
import FormAboutEditExItem from '../FormAbout/FormAboutEditExItem';
import FormAboutAddEducation from '../FormAbout/FormAboutAddEducation';
import FormAboutEditResume from '../FormAbout/FormAboutEditResume';
import FormEditAboutThumnail from '../FormAbout/FormEditAboutThumnail';
import FormEditAboutAwardHeading from '../AboutAward/FormEditAboutAwardHeading';

const AboutPage = () => {
  const [aboutHeader, setAboutHeader] = useState({});
  const [aboutStory, setAboutStory] = useState({});
  const [aboutExImage, setAboutExImage] = useState({});
  const [aboutResume, setAboutResume] = useState({});
  const [aboutEducationHeading, setAboutEducationHeading] = useState({});
  const [aboutEducation, setAboutEducation] = useState([]);
  const [aboutThumnail, setAboutThumnail] = useState({});
  const [acboutAwardHeading, setAboutAwardHeading] = useState({});
  const [showFormEditAboutThumnail, setShowEditAboutThumnail] = useState(false);
  const [showFormEditAboutHeader, setShowEditAboutHeader] = useState(false);
  const [showFormEditAboutStory, setShowEditAboutStory] = useState(false);
  const [showFormEditAboutEducationHeading, setShowEditAboutEducationHeading] =
    useState(false);
  const [showFormEditAboutEducation, setShowEditAboutEducation] =
    useState(false);
  const [showFormAddAboutEducation, setShowAddAboutEducation] = useState(false);
  const [showFormEditAboutExImage, setShowEditAboutExImage] = useState(false);
  const [showFormEditAboutResume, setShowEditAboutResume] = useState(false);
  const [showFormEditAboutAwardHeading, setShowEditAboutAwardHeading] =
    useState(false);

  const [education, setEducation] = useState('');
  const [refreshKey, setRefreshKey] = useState(0);
  const handleRefresh = useCallback(
    () => setRefreshKey(refreshKey + 1),
    [refreshKey]
  );

  const handleOpenAboutHeaderEditClick = () => {
    setShowEditAboutHeader(true);
  };
  const handleOpenAboutResumeEditClick = () => {
    setShowEditAboutResume(true);
  };
  const handleOpenAboutStoryEditClick = () => {
    setShowEditAboutStory(true);
  };
  const handleOpenAboutEducationHeadingEditClick = () => {
    setShowEditAboutEducationHeading(true);
  };
  const handleOpenAboutExImageEditClick = () => {
    setShowEditAboutExImage(true);
  };
  const handleOpenAboutEducationEditClick = (education) => {
    setShowEditAboutEducation(true);
    setEducation(education);
  };
  const handleOpenAboutEducationAddClick = (education) => {
    setShowAddAboutEducation(true);
  };

  const handleClodeAboutHeaderEditClick = () => {
    setShowEditAboutHeader(false);
  };
  const handleClodeAboutResumeEditClick = () => {
    setShowEditAboutResume(false);
  };
  const handleClodeAboutStoryEditClick = () => {
    setShowEditAboutStory(false);
  };
  const handleClodeAboutEducationHeadingEditClick = () => {
    setShowEditAboutEducationHeading(false);
  };
  const handleClodeAboutEducationEditClick = () => {
    setShowEditAboutEducation(false);
  };
  const handleClodeAboutEducationAddClick = () => {
    setShowAddAboutEducation(false);
  };
  const handleClodeAboutExImageEditClick = () => {
    setShowEditAboutExImage(false);
  };

  const handleOpenFormEditAboutThumbnail = () => {
    setShowEditAboutThumnail(true);
  };
  const handleClodeFormEditAboutThumbnail = () => {
    setShowEditAboutThumnail(false);
  };

  const handleOpenFormEditAboutAwardHeading = () => {
    setShowEditAboutAwardHeading(true);
  };
  const handleClodeFormEditAboutAwardHeading = () => {
    setShowEditAboutAwardHeading(false);
  };

  useEffect(() => {
    const fetchAboutHeader = async () => {
      const res = await fetch(`https://api.chaulanart.com/about/about-header`);
      const data = await res.json();
      setAboutHeader(data);
    };
    const fetchAboutStory = async () => {
      const res = await fetch(`https://api.chaulanart.com/about/about-story`);
      const data = await res.json();
      setAboutStory(data);
    };
    const fetchAboutEducaition = async () => {
      const res = await fetch(
        `https://api.chaulanart.com/about/about-education`
      );
      const data = await res.json();
      setAboutEducation(data);
    };
    const fetchAboutEducationHeading = async () => {
      const res = await fetch(
        `https://api.chaulanart.com/about/about-education-heading`
      );
      const data = await res.json();
      setAboutEducationHeading(data);
    };
    const fetchAboutEXImage = async () => {
      const res = await fetch(`https://api.chaulanart.com/about/about-ex-item`);
      const data = await res.json();
      setAboutExImage(data);
    };
    const fetchAboutResume = async () => {
      const res = await fetch(`https://api.chaulanart.com/about/about-resume`);
      const data = await res.json();
      setAboutResume(data);
    };
    const fetchAboutThumnail = async () => {
      const res = await fetch(
        `https://api.chaulanart.com/about/about-thumnail`
      );
      const data = await res.json();
      setAboutThumnail(data);
    };
    const fetchAboutAwardHeading = async () => {
      const res = await fetch(
        `https://api.chaulanart.com/about/about-award-heading`
      );
      const data = await res.json();
      setAboutAwardHeading(data);
    };
    fetchAboutAwardHeading();
    fetchAboutThumnail();
    fetchAboutHeader();
    fetchAboutResume();
    fetchAboutStory();
    fetchAboutEducaition();
    fetchAboutEducationHeading();
    fetchAboutEXImage();
    setShowEditAboutHeader(false);
    setShowEditAboutStory(false);
    setShowEditAboutEducationHeading(false);
    setShowEditAboutEducation(false);
    setShowEditAboutExImage(false);
    setShowAddAboutEducation(false);
    setShowEditAboutResume(false);
    setShowEditAboutThumnail(false);
    setShowEditAboutAwardHeading(false);
  }, [refreshKey]);

  const checkURL = (url) => {
    return /\.(jpeg|jpg|gif|png)$/i.test(url);
  };
  const checkMP4 = (url) => {
    return /\.(mp4)$/i.test(url);
  };
  const isValidYoutubeEmbedUrl = (url) => {
    const pattern = /^https:\/\/www\.youtube\.com\/embed\/.+/i;
    return pattern.test(url);
  };

  const deleteProject = (id) => {
    if (window.confirm('Bạn muốn xóa không?')) {
      fetch(`https://api.chaulanart.com/about/delete-about-education/${id}`, {
        method: 'DELETE',
      })
        .then((res) => res.json())
        .then(() => {
          // Fetch the list of projects again after a project has been deleted
          const fetchProjects = async () => {
            const res = await fetch(
              `https://api.chaulanart.com/about/about-education`
            );
            const data = await res.json();
            setAboutEducation(data);
          };
          setRefreshKey((prevKey) => prevKey + 1);
          fetchProjects();
        })
        .catch((error) => console.error('Lỗi xóa:', error));
    }
  };

  return (
    <div className="podcastPage">
      <h1>Trang about</h1>
      <h2>Thumbnail ExpertisePage</h2>
      {showFormEditAboutThumnail ? (
        <>
          <FormEditAboutThumnail
            onRefresh={() => setRefreshKey(refreshKey + 1)}
            aboutThumnail={aboutThumnail}
          />
          <div
            className="workpage-add"
            onClick={handleClodeFormEditAboutThumbnail}>
            <span>Đóng</span>
          </div>
        </>
      ) : null}
      <div className="project-image-detail">
        <div className="project-image-detail-item">
          {checkURL(aboutThumnail.thumnail) ? (
            <img
              src={aboutThumnail.thumnail}
              title="Uploaded Image"
              alt="Uploaded"
              className="project-image"
            />
          ) : isValidYoutubeEmbedUrl(aboutThumnail.thumnail) ? (
            <iframe
              src={aboutThumnail.thumnail}
              title="Uploaded Video"
              alt="Uploaded"
              className="project-image"
            />
          ) : (
            <iframe
              src={aboutThumnail.thumnail}
              title="Uploaded Video"
              alt="Uploaded"
              className="project-image"
            />
          )}
        </div>
      </div>
      <div
        className="project-detail-add"
        onClick={handleOpenFormEditAboutThumbnail}>
        <span>Sửa thông tin</span>
      </div>
      {showFormEditAboutHeader && (
        <>
          <FormAboutEditHeader
            aboutHeader={aboutHeader}
            onRefresh={handleRefresh}
          />
          <div
            className="project-detail-add"
            onClick={handleClodeAboutHeaderEditClick}>
            <span>Đóng</span>
          </div>
        </>
      )}
      <div className="podcastPage-header">
        <div className="podcastPage-header-item">
          <label>Hình minh họa</label>
          {checkURL(aboutHeader.image) ? (
            <img
              src={aboutHeader.image}
              title="Uploaded Image"
              alt="Uploaded"
              className="project-image"
            />
          ) : isValidYoutubeEmbedUrl(aboutHeader.image) ? (
            <iframe
              src={aboutHeader.image}
              title="Uploaded Video"
              alt="Uploaded"
              className="project-image"
            />
          ) : (
            <iframe
              src={aboutHeader.image}
              title="Uploaded Video"
              alt="Uploaded"
              className="project-image"
            />
          )}
        </div>
        <div className="podcastPage-header-item">
          <label>Mô tả</label>
          <div dangerouslySetInnerHTML={{ __html: aboutHeader.description }} />
        </div>
      </div>
      <div
        className="project-detail-add"
        onClick={handleOpenAboutHeaderEditClick}>
        <span>Sửa thông tin</span>
      </div>
      <h2>About Awards</h2>
      {showFormEditAboutAwardHeading && (
        <>
          <FormEditAboutAwardHeading
            aboutAwardHeading={acboutAwardHeading}
            onRefresh={handleRefresh}
          />
          <div
            className="project-detail-add"
            onClick={handleClodeFormEditAboutAwardHeading}>
            <span>Đóng</span>
          </div>
        </>
      )}
      <div className="podcastPage-info">
        <div className="podcastPage-info-item">
          <label>Tiêu đề:</label>
          <p dangerouslySetInnerHTML={{ __html: acboutAwardHeading.heading }} />
        </div>
      </div>
      <div
        className="project-detail-add"
        onClick={handleOpenFormEditAboutAwardHeading}>
        <span>Sửa thông tin</span>
      </div>
      <div className="project-detail-add">
        <Link to="/admin/about-awards">
          <span>Quản lý chi tiết</span>
        </Link>
      </div>
      <h2>About Story</h2>
      {showFormEditAboutStory && (
        <>
          <FormAboutEditStory
            aboutStory={aboutStory}
            onRefresh={handleRefresh}
          />
          <div
            className="project-detail-add"
            onClick={handleClodeAboutStoryEditClick}>
            <span>Đóng</span>
          </div>
        </>
      )}
      <div className="podcastPage-info">
        <div className="podcastPage-info-item">
          <label>Tiêu đề:</label>
          <div dangerouslySetInnerHTML={{ __html: aboutStory.heading }} />
        </div>
      </div>
      <div className="podcastPage-header">
        <div className="podcastPage-header-item">
          <label>Hình ảnh chính</label>
          {checkURL(aboutStory.image_main) ? (
            <img
              src={aboutStory.image_main}
              title="Uploaded Image"
              alt="Uploaded"
              className="project-image"
            />
          ) : isValidYoutubeEmbedUrl(aboutStory.image_main) ? (
            <iframe
              src={aboutStory.image_main}
              title="Uploaded Video"
              alt="Uploaded"
              className="project-image"
            />
          ) : (
            <iframe
              src={aboutStory.image_main}
              title="Uploaded Video"
              alt="Uploaded"
              className="project-image"
            />
          )}
        </div>
        <div className="podcastPage-header-item">
          <label>Mô tả</label>
          <div dangerouslySetInnerHTML={{ __html: aboutStory.description }} />
        </div>
        <div className="podcastPage-header-flex">
          <div className="podcastPage-header-flex-item">
            <label>Hình ảnh 1</label>
            {checkURL(aboutStory.image_item_1) ? (
              <img
                src={aboutStory.image_item_1}
                title="Uploaded Image"
                alt="Uploaded"
                className="project-image"
              />
            ) : isValidYoutubeEmbedUrl(aboutStory.image_item_1) ? (
              <iframe
                src={aboutStory.image_item_1}
                title="Uploaded Video"
                alt="Uploaded"
                className="project-image"
              />
            ) : (
              <iframe
                src={aboutStory.image_item_1}
                title="Uploaded Video"
                alt="Uploaded"
                className="project-image"
              />
            )}
          </div>
          <div className="podcastPage-header-flex-item">
            <label>Hình ảnh 2</label>
            {checkURL(aboutStory.image_item_2) ? (
              <img
                src={aboutStory.image_item_2}
                title="Uploaded Image"
                alt="Uploaded"
                className="project-image"
              />
            ) : isValidYoutubeEmbedUrl(aboutStory.image_item_2) ? (
              <iframe
                src={aboutStory.image_item_2}
                title="Uploaded Video"
                alt="Uploaded"
                className="project-image"
              />
            ) : (
              <iframe
                src={aboutStory.image_item_2}
                title="Uploaded Video"
                alt="Uploaded"
                className="project-image"
              />
            )}
          </div>
          <div className="podcastPage-header-flex-item">
            <label>Hình ảnh 3</label>
            {checkURL(aboutStory.image_item_3) ? (
              <img
                src={aboutStory.image_item_3}
                title="Uploaded Image"
                alt="Uploaded"
                className="project-image"
              />
            ) : isValidYoutubeEmbedUrl(aboutStory.image_item_3) ? (
              <iframe
                src={aboutStory.image_item_3}
                title="Uploaded Video"
                alt="Uploaded"
                className="project-image"
              />
            ) : (
              <iframe
                src={aboutStory.image_item_3}
                title="Uploaded Video"
                alt="Uploaded"
                className="project-image"
              />
            )}
          </div>
          <div className="podcastPage-header-flex-item">
            <label>Hình ảnh 4</label>
            {checkURL(aboutStory.image_item_4) ? (
              <img
                src={aboutStory.image_item_4}
                title="Uploaded Image"
                alt="Uploaded"
                className="project-image"
              />
            ) : isValidYoutubeEmbedUrl(aboutStory.image_item_4) ? (
              <iframe
                src={aboutStory.image_item_4}
                title="Uploaded Video"
                alt="Uploaded"
                className="project-image"
              />
            ) : (
              <iframe
                src={aboutStory.image_item_4}
                title="Uploaded Video"
                alt="Uploaded"
                className="project-image"
              />
            )}
          </div>
        </div>
      </div>
      <div
        className="project-detail-add"
        onClick={handleOpenAboutStoryEditClick}>
        <span>Sửa thông tin</span>
      </div>
      <h2>About education</h2>
      {showFormEditAboutEducationHeading && (
        <>
          <FormAboutEditEducationHeading
            aboutEducationHeading={aboutEducationHeading}
            onRefresh={handleRefresh}
          />
          <div
            className="project-detail-add"
            onClick={handleClodeAboutEducationHeadingEditClick}>
            <span>Đóng</span>
          </div>
        </>
      )}
      <div className="podcastPage-info">
        <div className="podcastPage-info-item">
          <label>Tiêu đề:</label>
          <p
            dangerouslySetInnerHTML={{ __html: aboutEducationHeading.heading }}
          />
        </div>
      </div>
      <div
        className="project-detail-add"
        onClick={handleOpenAboutEducationHeadingEditClick}>
        <span>Sửa thông tin</span>
      </div>
      {showFormEditAboutEducation && (
        <>
          <FormAboutEditEducation
            aboutEducation={education}
            onRefresh={handleRefresh}
          />
          <div
            className="project-detail-add"
            onClick={handleClodeAboutEducationEditClick}>
            <span>Đóng</span>
          </div>
        </>
      )}
      {showFormAddAboutEducation && (
        <>
          <FormAboutAddEducation onRefresh={handleRefresh} />
          <div
            className="project-detail-add"
            onClick={handleClodeAboutEducationAddClick}>
            <span>Đóng</span>
          </div>
        </>
      )}
      <div className="expertpage-tags">
        {aboutEducation &&
          aboutEducation.length > 0 &&
          aboutEducation.map((item) => {
            return (
              <div className="expertpage-tags-item">
                <div dangerouslySetInnerHTML={{ __html: item.description }} />
                <div
                  className="expertpage-tags-item-delete"
                  onClick={() => deleteProject(item.about_education_id)}>
                  <i className="fa-solid fa-trash"></i>
                </div>
                <div
                  className="expertpage-tags-item-edit"
                  onClick={() => handleOpenAboutEducationEditClick(item)}>
                  <i className="fa-solid fa-pen"></i>
                </div>
              </div>
            );
          })}
      </div>
      <div
        className="project-detail-add"
        onClick={handleOpenAboutEducationAddClick}>
        <span>Thêm thông tin</span>
      </div>
      <h2>About Experiences</h2>
      {showFormEditAboutExImage && (
        <>
          <FormAboutEditExItem
            aboutExItem={aboutExImage}
            onRefresh={handleRefresh}
          />
          <div
            className="project-detail-add"
            onClick={handleClodeAboutExImageEditClick}>
            <span>Đóng</span>
          </div>
        </>
      )}

      <div className="podcastPage-info">
        <div className="podcastPage-info-item">
          <label>Tiêu đề:</label>
          <div dangerouslySetInnerHTML={{ __html: aboutExImage.heading }} />
        </div>
      </div>
      <div className="podcastPage-header">
        <div className="podcastPage-header-flex">
          <div className="podcastPage-header-flex-item">
            <label>Nội dung 1</label>
            {checkURL(aboutExImage.image_item_1) ? (
              <img
                src={aboutExImage.image_item_1}
                title="Uploaded Image"
                alt="Uploaded"
                className="podcastPage-header-image"
              />
            ) : isValidYoutubeEmbedUrl(aboutExImage.image_item_4) ? (
              <iframe
                src={aboutExImage.image_item_1}
                title="Uploaded Video"
                alt="Uploaded"
                className="podcastPage-header-image"
              />
            ) : checkMP4(aboutExImage.image_item_1) ? (
              <iframe
                src={aboutExImage.image_item_1}
                title="Uploaded Video"
                alt="Uploaded"
                className="podcastPage-header-image"
              />
            ) : (
              <span
                dangerouslySetInnerHTML={{ __html: aboutExImage.image_item_1 }}
              />
            )}
          </div>
          <div className="podcastPage-header-flex-item">
            <label>Nội dung 2</label>
            {checkURL(aboutExImage.image_item_2) ? (
              <img
                src={aboutExImage.image_item_2}
                title="Uploaded Image"
                alt="Uploaded"
                className="podcastPage-header-image"
              />
            ) : isValidYoutubeEmbedUrl(aboutExImage.image_item_2) ? (
              <iframe
                src={aboutExImage.image_item_2}
                title="Uploaded Video"
                alt="Uploaded"
                className="podcastPage-header-image"
              />
            ) : checkMP4(aboutExImage.image_item_2) ? (
              <iframe
                src={aboutExImage.image_item_2}
                title="Uploaded Video"
                alt="Uploaded"
                className="podcastPage-header-image"
              />
            ) : (
              <span
                dangerouslySetInnerHTML={{ __html: aboutExImage.image_item_2 }}
              />
            )}
          </div>
          <div className="podcastPage-header-flex-item">
            <label>Nội dung 3</label>
            {checkURL(aboutExImage.image_item_3) ? (
              <img
                src={aboutExImage.image_item_3}
                title="Uploaded Image"
                alt="Uploaded"
                className="podcastPage-header-image"
              />
            ) : isValidYoutubeEmbedUrl(aboutExImage.image_item_3) ? (
              <iframe
                src={aboutExImage.image_item_3}
                title="Uploaded Video"
                alt="Uploaded"
                className="podcastPage-header-image"
              />
            ) : checkMP4(aboutExImage.image_item_3) ? (
              <iframe
                src={aboutExImage.image_item_3}
                title="Uploaded Video"
                alt="Uploaded"
                className="podcastPage-header-image"
              />
            ) : (
              <span
                dangerouslySetInnerHTML={{ __html: aboutExImage.image_item_3 }}
              />
            )}
          </div>
          <div className="podcastPage-header-flex-item">
            <label>Nội dung 4</label>
            {checkURL(aboutExImage.image_item_4) ? (
              <img
                src={aboutExImage.image_item_4}
                title="Uploaded Image"
                alt="Uploaded"
                className="podcastPage-header-image"
              />
            ) : isValidYoutubeEmbedUrl(aboutExImage.image_item_4) ? (
              <iframe
                src={aboutExImage.image_item_4}
                title="Uploaded Video"
                alt="Uploaded"
                className="podcastPage-header-image"
              />
            ) : checkMP4(aboutExImage.image_item_4) ? (
              <iframe
                src={aboutExImage.image_item_4}
                title="Uploaded Video"
                alt="Uploaded"
                className="podcastPage-header-image"
              />
            ) : (
              <span
                dangerouslySetInnerHTML={{ __html: aboutExImage.image_item_4 }}
              />
            )}
          </div>
          <div className="podcastPage-header-flex-item">
            <label>Nội dung 5</label>
            {checkURL(aboutExImage.image_item_5) ? (
              <img
                src={aboutExImage.image_item_5}
                title="Uploaded Image"
                alt="Uploaded"
                className="podcastPage-header-image"
              />
            ) : isValidYoutubeEmbedUrl(aboutExImage.image_item_5) ? (
              <iframe
                src={aboutExImage.image_item_5}
                title="Uploaded Video"
                alt="Uploaded"
                className="podcastPage-header-image"
              />
            ) : checkMP4(aboutExImage.image_item_5) ? (
              <iframe
                src={aboutExImage.image_item_5}
                title="Uploaded Video"
                alt="Uploaded"
                className="podcastPage-header-image"
              />
            ) : (
              <span
                dangerouslySetInnerHTML={{ __html: aboutExImage.image_item_5 }}
              />
            )}
          </div>
        </div>
        <div className="podcastPage-header-flex">
          <div className="podcastPage-header-flex-item">
            <label>Nội dung 6</label>
            {checkURL(aboutExImage.image_item_6) ? (
              <img
                src={aboutExImage.image_item_6}
                title="Uploaded Image"
                alt="Uploaded"
                className="podcastPage-header-image"
              />
            ) : isValidYoutubeEmbedUrl(aboutExImage.image_item_6) ? (
              <iframe
                src={aboutExImage.image_item_6}
                title="Uploaded Video"
                alt="Uploaded"
                className="podcastPage-header-image"
              />
            ) : checkMP4(aboutExImage.image_item_6) ? (
              <iframe
                src={aboutExImage.image_item_6}
                title="Uploaded Video"
                alt="Uploaded"
                className="podcastPage-header-image"
              />
            ) : (
              <span
                dangerouslySetInnerHTML={{ __html: aboutExImage.image_item_6 }}
              />
            )}
          </div>
          <div className="podcastPage-header-flex-item">
            <label>Nội dung 7</label>
            {checkURL(aboutExImage.image_item_7) ? (
              <img
                src={aboutExImage.image_item_7}
                title="Uploaded Image"
                alt="Uploaded"
                className="podcastPage-header-image"
              />
            ) : isValidYoutubeEmbedUrl(aboutExImage.image_item_7) ? (
              <iframe
                src={aboutExImage.image_item_7}
                title="Uploaded Video"
                alt="Uploaded"
                className="podcastPage-header-image"
              />
            ) : checkMP4(aboutExImage.image_item_7) ? (
              <iframe
                src={aboutExImage.image_item_7}
                title="Uploaded Video"
                alt="Uploaded"
                className="podcastPage-header-image"
              />
            ) : (
              <span
                dangerouslySetInnerHTML={{ __html: aboutExImage.image_item_7 }}
              />
            )}
          </div>
          <div className="podcastPage-header-flex-item">
            <label>Nội dung 8</label>
            {checkURL(aboutExImage.image_item_8) ? (
              <img
                src={aboutExImage.image_item_8}
                title="Uploaded Image"
                alt="Uploaded"
                className="podcastPage-header-image"
              />
            ) : isValidYoutubeEmbedUrl(aboutExImage.image_item_8) ? (
              <iframe
                src={aboutExImage.image_item_8}
                title="Uploaded Video"
                alt="Uploaded"
                className="podcastPage-header-image"
              />
            ) : checkMP4(aboutExImage.image_item_8) ? (
              <iframe
                src={aboutExImage.image_item_8}
                title="Uploaded Video"
                alt="Uploaded"
                className="podcastPage-header-image"
              />
            ) : (
              <span
                dangerouslySetInnerHTML={{ __html: aboutExImage.image_item_8 }}
              />
            )}
          </div>
          <div className="podcastPage-header-flex-item">
            <label>Nội dung 9</label>
            {checkURL(aboutExImage.image_item_9) ? (
              <img
                src={aboutExImage.image_item_9}
                title="Uploaded Image"
                alt="Uploaded"
                className="podcastPage-header-image"
              />
            ) : isValidYoutubeEmbedUrl(aboutExImage.image_item_9) ? (
              <iframe
                src={aboutExImage.image_item_9}
                title="Uploaded Video"
                alt="Uploaded"
                className="podcastPage-header-image"
              />
            ) : checkMP4(aboutExImage.image_item_9) ? (
              <iframe
                src={aboutExImage.image_item_9}
                title="Uploaded Video"
                alt="Uploaded"
                className="podcastPage-header-image"
              />
            ) : (
              <span
                dangerouslySetInnerHTML={{ __html: aboutExImage.image_item_9 }}
              />
            )}
          </div>
          <div className="podcastPage-header-flex-item">
            <label>Nội dung 10</label>
            {checkURL(aboutExImage.image_item_10) ? (
              <img
                src={aboutExImage.image_item_10}
                title="Uploaded Image"
                alt="Uploaded"
                className="podcastPage-header-image"
              />
            ) : isValidYoutubeEmbedUrl(aboutExImage.image_item_10) ? (
              <iframe
                src={aboutExImage.image_item_10}
                title="Uploaded Video"
                alt="Uploaded"
                className="podcastPage-header-image"
              />
            ) : checkMP4(aboutExImage.image_item_10) ? (
              <iframe
                src={aboutExImage.image_item_10}
                title="Uploaded Video"
                alt="Uploaded"
                className="podcastPage-header-image"
              />
            ) : (
              <span
                dangerouslySetInnerHTML={{ __html: aboutExImage.image_item_10 }}
              />
            )}
          </div>
        </div>
      </div>
      <div
        className="project-detail-add"
        onClick={handleOpenAboutExImageEditClick}>
        <span>Sửa thông tin</span>
      </div>
      <h2>About Experiences Content</h2>
      <div className="project-detail-add">
        <Link to="/admin/about-ex-content">
          <span>Quản lý chi tiết</span>
        </Link>
      </div>
      <h2>About Resume</h2>
      {showFormEditAboutResume && (
        <>
          <FormAboutEditResume
            onRefresh={handleRefresh}
            aboutResume={aboutResume}
          />
          <div
            className="project-detail-add"
            onClick={handleClodeAboutResumeEditClick}>
            <span>Đóng</span>
          </div>
        </>
      )}
      <div className="expertpage-tags">
        <div className="expertpage-tags-item">
          <div dangerouslySetInnerHTML={{ __html: aboutResume.title }} />
          <Link
            to={aboutResume.link}
            target="_blank">
            <div dangerouslySetInnerHTML={{ __html: aboutResume.text }} />
          </Link>
          <div
            className="expertpage-tags-item-edit"
            onClick={handleOpenAboutResumeEditClick}>
            <i className="fa-solid fa-pen"></i>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutPage;
