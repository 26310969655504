import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';

const Footer = () => {
  const [courseHeader, setCourseHeader] = useState({});
  const [menuLeft, setMenuLeft] = useState([]);
  const [menuRight, setMenuRight] = useState([]);

  useEffect(() => {
    const fetchAboutHeader = async () => {
      const res = await fetch(`https://api.chaulanart.com/footer/footer`);
      const text = await res.text();
      const data = text ? JSON.parse(text) : {};
      setCourseHeader(data);
    };
    const fetchMenuLeft = async () => {
      const res = await fetch(
        `https://api.chaulanart.com/footer/footer-menu-left`
      );
      const text = await res.text();
      const data = text ? JSON.parse(text) : {};
      setMenuLeft(data);
    };
    const fetchMenuRight = async () => {
      const res = await fetch(
        `https://api.chaulanart.com/footer/footer-menu-right`
      );
      const text = await res.text();
      const data = text ? JSON.parse(text) : {};
      setMenuRight(data);
    };
    fetchMenuLeft();
    fetchMenuRight();
    fetchAboutHeader();
  }, []);

  return (
    <footer className="footer">
      <div className="footer-marquee">
        <span className="footer-title">GET IN TOUCH&nbsp;&nbsp;</span>
        <span className="footer-title">GET IN TOUCH&nbsp;&nbsp;</span>
        <span className="footer-title">GET IN TOUCH&nbsp;&nbsp;</span>
      </div>
      <div className="container">
        <div className="footer-wrapper">
          <div className="footer-contact">
            {courseHeader.email !== '' &&
            courseHeader.email !== '<p><br></p>' ? (
              <p className="footer-contact-text">
                <span
                  dangerouslySetInnerHTML={{ __html: courseHeader.email }}
                />
              </p>
            ) : null}
            {courseHeader.phone !== '' &&
            courseHeader.phone !== '<p><br></p>' ? (
              <p className="footer-contact-text">
                <span
                  dangerouslySetInnerHTML={{ __html: courseHeader.phone }}
                />
              </p>
            ) : null}
            {courseHeader.address !== '' &&
            courseHeader.address !== '<p><br></p>' ? (
              <p className="footer-contact-text">
                <span
                  dangerouslySetInnerHTML={{ __html: courseHeader.address }}
                />
              </p>
            ) : null}
          </div>
          <nav className="footer-menu">
            <ul className="footer-list">
              {menuLeft.map((item, index) => (
                <li
                  className="footer-item"
                  key={index}>
                  <Link
                    to={item.link}
                    target="_blank"
                    className="footer-link">
                    {item.name}
                  </Link>
                </li>
              ))}
            </ul>
            <ul className="footer-list">
              {menuRight.map((item, index) => (
                <li
                  className="footer-item"
                  key={index}>
                  <Link
                    to={item.link}
                    target="_blank"
                    className="footer-link">
                    {item.name}
                  </Link>
                </li>
              ))}
            </ul>
          </nav>
        </div>
        <div className="footer-bottom">
          <div className="footer-bottom-list">
            <div className="footer-bottom-item">
              <p className="footer-design">Design by leodang.com</p>
              <p className="footer-text">© 2024 Chau Lan Art</p>
            </div>
            <div className="footer-bottom-item">
              <p className="footer-info">
                Explore This Amazing Art World Together
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
