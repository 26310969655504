import React, { useEffect, useState } from 'react';
import './StudentCourse.css';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const StudentCourse = ({ id }) => {
  const [courseStudent, setCourseStudent] = useState([]);
  const [courseStudentHeading, setCourseStudentHeading] = useState({});

  useEffect(() => {
    const fetchProjects = async () => {
      const res = await fetch(
        `https://api.chaulanart.com/course/course-student/${id}`
      );
      const data = await res.json();

      setCourseStudent(data);
    };
    const fetchCourseStudentHeading = async () => {
      const res = await fetch(
        `https://api.chaulanart.com/course/course-student-heading`
      );
      const data = await res.json();

      setCourseStudentHeading(data);
    };

    fetchProjects();
    fetchCourseStudentHeading();
  }, [id]);

  const checkURL = (url) => {
    return /\.(jpeg|jpg|gif|png)$/i.test(url);
  };
  const isValidYoutubeEmbedUrl = (url) => {
    const pattern = /^https:\/\/www\.youtube\.com\/embed\/.+/i;
    return pattern.test(url);
  };

  const itemCount = courseStudent?.length || 0;

  const settingsStudent = {
    dots: false,
    infinite: true,
    slidesToShow: Math.min(3, itemCount),
    slidesToScroll: 1,
    autoplay: true,
    speed: 5000,
    autoplaySpeed: 3000,
    cssEase: 'linear',
    pauseOnHover: false,
    pauseOnFocus: false,
    Swipe: false,
    responsive: [
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: Math.min(2, itemCount),
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: Math.min(2, itemCount),
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: Math.min(1, itemCount),
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section className="studentCourse">
      <div className="container">
        <h3
          className="studentCourse-heading"
          dangerouslySetInnerHTML={{ __html: courseStudentHeading.heading }}
        />
        <div className="studentCourse-wrapper">
          <Slider
            {...settingsStudent}
            className="studentCourse-list">
            {courseStudent.map((item, index) => (
              <div
                className="studentCourse-margin"
                key={index}>
                <div className="studentCoure-item">
                  {checkURL(item.image) ? (
                    <img
                      src={item.image}
                      title="Uploaded Image"
                      alt="Uploaded"
                      className="studentCourse-imgae"
                    />
                  ) : isValidYoutubeEmbedUrl(item.image) ? (
                    <iframe
                      src={item.image}
                      title="Uploaded Video"
                      alt="Uploaded"
                      className="studentCourse-imgae"
                    />
                  ) : (
                    <iframe
                      src={item.image}
                      title="Uploaded Video"
                      alt="Uploaded"
                      className="studentCourse-imgae"
                    />
                  )}
                  <p
                    className="studentCourse-name"
                    dangerouslySetInnerHTML={{ __html: item.name }}
                  />
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </section>
  );
};

export default StudentCourse;
